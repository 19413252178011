import { fontFamily } from '@components/charts/common-styles'
import { base64Url } from '@components/charts/fonts/nbInternationalBase64Url'

export interface ExpectedWeightLossChartData {
  initialWeight: string
  expectedWeightLoss: string
  units: string
}

export interface ExpectedWeightLossChartConfig {
  data: ExpectedWeightLossChartData
  lineColors?: string[]
  disableAnimation?: boolean
  style: {
    axisLabel: string
    axisLine: string
    initialWeightColor: string
    initialWeightBackground: string
    lossRateMainColor: string
    lossRateSubColor: string
    weightIconBackground: string
    dashColor: string
  }
}

export const expectedWeightLossChart = ({
  data,
  lineColors,
  style,
}: ExpectedWeightLossChartConfig) => `(() => {
  var nbInternationalFont = new FontFace('${fontFamily}', 'url(${base64Url})');
  nbInternationalFont.load().then(function(font) {
    document.fonts.add(font);

    const data = ${JSON.stringify([
      { index: 0, x: 0.5, y: 5 },
      { index: 2, x: 2, y: 4.5 },
      { index: 3, x: 3, y: 2 },
      { index: 4, x: 4, y: 1.5 },
      { index: 5, x: 5, y: 1.3 },
    ])};

    const style = document.createElement('style');
    style.textContent = \`
      .f2-tooltip {
        display: none;
      }
      .f2-tooltip:after {
        display: none;
      }
      .tooltip-content {
        overflow: hidden;
        display: none;
      }
      .initial-weight {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 27px;
        border-radius: 32px;
        background-color: ${style.initialWeightBackground};
        color: ${style.initialWeightColor};
        font-family: 'NBInternationalProReg';
      }
      .expect-weight-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: ${style.weightIconBackground};
      }
      .loss-rate-text {
        color: ${style.lossRateMainColor};
        text-align: right;
        width: 28vw;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        font-family: 'NBInternationalProReg';
      }

      .loss-rate-text-secondary {
        color: ${style.lossRateSubColor};
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
      }

    \`;
    document.body.append(style)

    chart = new F2.Chart({
      id: 'chart',
      padding: ['auto',20,'auto',0],
      pixelRatio: window.devicePixelRatio,
    });

    chart.source(data, {
      x: {
        range: [0, 1],
        ticks: [1,2,3,4],
        min: 0,
        max: 5,
        nice: false,
      },
      y: {
        min: 0,
        max:  6,
        tickCount: 9,
        nice: true,
      }
    });

    chart.axis('x', {
      line: false,
      label: (text, index) => {
        return {
          fill: '${style.axisLabel}',
          fontFamily: '${fontFamily}',
          fontSize: 16,
        };
      },
      grid: (_, index) => {
        return {
          stroke: '${style.axisLine}',
          lineDash: [5, 5],
        }

      },
    });

    chart.axis('y', false);


    chart.line()
      .position('x*y')
      .shape('smooth')
      .style({ lineCap: 'butt' })
      .color('index', ${JSON.stringify(lineColors)});



    chart.guide().html({
      position: [0.5, 5],
      html: \`<div class="initial-weight">${data.initialWeight}</div>\`,
    });

    chart.guide().html({
      position: [4, 1.3],
      offsetY: -10,
      html:\`
        <div  class="expect-weight-icon" >
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#FFF" viewBox="0 0 256 256">
            <path d="M227.32,48.75A8,8,0,0,0,218.76,50c-28,24.22-51.72,12.48-79.21-1.13C111.07,34.76,78.78,18.79,42.76,50A8,8,0,0,0,40,56V224a8,8,0,0,0,16,0V179.77c26.79-21.16,49.87-9.75,76.45,3.41,28.49,14.09,60.77,30.06,96.79-1.13a8,8,0,0,0,2.76-6V56A8,8,0,0,0,227.32,48.75ZM216,71.6v40.65c-14,11.06-27,13.22-40,10.88V79.34A60.05,60.05,0,0,0,216,71.6Zm-56,3.76v43c-6.66-2.67-13.43-6-20.45-9.48-8.82-4.37-18-8.91-27.55-12.18v-43c6.66,2.66,13.43,6,20.45,9.48C141.27,67.55,150.46,72.09,160,75.36ZM96,48.91V92.69a60.06,60.06,0,0,0-40,7.75V59.78C70,48.72,83,46.57,96,48.91ZM86.58,152A60.06,60.06,0,0,0,56,160.43V119.78c14-11.06,27-13.22,40-10.88v43.8A65.61,65.61,0,0,0,86.58,152ZM112,156.67v-43c6.66,2.66,13.43,6,20.45,9.48,8.82,4.37,18,8.9,27.55,12.17v43c-6.66-2.67-13.43-6-20.45-9.48C130.73,164.47,121.54,159.94,112,156.67Zm64,26.45v-43.8a65.61,65.61,0,0,0,9.42.72A60.11,60.11,0,0,0,216,131.57v40.68C202,183.31,189,185.46,176,183.12Z"></path>
          </svg>
        </div>
      \`
    });

    chart.guide().text({
      position: [4.6, 0],
      content: 'calls',
      offsetY: 16,
      style: {
        textAlign: 'start',
        fontFamily: 'NBInternationalProReg',
        fontSize: 15,
        fill: '${style.axisLabel}',
      },
    });

    chart.guide().html({
      position: [3.8, 4],
      html: \`<div>
      <div class="loss-rate-text">${`${data.expectedWeightLoss} ${data.units}`}</div>
      <div class="loss-rate-text loss-rate-text-secondary">/month</div>
      </div>
      \`,
    });

    chart.guide().regionFilter({
      start: [4, 0],
      end: ['max', 'max'],
      style: {
        stroke: '${style.dashColor}',
        lineDash: [2, 4],
      }
    });

    chart.tooltip(false);
    chart.legend(false);
    chart.animate(false);
    chart.render();

    window.ReactNativeWebView.postMessage(stringify({ type: 'loaded' }));
  });
})(); true;
`
