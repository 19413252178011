import { gql } from '@apollo/client'

export const ACTIVE_NOTIFICATIONS = gql`
  query activeNotifications {
    activeNotifications {
      notifications {
        id
        kind
        actionKind
        actionButtonLabel
        dismissButtonLabel
        screen
        icon
        style
        content
        payload
        deliveredAt
        canceledAt
        dismissedAt
        completedAt
        createdAt
        updatedAt
      }
    }
  }
`
