import { gql } from '@apollo/client'

export const LATEST_USER_APPOINTMENT_FIELDS = gql`
  fragment LatestUserAppointmentFields on LatestUserAppointment {
    id
    date
    meetingStatus
    status
    title
    nutritionistName
    addToGoogleCalendarLink
    unauthenticatedIcsLink
    appointmentType
    chargeType
    followupSchedulingAvailable
  }
`
