import React, { TouchableOpacity, View } from 'react-native'
import { useCallback, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  DragEndParams,
  NestableDraggableFlatList,
  NestableScrollContainer,
  ScaleDecorator,
  ShadowDecorator,
} from 'react-native-draggable-flatlist'
import { useDispatch } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { DailyActionKind } from '@src/types'
import { Haptic } from '@src/utils'
import {
  DailyActionsSection,
  NUTRITION_SUMMARY_CARD_KEY,
  TODAY_SECTION_ID,
  useEditDailyActionsSections,
} from '../hooks/useEditDailyActionsSections'
import { SectionHeader } from './SectionHeader'
import { SectionItem } from './SectionItem'
import { ChartTypeSelectorSection } from './ChartTypeSelectorSection'

const ALWAYS_VISIBLE_CARDS = [NUTRITION_SUMMARY_CARD_KEY]

export const EditDailyActionsModal = () => {
  const styles = useStyleSheet(themedStyles)
  const sections = useEditDailyActionsSections()
  const dispatch = useDispatch()
  const insets = useSafeAreaInsets()
  const theme = useTheme()
  // tempOrder is used to store the order of the daily actions to prevent the draggable list from
  // glitching after the user is done dragging the items
  const [tempOrder, setTempOrder] = useState<DailyActionKind[]>([])

  const [expandedSections, setExpandedSections] = useState<Set<number>>(
    new Set(sections.map((section) => section.id)),
  )

  const handleToggleSection = (id: number) => {
    setExpandedSections((expandedSections) => {
      const next = new Set(expandedSections)
      if (next.has(id)) {
        next.delete(id)
      } else {
        next.add(id)
      }
      return next
    })
  }

  const persistOrder = (params: DragEndParams<DailyActionKind>) => {
    setTempOrder(params.data)
    dispatch({
      type: 'dailyActions/reorder',
      payload: {
        nextOrder: params.data.map((dailyAction) => dailyAction.key),
      },
    })
  }

  const renderAlwaysVisibleCards = (section: DailyActionsSection) => {
    if (section.id !== TODAY_SECTION_ID) {
      return null
    }

    const dragInfoText = (
      <Text type="regular" lineSpacing="tight" style={styles.infoText}>
        You can easily hold and drag each focus item to rearrange them as you prefer.
      </Text>
    )

    const alwaysVisibleCards = section.data.filter((item) =>
      ALWAYS_VISIBLE_CARDS.includes(item.key),
    )

    if (alwaysVisibleCards.length === 0) {
      return dragInfoText
    }

    return alwaysVisibleCards.map((item) => (
      <View key={item.key}>
        {dragInfoText}
        <SectionItem dailyAction={item} section={section} hasInteraction={false} pinned />
      </View>
    ))
  }

  const renderSectionItem = useCallback(
    ({
      section,
      item,
      drag,
    }: {
      section: DailyActionsSection
      item: DailyActionKind
      drag: () => void
    }) => {
      const alwaysVisible = ALWAYS_VISIBLE_CARDS.includes(item.key)

      if (!expandedSections.has(section.id) || alwaysVisible) {
        return null
      }

      return (
        <ShadowDecorator elevation={1} color={theme[`theme.background.light`]} radius={16}>
          <ScaleDecorator activeScale={1.05}>
            <TouchableOpacity
              onLongPress={() => {
                Haptic.heavyTap()
                drag()
              }}
              activeOpacity={1}
              delayLongPress={250}
              disabled={alwaysVisible || section.id !== TODAY_SECTION_ID}
              accessibilityLabel="draggable card"
            >
              <SectionItem
                dailyAction={item}
                section={section}
                key={item.key}
                hasInteraction={!alwaysVisible}
              />
            </TouchableOpacity>
          </ScaleDecorator>
        </ShadowDecorator>
      )
    },
    [expandedSections, theme],
  )

  const renderSectionHeader = useCallback(
    ({ section: { id, title } }: { section: DailyActionsSection }) => {
      return (
        <SectionHeader
          title={title}
          onPress={() => handleToggleSection(id)}
          expanded={expandedSections.has(id)}
        />
      )
    },
    [expandedSections],
  )

  return (
    <NavigationContainer
      style={styles.modal}
      leftIcon={<Icon name="x" size="20" weight="bold" style={styles.closeButton} />}
      title="Customize Today's Focus"
    >
      <View style={styles.container}>
        <NestableScrollContainer style={{ paddingBottom: insets.bottom }}>
          <ChartTypeSelectorSection />
          {sections.map((section) => (
            <>
              {renderSectionHeader({ section })}
              {/* We need to render static cards for each section outside of the draggable list */}
              {expandedSections.has(section.id) && renderAlwaysVisibleCards(section)}
              <NestableDraggableFlatList
                style={styles.draggableList}
                key={section.id}
                activationDistance={50}
                data={
                  section.id === TODAY_SECTION_ID && tempOrder.length === section.data.length
                    ? tempOrder
                    : section.data
                }
                renderItem={({ item, drag }) =>
                  renderSectionItem({
                    section,
                    item,
                    drag,
                  })
                }
                keyExtractor={(item) => item.key}
                onDragEnd={persistOrder}
              />
            </>
          ))}
        </NestableScrollContainer>
      </View>
    </NavigationContainer>
  )
}
const themedStyles = StyleService.create({
  modal: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  closeButton: {
    marginLeft: 16,
  },
  container: {
    flex: 1,
  },
  sectionList: {
    paddingHorizontal: 16,
  },
  draggableList: {
    overflow: 'visible',
  },
  infoText: {
    marginTop: -8,
    paddingLeft: 16,
    paddingRight: 72,
  },
})
