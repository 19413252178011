import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Feature, useFeatureFlag } from '@src/components'
import { InsuranceBerryStreetAppointmentType } from '@src/types'
import {
  latestUserAppointmentEarlierAvailableSlotsSelector,
  latestUserAppointmentSelector,
} from '../models/nutritionistHub.selectors'

export const useEarlierSlotAvailableAlert = () => {
  const [showAlert, setShowAlert] = useState(false)

  const navigation = useNavigation()

  const latestAppointment = useSelector(latestUserAppointmentSelector)

  const earlierAvailableSlotsCheckFeatureEnabled = useFeatureFlag(
    Feature.VideoCallsEarlierAvailableSlotsCheck,
  )

  const earlierAvailableSlots = useSelector(latestUserAppointmentEarlierAvailableSlotsSelector)

  const earlierSlotsAvailable = earlierAvailableSlots.length > 0

  useEffect(() => {
    setShowAlert(earlierAvailableSlotsCheckFeatureEnabled && earlierSlotsAvailable)
  }, [earlierAvailableSlotsCheckFeatureEnabled, earlierSlotsAvailable])

  const onPress = () => {
    if (!latestAppointment) {
      return
    }

    navigation.navigate('RescheduleVideoCallToEarlierSlot', {
      appointmentType: latestAppointment.appointmentType as InsuranceBerryStreetAppointmentType,
      appointmentId: latestAppointment.id,
      nutritionistName: latestAppointment.nutritionistName,
      title: latestAppointment.title,
      date: latestAppointment.date as string,
    })
  }

  return { showAlert: earlierAvailableSlotsCheckFeatureEnabled && showAlert, onPress }
}
