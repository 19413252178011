import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { take } from 'lodash'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade } from '@src/components'
import {
  insurancePolicySelector,
  pendingAppointmentsSelector,
  suggestedAvailabilitySelector,
  upcomingAppointmentsSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { AppStackParamList } from '@navigation/types'
import { SuggestedCallCard } from '@screens/NutritionistHub/components/UpcomingCalls/SuggestedCallCard.tsx'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'
import { CustomEventTypes, ErrorMessages } from '@config'
import { isCallConfirmed } from '@screens/NutritionistHub/utils.ts'
import { Appointment, InsuranceBerryStreetAppointmentType } from '@src/types'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import { useSnack } from '@src/utils'
import { DEFAULT_VIDEO_CALL_INFO } from '../../constants'

const SLOT_TAKEN_ERROR = 'slot is already taken'

export const UpcomingCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()
  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)
  const suggestedAvailability = useSelector(suggestedAvailabilitySelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const insuranceDeclined = insurancePolicy?.declined
  const pendingAppointments = useSelector(pendingAppointmentsSelector)

  const [canBookCall, setCanBookCall] = useState(false)

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
    dispatch({ type: 'nutritionistHub/fetchPendingAppointments' })
  }, [dispatch])

  useFocusEffect(
    useCallback(() => {
      if (!insuranceDeclined) {
        dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })
      }
    }, [dispatch, insuranceDeclined]),
  )

  const handleSeeAllPress = () => {
    track(CustomEventTypes.NutritionistHubSeeAllCallsTapped)
    navigation.navigate('AllVideoCalls')
  }

  const { getRequiredPolicyConsents, loading } = useGetRequiredPolicyConsents()

  useEffect(() => {
    setCanBookCall(
      insurancePolicy?.inReview ||
        insurancePolicy?.hasUnusedCalls ||
        pendingAppointments.length > 0,
    )
  }, [insurancePolicy, pendingAppointments])

  const onOpenSchedulerPress = ({
    appointmentType,
  }: {
    appointmentType: InsuranceBerryStreetAppointmentType
  }) => {
    track(CustomEventTypes.NutritionistHubSuggestedCallEditTapped)

    if (canBookCall) {
      navigation.navigate('CallScheduling', {
        appointmentType,
        allowBackNavigation: true,
      })
    } else {
      navigation.navigate('VideoCallCheckout', {})
    }
  }

  const onSchedulePress = ({
    appointmentType,
    meetingAt,
    nutritionistId,
  }: {
    appointmentType: InsuranceBerryStreetAppointmentType
    meetingAt: string
    nutritionistId: string
  }) => {
    track(CustomEventTypes.NutritionistHubSuggestedCallConfirmTapped)

    if (!canBookCall) {
      navigation.navigate('VideoCallCheckout', { suggestedCallFlow: true })
      return
    }

    dispatch({
      type: 'nutritionistHub/bookVideoCall',
      payload: {
        appointmentType,
        date: meetingAt,
        coachId: nutritionistId,
        pendingAppointmentId: pendingAppointments[0]?.id,
      },
      success: async () => {
        dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
        dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })
        dispatch({ type: 'nutritionistHub/fetchInsurancePolicy' })

        const requiredPolicies = await getRequiredPolicyConsents()
        if (requiredPolicies.length > 0) {
          navigation.navigate('PolicyConsent', { policy: requiredPolicies[0] })
        }
      },
      failure: (error: Error) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        if (errorMessage.includes(SLOT_TAKEN_ERROR)) {
          navigation.navigate('CallScheduling', {
            appointmentType,
            allowBackNavigation: true,
            errorMessage:
              'The suggested appointment time is no longer available. Please choose a different time slot',
          })
        } else {
          showSnack(errorMessage, null, 'error')
        }
      },
    })
  }

  const onCallCardPress = (appointment: Appointment) => {
    const {
      id,
      meetingAt,
      status,
      title,
      nutritionistName,
      addToGoogleCalendarLink,
      unauthenticatedIcsLink,
      appointmentType,
      chargeType,
    } = appointment

    if (isCallConfirmed(status)) {
      navigation.navigate('VideoCallConfirmedModal', {
        id,
        title: title || DEFAULT_VIDEO_CALL_INFO.title,
        nutritionistName,
        date: meetingAt,
        addToGoogleCalendarLink,
        unauthenticatedIcsLink,
        appointmentType: appointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
        chargeType,
      })
      return
    }

    navigation.navigate('VideoCallInReviewModal', {
      title: title || DEFAULT_VIDEO_CALL_INFO.title,
      nutritionistName,
      date: meetingAt,
    })
  }

  return (
    <ScrollViewWithFade>
      <View style={styles.container}>
        <View style={styles.sectionHeader}>
          <View style={styles.header}>
            <Icon name="calendar-blank" />
            <View style={styles.headerText}>
              <Text type="regular" bold>
                Upcoming Calls
              </Text>
            </View>
          </View>
          <Button
            accessibilityLabel="seeAllCalls"
            type="outline"
            size="small"
            style={styles.seeAllButton}
            onPress={handleSeeAllPress}
          >
            See All
          </Button>
        </View>
        <View style={styles.callsContainer}>
          {take(upcomingAppointments, 5).map((appointment) => {
            const {
              id,
              meetingAt,
              title,
              meetingStatus,
              nutritionistName,
              appointmentType,
              recurrent,
            } = appointment
            const inFuture = meetingAt && moment(meetingAt).isAfter(moment())
            return (
              <CallCard
                key={id}
                title={title}
                meetingAt={meetingAt}
                meetingStatus={meetingStatus}
                nutritionistName={nutritionistName}
                appointmentType={appointmentType}
                recurrent={recurrent}
                onPress={inFuture ? () => onCallCardPress(appointment) : undefined}
              />
            )
          })}
          {suggestedAvailability && insurancePolicy && !insuranceDeclined && (
            <SuggestedCallCard
              pillText="Suggested"
              title={suggestedAvailability.title}
              meetingAt={suggestedAvailability.meetingAt}
              nutritionistName={suggestedAvailability.nutritionistName}
              appointmentType={suggestedAvailability.appointmentType}
              textStyle={styles.suggestedCallText}
              onOpenSchedulerPress={() => {
                onOpenSchedulerPress({
                  appointmentType: suggestedAvailability.appointmentType,
                })
              }}
              onSchedulePress={() => {
                onSchedulePress({
                  appointmentType: suggestedAvailability.appointmentType,
                  meetingAt: suggestedAvailability.meetingAt,
                  nutritionistId: suggestedAvailability.nutritionistId,
                })
              }}
              schedulingDisabled={loading}
            />
          )}
        </View>
      </View>
    </ScrollViewWithFade>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginLeft: 20,
    lineHeight: 24,
    flexDirection: 'column',
  },
  seeAllButton: {
    alignSelf: 'center',
  },
  callsContainer: {
    rowGap: 24,
  },
  suggestedCallText: {
    color: 'theme.text.tertiary',
    marginTop: 12,
  },
})
