import React, { useState } from 'react'
import { useTheme } from '@ui-kitten/components'
import { TouchableOpacity } from 'react-native'
import DateTimePicker from 'react-native-modal-datetime-picker'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { useIsDarkMode } from '@src/config/theme'
import { DATE_FORMAT, DATE_FORMAT_FOR_PICKER } from '@src/config/momentFormat'
import { DateInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface DateInputProps extends InputPropsBase {
  response: DateInputConfig
}

const MINIMUM_DATE = new Date(1900, 0, 1)

const getMaxDate = (disallowFutureDates?: boolean, minimumAge?: number) => {
  if (minimumAge) {
    return moment().subtract(minimumAge, 'years').toDate()
  }

  if (disallowFutureDates) {
    return new Date()
  }
}

export const DateInput = ({ field, response }: DateInputProps) => {
  const isDarkMode = useIsDarkMode()
  const theme = useTheme()
  const styles = useStyleSheet(themedStyles)
  const [isDatePickerVisible, setDatePickerVisible] = useState(false)

  const fieldValue: string | null = field.value

  const { kindMetadata } = response

  const disallowFutureDates = kindMetadata?.disallow_future_dates
  const minimumAge = kindMetadata?.minimum_age
  const maxDate = getMaxDate(disallowFutureDates, minimumAge)
  const label = kindMetadata?.label

  useSetDefaultValue({ fieldValue, onChange: field.onChange, defaultValue: null })

  const onDatePicker = (date: Date) => {
    field.onChange(moment.utc(date).format(DATE_FORMAT))
    setDatePickerVisible(false)
  }

  return (
    <>
      {!!label && (
        <Text type="regular" lineSpacing="none" style={styles.label}>
          {label}
        </Text>
      )}
      <TouchableOpacity
        style={styles.container}
        accessibilityLabel="Select Date"
        activeOpacity={0.7}
        onPress={() => setDatePickerVisible(true)}
      >
        <Text type="regular" style={[styles.date, fieldValue ? styles.dateSelected : []]}>
          {fieldValue ? moment.utc(fieldValue).format(DATE_FORMAT_FOR_PICKER) : 'Select Date'}
        </Text>
        <DateTimePicker
          isDarkModeEnabled={isDarkMode}
          date={fieldValue ? moment.utc(fieldValue).toDate() : moment.utc().toDate()}
          isVisible={isDatePickerVisible}
          onConfirm={onDatePicker}
          timeZoneOffsetInMinutes={0}
          onCancel={() => setDatePickerVisible(false)}
          mode="date"
          minimumDate={MINIMUM_DATE}
          maximumDate={maxDate}
          textColor={theme['theme.text.primary']}
          pickerContainerStyleIOS={styles.pickerContainerIOS}
          themeVariant={isDarkMode ? 'dark' : 'light'}
        />
      </TouchableOpacity>
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    borderRadius: 8,
  },
  label: {
    marginBottom: 8,
  },
  date: {
    color: 'theme.text.tertiary',
  },
  dateSelected: {
    color: 'theme.text.primary',
  },
  pickerContainerIOS: {
    backgroundColor: 'theme.secondary.base',
  },
})
