import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { BottomSheet } from '@components'
import { Text, Pill } from '@components/base'
import { useGoBack } from '@src/utils'
import { authenticatedUserSelector } from '@src/selectors/app'
import { UiPillSize, UiPillType } from '@src/types'
import { FULL_DAY_MONTH_AND_DATE_FORMAT, VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT } from '../constants'

interface VideoCallDetailsModalProps {
  children: React.ReactNode
  title: string
  nutritionistName?: string | null
  date: string
  isCallConfirmed: boolean
}

export const VideoCallDetailsModal = (props: VideoCallDetailsModalProps) => {
  const { children, title, nutritionistName, date, isCallConfirmed } = props
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const user = useSelector(authenticatedUserSelector)

  return (
    <BottomSheet secondaryButton={{ text: 'Dismiss', onPress: goBack }}>
      <SafeAreaView style={styles.modalContent} edges={['bottom']}>
        <View style={styles.titleContainer}>
          <Text type="title-2" style={styles.title}>
            {title}
          </Text>
          <Pill
            status={isCallConfirmed ? UiPillType.Success : UiPillType.Warning}
            text={isCallConfirmed ? 'CONFIRMED' : 'PENDING'}
            size={UiPillSize.S}
          />
        </View>

        {nutritionistName && <Text type="large">{nutritionistName}</Text>}

        <Text type="large">{moment(date).format(FULL_DAY_MONTH_AND_DATE_FORMAT)}</Text>

        <Text type="large" style={styles.timeAndTimeZone}>
          {moment(date).format(VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT)}, {user.timeZone}
        </Text>

        {children}
      </SafeAreaView>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  modalContent: {
    flex: 0,
    flexBasis: 'auto',
    marginTop: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flex: 1,
  },
  timeAndTimeZone: {
    color: 'theme.text.secondary',
  },
})
