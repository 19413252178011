import React, { useEffect } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { FlatList } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { appointmentTypesSelector } from '@src/selectors/app'
import { Button, Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList, RootStackParamList } from '@src/navigation/types'
import { useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config'
import { NavigationContainer } from '@src/screens/Common/containers'
import { earlierAvailableSlotsByAppointmentIdSelector } from '../models/nutritionistHub.selectors'
import { SuggestedCallCard } from './UpcomingCalls'
import { CallCard } from './UpcomingCalls/CallCard'

const DEFAULT_TITLE = 'Video Call'

const SLOT_HEIGHT = 128

export const RescheduleVideoCallToEarlierSlot = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()

  const route = useRoute<RouteProp<RootStackParamList, 'RescheduleVideoCallToEarlierSlot'>>()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const showSnack = useSnack()

  const { appointmentId, appointmentType, nutritionistName, title, date } = route.params

  const earlierAvailableSlotsByAppointmentId = useSelector(
    earlierAvailableSlotsByAppointmentIdSelector,
  )
  const earlierAvailableSlotsForAppointment =
    earlierAvailableSlotsByAppointmentId[appointmentId] || []

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchEarlierAvailableSlots',
      payload: { appointmentId },
    })
  }, [dispatch, appointmentId])

  const appointmentTypes = useSelector(appointmentTypesSelector)

  const appointmentTypeConfig = appointmentTypes.find((config) => config.key === appointmentType)
  const navigationBarTitle = `Reschedule ${title || appointmentTypeConfig?.title || DEFAULT_TITLE}`

  const insets = useSafeAreaInsets()

  const openCalendar = () => {
    navigation.replace('CallScheduling', {
      appointmentType,
      appointmentId,
      allowBackNavigation: true,
    })
  }

  const rescheduleCall = (date: string) => {
    dispatch({
      type: 'nutritionistHub/rescheduleVideoCall',
      payload: {
        date,
        id: appointmentId,
      },
      success: () => {
        navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
      },
      failure: (error: any) => {
        dispatch({
          type: 'nutritionistHub/fetchEarlierAvailableSlots',
          payload: { appointmentId },
        })
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }

  return (
    <NavigationContainer
      title={navigationBarTitle}
      navigationBarProps={{ titleStyle: styles.navigationTitle }}
    >
      <Text type="regular" style={styles.title}>
        Select and reschedule your initial call to a sooner available slot that works for you
      </Text>
      <FlatList
        ListHeaderComponent={() => (
          <>
            <CallCard
              title={title}
              meetingAt={date}
              nutritionistName={nutritionistName}
              appointmentType={appointmentType}
              recurrent={false}
              style={styles.slotCard}
              header="Current slot"
            />
            {earlierAvailableSlotsForAppointment.length > 0 && (
              <Text type="small" bold style={styles.sectionText}>
                Available slots
              </Text>
            )}
          </>
        )}
        data={earlierAvailableSlotsForAppointment}
        renderItem={({ item: slot }) => (
          <SuggestedCallCard
            key={slot.time}
            style={styles.slotCard}
            pillText="New Slot Available"
            meetingAt={slot.time}
            appointmentType={appointmentType}
            onSchedulePress={() => {
              rescheduleCall(slot.time)
            }}
          />
        )}
        keyExtractor={(item) => item.time}
        getItemLayout={(_, index) => ({ length: SLOT_HEIGHT, offset: SLOT_HEIGHT * index, index })}
      />
      <Button
        type="outline"
        size="block"
        style={[styles.calendarButton, { marginBottom: Math.max(insets.bottom, 16) }]}
        onPress={openCalendar}
        accessibilityLabel="See Calendar"
      >
        See Calendar
      </Button>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  navigationTitle: {
    maxWidth: '90%',
  },
  title: {
    maxWidth: 280,
    marginTop: 8,
    marginBottom: 24,
    textAlign: 'center',
    alignSelf: 'center',
  },
  alert: {
    marginVertical: 16,
    marginHorizontal: 16,
  },
  calendarButton: {
    marginVertical: 16,
    marginHorizontal: 16,
  },
  slotCard: {
    marginHorizontal: 16,
    marginBottom: 32,
  },
  sectionText: {
    color: 'theme.text.secondary',
    marginLeft: 32,
    marginBottom: 16,
  },
})
