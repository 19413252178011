import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import {
  insurancePolicySelector,
  latestUserAppointmentSelector,
  topPriorityCoachAssignmentSelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { Icon, Text } from '@src/components/base'
import { Analytics, CustomEventTypes, Debug } from '@src/config'
import { appointmentTypesSelector, coachChatConversationSelector } from '@src/selectors/app'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  BillingProduct,
  BillingProductCategory,
  CoachAssignment,
  CoachAssignmentState,
  CoachAssignmentSupportType,
  Subscription,
} from '@src/types'
import { Intercom } from '@src/utils'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { useActionBottomSheet } from '@src/components'
import {
  VideoCallDetails,
  getSchedulingConfig,
  getVideoCallDetails,
  isBookingAvailable,
  isCallScheduled,
  isFollowupSchedulingAvailable,
} from '../utils'
import { NO_CURRENT_CHAT_SUPPORT, VIDEO_CHAT_AND_PAID_ASYNC_CHAT } from '../constants'
import { useInitialAppointmentType } from '../hooks'
import { Card } from './Card'

export const ChatCard = () => {
  const styles = useStyleSheet(themedStyle)
  const latestAppointment = useSelector(latestUserAppointmentSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const coachChatConversation = useSelector(coachChatConversationSelector)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const showActionBottomSheet = useActionBottomSheet()
  const videoCallDetails = latestAppointment
    ? getVideoCallDetails(latestAppointment)
    : ({} as VideoCallDetails)
  const subscriptions = useActiveSubscriptions()
  const complementarySupportSubscription = subscriptions.find(
    (subscription) => subscription.primaryProduct.key === BillingProduct.NutrisenseDietitianLimited,
  )
  const navigation = useNavigation()
  const dispatch = useDispatch()

  // Fetching this in case we show the buy Nutritionist plan option
  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: BillingProductCategory.Marketplace },
    })
  }, [dispatch])

  const directCoachConversationAllowedHandler = () => {
    if (!Debug.shouldEnableIntercom()) {
      return
    }

    Analytics.track(CustomEventTypes.NutritionistHubMessageBubbleTapped, {
      insurancePolicyState: insurancePolicy?.lastState?.kind,
      latestAppointmentStatus: latestAppointment && {
        status: latestAppointment.status,
        meetingStatus: latestAppointment.meetingStatus,
      },
    })

    if (coachChatConversation) {
      Intercom.showIntercomConversation(coachChatConversation.intercomId)
    } else {
      Intercom.showIntercomMessenger({ source: 'NutritionistHub' })
    }
  }

  const followupCallNotScheduled = isFollowupSchedulingAvailable(videoCallDetails)
  const initialAppointmentType = useInitialAppointmentType()
  const appointmentTypes = useSelector(appointmentTypesSelector)

  const schedulingConfig = getSchedulingConfig(
    videoCallDetails,
    followupCallNotScheduled,
    initialAppointmentType,
    appointmentTypes,
  )

  const bookCallOrPurchasePlanHandler = () => {
    navigation.navigate('ScheduleCallOrPurchasePlanModal', schedulingConfig.navigationParams)
  }

  const purchasePlanHandler = () => {
    navigation.navigate('MonthlyNutritionCoachingCheckout')
  }

  const coachAssignmentPendingHandler = () => {
    showActionBottomSheet({
      title: NO_CURRENT_CHAT_SUPPORT.title,
      body: 'Your Nutritionist will be assigned soon',
      primaryButton: {
        text: 'Dismiss',
      },
    })
  }

  const getVideoChatConfig = (state: CoachAssignmentState, canBookACall: boolean) => {
    if ([CoachAssignmentState.Active, CoachAssignmentState.Pending].includes(state)) {
      return {
        ...VIDEO_CHAT_AND_PAID_ASYNC_CHAT,
        onPressHandler: directCoachConversationAllowedHandler,
      }
    }

    if (canBookACall) {
      return {
        ...VIDEO_CHAT_AND_PAID_ASYNC_CHAT,
        onPressHandler: bookCallOrPurchasePlanHandler,
      }
    } else {
      return {
        ...NO_CURRENT_CHAT_SUPPORT,
        onPressHandler: purchasePlanHandler,
      }
    }
  }

  const getAsyncChatConfig = (
    state: CoachAssignmentState,
    complementarySupportSubscription: Subscription | undefined,
  ) => {
    if (state === CoachAssignmentState.Pending) {
      return {
        ...NO_CURRENT_CHAT_SUPPORT,
        onPressHandler: coachAssignmentPendingHandler,
      }
    }

    if (state === CoachAssignmentState.Active) {
      const title = complementarySupportSubscription
        ? 'Complementary Weekly Chat'
        : VIDEO_CHAT_AND_PAID_ASYNC_CHAT.title
      const description = complementarySupportSubscription
        ? `${moment(complementarySupportSubscription.cancelAt).diff(moment(), 'days')} days left`
        : VIDEO_CHAT_AND_PAID_ASYNC_CHAT.description
      return {
        title,
        description,
        buttonText: VIDEO_CHAT_AND_PAID_ASYNC_CHAT.buttonText,
        onPressHandler: directCoachConversationAllowedHandler,
      }
    }

    return {
      ...NO_CURRENT_CHAT_SUPPORT,
      onPressHandler: purchasePlanHandler,
    }
  }

  const getNoChatConfig = () => {
    return {
      ...NO_CURRENT_CHAT_SUPPORT,
      onPressHandler: purchasePlanHandler,
    }
  }

  const getConfig = (
    coachAssignment: CoachAssignment | null,
    complementarySupportSubscription: Subscription | undefined,
    videoCallDetails: VideoCallDetails,
  ) => {
    if (!coachAssignment) {
      return getNoChatConfig()
    }

    const canBookACall =
      !isCallScheduled(videoCallDetails) && isBookingAvailable(insurancePolicy, videoCallDetails)

    if (coachAssignment.supportType === CoachAssignmentSupportType.VideoChat) {
      return getVideoChatConfig(coachAssignment.state, canBookACall)
    }

    if (coachAssignment.supportType === CoachAssignmentSupportType.AsyncChat) {
      return getAsyncChatConfig(coachAssignment.state, complementarySupportSubscription)
    }

    return getNoChatConfig()
  }

  const config = getConfig(coachAssignment, complementarySupportSubscription, videoCallDetails)

  return (
    <Card buttonText={config.buttonText} onButtonPress={config.onPressHandler}>
      <Icon name="chat-circle-text" size="32" style={styles.header} />
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {config.title}
      </Text>
      <Text type="small" lineSpacing="none" style={styles.description}>
        {config.description}
      </Text>
    </Card>
  )
}

const themedStyle = StyleService.create({
  header: {
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    color: 'theme.text.secondary',
  },
})
