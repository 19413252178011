import { gql } from '@apollo/client'
import { CORE_USER_FIELDS } from './fragments/user'
import { CORE_ADDRESS_FIELDS } from './fragments/address'

export const SIGN_IN_USER = gql`
  ${CORE_USER_FIELDS}
  ${CORE_ADDRESS_FIELDS}
  mutation signinUser($email: String!, $password: String!, $impersonate: String) {
    signinUser(email: { email: $email, password: $password, impersonate: $impersonate }) {
      token
      success
      user {
        ...CoreUserFields
        address {
          ...CoreAddressFields
        }
        paymentMethod {
          stripeId
          cardType
          last4
          expirationDate
        }
        goalMetrics {
          primary
          kind
          lowThresholdValue
          highThresholdValue
          section
          statKey
        }
      }
      features
    }
  }
`
