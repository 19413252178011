import React from 'react'
import { CardStyleInterpolators, StackNavigationOptions } from '@react-navigation/stack'
import {
  AdminSettingsScreen,
  BarcodeIngredientSearchModal,
  ChartModal,
  MacroGoalsModal,
  ThresholdsModal,
  GlucoseThresholdsModal,
  ManualCalibrationModal,
  FastingWindowModal,
  MeasurementModal,
  ChatScreen,
  ScanModal,
  BluetoothPermissionModal,
  ErrorScanningModal,
  ConnectivityErrorModal,
  SourcePriorityListScreen,
  HistoryEditScreen,
  ScansScreen,
  ActivityModal,
  ActivityDetailModal,
  SubscriptionScreen,
  ChangePasswordScreen,
  ReadyToParticipateScreen,
  ResearchSurveysScreen,
  EditAccountDetailsScreen,
  UserSettingsScreen,
  SensorSettingsScreen,
  IntegrationsScreen,
  EducationScreen,
  SkipTutorialScreen,
  CGMEducationScreen,
  GlucoseEducationScreen,
  ArticlesScreen,
  TerraProviderScreen,
  RefillConsentScreen,
  LearnScreen,
  ProgramScreen,
  ModuleScreen,
  LessonScreen,
  LessonExperimentModal,
  SensorActivationModal,
  ModuleCompletionScreen,
  LibreAuthScreen,
  LibreLinkupIntegrationScreen,
  CGMActivatedScreen,
  GlucoseScoreModal,
  NutritionSummaryModal,
  TimelineTutorialModal,
  PermissionsCarousel,
  HQApprovalScreen,
  RescheduleOrCancelVideoCallModal,
  LateRescheduleOrCancelVideoCallModal,
  FreePassLateRescheduleOrCancelVideoCallModal,
  VideoCallConfirmedModal,
  VideoCallInReviewModal,
  ReferralScreen,
  VideoCallsIntroModal,
  VideoCallsGettingStartedScreen,
  VideoCallCheckoutScreen,
  OnboardingChecklistScreen,
  ClaimFreeVideoCallModal,
  EarlierSlotAvailableModal,
  ScheduleCallOrPurchasePlanModal,
  AddLabResultsScreen,
  VideoCallsUpsellModal,
  VideoCallsUpsellDetailsModal,
  UpgradeToDexcomSensorScreen,
} from '@screens'
import { HorizontalChartModal } from '@src/navigation/containers/HorizontalChartModal'
import { CalendarModal } from '@src/navigation/containers/CalendarModal'
import { TutorialModal, WebViewModal } from '@components'
import { Debug } from '@config'
import { DrawerNavigator } from '@src/navigation'
import { ActionsModal } from '@src/navigation/containers/ActionsModal'
import { IntercomMenuModal } from '@src/navigation/containers/IntercomMenuModal'
import {
  fadeFromBottomModalOptions,
  fadeInModalOptions,
  gestureDismissibleModalOptions,
  tutorialModalOptions,
} from '@src/navigation/utils'
import { ShareViewModal } from '@src/containers/ShareViewModal'
import { CancelModal } from '@src/components/CancelModal'
import { CustomIngredient, IngredientPortion, IngredientSearch } from '@src/screens/Ingredients'
import {
  CompareMeals,
  CompareMealsDetails,
  CreateMealModal,
  EditMealModal,
  MealDetailModal,
  ShareMealComparison,
  UnlockNutritionistSupport,
} from '@src/screens/Meals'
import {
  CancelSubscriptionConfirmationScreen,
  CancelSubscriptionOnCommitmentScreen,
  CancelSubscriptionOtherOptionsScreen,
  ChurnRecoveryOptionsScreen,
  PauseSubscriptionScreen,
  PauseSubscriptionReviewScreen,
  PauseSubscriptionSuccessScreen,
  ResumeSubscriptionModal,
  ReactivateSubscriptionModal,
  ChangeBillingCycleModal,
  ChangeBillingCycleSuccessModal,
  ProgramDetailsScreen,
  OrderConfirmedScreen,
  DietitianAddonOrderConfirmedScreen,
  SubscriptionCheckoutScreen,
  ProductsSelectionModal,
  ManageUpcomingSubscriptionModal,
  AddonCheckoutScreen,
  DietitianMenuCheckoutScreen,
  CheckoutWithInsuranceOptionScreen,
  DietitianMenuGroupProductSelectorScreen,
  DietitianMenuFeedbackModal,
  CancelSubscriptionSwitchSensorScreen,
  SensorSwitchConfirmedScreen,
  InsuranceCallsUpsellScreen,
  ChurnRecoveryUpsellOfferScreen,
  ChurnRecoveryUpsellOfferConfirmationScreen,
} from '@src/screens/Marketplace'
import {
  HealthKitIntegrationScreen,
  KetoMojoIntegrationScreen,
  SettingsScreen,
} from '@src/screens/Settings'
import { TutorialsGroup } from '@src/screens/Tutorials/containers/TutorialsGroup'
import { CMSTutorial } from '@src/screens/Tutorials/containers/CMSTutorial'
import { VideoTutorial } from '@src/screens/Tutorials/containers/VideoTutorial'
import { JournalEntryModal } from '@src/screens/JournalEntries'
import { EditDailyMeasurementModal } from '@src/screens/DailyMeasurements/components/EditDailyMeasurementModal'
import { ChatWelcomeModal } from '@src/screens/Chat/components/ChatWelcomeModal'
import { ChatDelimiterConfirmationModal } from '@src/screens/Chat/components/ChatDelimiterConfirmationModal'
import { SensorWarmUpModal } from '@src/screens/Scans'
import { JournalEngagementPromptModal } from '@src/screens/JournalEntries/containers/JournalEngagementPromptModal'
import {
  AddReminderModal,
  EditReminderModal,
  EditScanReminderModal,
  NotificationsSettingsScreen,
} from '@src/screens/Settings/components/Notifications'
import { DexcomResearchOptedOutModal } from '@src/screens/Profile'
import { SubscriptionsNavigator } from '@src/navigation/navigators/subscriptions.navigator'
import { ChooseGoalModal } from '@src/screens/Insights/components/ChooseGoalModal'
import { ChoosePrimaryGoalMetricsModal } from '@src/screens/Insights/components/ChoosePrimaryGoalMetricsModal'
import { InsightsDetailsScreen, InsightsSettings } from '@src/screens/Insights'
import { RateOurAppModal } from '@src/navigation/containers/RateOurAppModal'
import {
  BookingConfirmationScreen,
  MonthlyNutritionCoachingCheckoutScreen,
  PaidCallsIntroScreen,
  FreePassLateRescheduleVideoCallModal,
  LateRescheduleVideoCallModal,
  FeePaymentIncompleteModal,
  PaymentFailed,
  FeePaymentScreen,
  FeePaymentSuccessfulModal,
  FreeVideoCallScreen,
  VideoCallCheckIn,
  VideoCallCheckInAddendum,
  CheckInCompletedModal,
  SubmitInsuranceCardScreen,
  CallSchedulingScreen,
  RescheduleVideoCallToEarlierSlotScreen,
  SetWeightLossGoalScreen,
  ConfirmWeightLossRecurrenceScreen,
} from '@src/screens/NutritionistHub'
import { LabResultsScreen, LabResultsSubmittedScreen, BiomarkerForm } from '@src/screens/LabResults'
import { MemberNoteDetail } from '@src/screens/NutritionistHub/components/MemberNotes/MemberNoteDetail'
import { MemberNotes } from '@src/screens/NutritionistHub/components/MemberNotes/MemberNotes'
import {
  AllVideoCalls,
  UpsertRecurrence,
  UpsertRecurrenceSuccessfulModal,
} from '@screens/NutritionistHub/components/UpcomingCalls'
import { SetReminderModal } from '@src/screens/LabResults/components/SetReminderModal'
import { AllProductsScreen } from '@src/screens/NutritionistHub/components/NutritionistMenu/AllProductsScreen'
import { EligibilityCheckScreen, FreeTrialSubscriptionOfferScreen } from '@src/screens/SignUp'
import {
  CheckConnectionScreen,
  ConfirmSensorSelectionScreen,
} from '@src/screens/OwnSensorOnboarding'
import { ConnectionSuccessfulScreen } from '@src/screens/OwnSensorOnboarding/ConnectionSuccessfulScreen'
import { InsuranceStatusScreen } from '@src/screens/InsuranceStatus'
import { PolicyConsentScreen, RequiredPolicyConsentScreen } from '@src/screens/PolicyConsents'
import { EditDailyActionsModal } from '@src/screens/DailyActions/EditDailyActions/EditDailyActionsModal'
import { HomeScreenWalkthroughModal } from '@src/screens/Events/components/HomeScreenWalkthroughModal'
import { InsurancePolicyForm } from '@src/screens/InsuranceStatus/components/InsurancePolicyForm'
import { ScanDisableAnnouncement } from '@src/screens/Scans/components/ScanDisableAnnouncement'
import { ChurnRecoveryProactiveUpsellOffer } from '@src/screens/Marketplace/components/ChurnRecovery'
import { ScanMigrationGuide } from '@src/screens/Scans/components/ScanMigrationGuide'
// eslint-disable-next-line max-len
import { IntegrationSuccessfulScreen } from '@src/screens/Settings/components/Integrations/LibreAuth/IntegrationSuccessfulScreen'
import { InsuranceNotEligibleModal } from '@src/screens/NutritionistHub/components/InsuranceNotEligibleModal'
import {
  ActivationInfoScreen,
  RemainingSensorsQuestionScreen,
  SwitchToDexcomNotSuccessfulScreen,
  FinalizePrescriptionDexcomSwitchScreen,
} from '@src/screens/Dexcom/Prescription'
import { DrawerRoute } from '../components/drawer/hooks'
import { SensorInfoModal } from '../components/SensorInfoModal'

// TODO: Use corresponding type for AppStack
export const getAppNavigator = (
  AppStack: any,
  options: StackNavigationOptions,
  hasLargeScreen: boolean,
  drawerRoutes: DrawerRoute[],
) => {
  return (
    <>
      {/* Drawer screen should be the first one */}
      <AppStack.Screen options={options} name="Drawer" component={DrawerNavigator} />

      <AppStack.Group
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
          presentation: 'transparentModal',
        }}
      >
        <AppStack.Screen name="HomeScreenWalkthroughModal" component={HomeScreenWalkthroughModal} />
      </AppStack.Group>

      <AppStack.Group
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          presentation: 'transparentModal',
        }}
      >
        <AppStack.Screen name="AddJournalEntry" component={JournalEntryModal} />
        <AppStack.Screen name="AddScan" component={ScanModal} />
        <AppStack.Screen name="CGMActivated" component={CGMActivatedScreen} />
        <AppStack.Screen name="OrderConfirmed" component={OrderConfirmedScreen} />
        <AppStack.Screen
          name="DietitianAddonOrderConfirmed"
          component={DietitianAddonOrderConfirmedScreen}
        />
        <AppStack.Screen name="AddActivity" component={ActivityModal} />
        <AppStack.Screen name="AddMeal" component={CreateMealModal} />
        <AppStack.Screen name="AddMeasurement" component={MeasurementModal} />
        <AppStack.Screen name="ShowHorizontalChart" component={HorizontalChartModal} />
        <AppStack.Screen name="BarcodeIngredientSearch" component={BarcodeIngredientSearchModal} />
        <AppStack.Screen name="ShareViewModal" component={ShareViewModal} />
        <AppStack.Screen name="EducationModal" component={EducationScreen} />
        <AppStack.Screen name="OnboardingChecklist" component={OnboardingChecklistScreen} />
        <AppStack.Screen name="ReadyToParticipateScreen" component={ReadyToParticipateScreen} />
        <AppStack.Screen name="HQApproval" component={HQApprovalScreen} />
        <AppStack.Screen name="EditDailyActionsModal" component={EditDailyActionsModal} />
        <AppStack.Screen name="ActivationInfoScreen" component={ActivationInfoScreen} />
        <AppStack.Screen
          name="RemainingSensorsQuestionScreen"
          component={RemainingSensorsQuestionScreen}
        />
        <AppStack.Screen
          name="FinalizePrescriptionDexcomSwitchScreen"
          component={FinalizePrescriptionDexcomSwitchScreen}
        />
        <AppStack.Screen
          name="SwitchToDexcomNotSuccessfulScreen"
          component={SwitchToDexcomNotSuccessfulScreen}
        />
      </AppStack.Group>
      <AppStack.Group screenOptions={gestureDismissibleModalOptions}>
        <AppStack.Screen name="ChooseGoalModal" component={ChooseGoalModal} />
        <AppStack.Screen name="SensorWarmUpModal" component={SensorWarmUpModal} />
        <AppStack.Screen name="IngredientPortion" component={IngredientPortion} />
        <AppStack.Screen name="ResumeSubscriptionModal" component={ResumeSubscriptionModal} />
        <AppStack.Screen
          name="ReactivateSubscriptionModal"
          component={ReactivateSubscriptionModal}
        />
        <AppStack.Screen name="ChangeBillingCycleModal" component={ChangeBillingCycleModal} />
        <AppStack.Screen name="AddReminderModal" component={AddReminderModal} />
        <AppStack.Screen name="EditReminderModal" component={EditReminderModal} />
        <AppStack.Screen name="EditScanReminderModal" component={EditScanReminderModal} />
        <AppStack.Screen
          name="DexcomResearchOptedOutModal"
          component={DexcomResearchOptedOutModal}
        />
        <AppStack.Screen name="PaymentFailed" component={PaymentFailed} />
        <AppStack.Screen name="FeePaymentScreen" component={FeePaymentScreen} />
        <AppStack.Screen
          name="ScheduleCallOrPurchasePlanModal"
          component={ScheduleCallOrPurchasePlanModal}
        />
      </AppStack.Group>
      <AppStack.Group screenOptions={fadeInModalOptions}>
        <AppStack.Screen name="ShowActions" component={ActionsModal} />
        <AppStack.Screen name="GlucoseScoreModal" component={GlucoseScoreModal} />
        <AppStack.Screen name="NutritionSummaryModal" component={NutritionSummaryModal} />
        <AppStack.Screen name="JournalEngagementPrompt" component={JournalEngagementPromptModal} />
      </AppStack.Group>
      <AppStack.Group screenOptions={fadeFromBottomModalOptions}>
        <AppStack.Screen
          name="ChurnRecoveryProactiveUpsellOffer"
          component={ChurnRecoveryProactiveUpsellOffer}
        />
        <AppStack.Screen name="BluetoothPermissionModal" component={BluetoothPermissionModal} />
        <AppStack.Screen name="ConnectivityErrorModal" component={ConnectivityErrorModal} />
        <AppStack.Screen name="ErrorScanningModal" component={ErrorScanningModal} />
        <AppStack.Screen name="TimelineTutorialModal" component={TimelineTutorialModal} />
        <AppStack.Screen name="ScanDisableAnnouncement" component={ScanDisableAnnouncement} />
        <AppStack.Screen
          name="ChoosePrimaryGoalMetricsModal"
          component={ChoosePrimaryGoalMetricsModal}
        />
        <AppStack.Screen
          name="RescheduleOrCancelVideoCallModal"
          component={RescheduleOrCancelVideoCallModal}
        />
        <AppStack.Screen
          name="LateRescheduleOrCancelVideoCallModal"
          component={LateRescheduleOrCancelVideoCallModal}
        />
        <AppStack.Screen
          name="FreePassLateRescheduleOrCancelVideoCallModal"
          component={FreePassLateRescheduleOrCancelVideoCallModal}
        />
        <AppStack.Screen
          name="LateRescheduleVideoCallModal"
          component={LateRescheduleVideoCallModal}
        />
        <AppStack.Screen
          name="FreePassLateRescheduleVideoCallModal"
          component={FreePassLateRescheduleVideoCallModal}
        />
        <AppStack.Screen name="FeePaymentSuccessfulModal" component={FeePaymentSuccessfulModal} />
        <AppStack.Screen name="FeePaymentIncompleteModal" component={FeePaymentIncompleteModal} />
        <AppStack.Screen name="ClaimFreeVideoCallModal" component={ClaimFreeVideoCallModal} />
        <AppStack.Screen name="EarlierSlotAvailableModal" component={EarlierSlotAvailableModal} />
        <AppStack.Screen name="InsuranceNotEligibleModal" component={InsuranceNotEligibleModal} />
        <AppStack.Screen name="VideoCallConfirmedModal" component={VideoCallConfirmedModal} />
        <AppStack.Screen name="VideoCallInReviewModal" component={VideoCallInReviewModal} />
        <AppStack.Screen name="VideoCallsIntroModal" component={VideoCallsIntroModal} />
        <AppStack.Screen name="VideoCallCheckIn" component={VideoCallCheckIn} />
        <AppStack.Screen name="VideoCallCheckInAddendum" component={VideoCallCheckInAddendum} />
        <AppStack.Screen name="CheckInCompletedModal" component={CheckInCompletedModal} />
        <AppStack.Screen name="ChangeMacroGoals" component={MacroGoalsModal} />
        <AppStack.Screen name="GlucoseThresholdSettings" component={GlucoseThresholdsModal} />
        <AppStack.Screen name="IntercomMenu" component={IntercomMenuModal} />
        <AppStack.Screen name="ChangeDateRange" component={CalendarModal} />
        <AppStack.Screen name="RefillConsent" component={RefillConsentScreen} />
        <AppStack.Screen name="PermissionsCarousel" component={PermissionsCarousel} />
        <AppStack.Screen name="CancelModal" component={CancelModal} />
        <AppStack.Screen name="RateOurAppModal" component={RateOurAppModal} />
        <AppStack.Screen name="ThresholdSettings" component={ThresholdsModal} />
        <AppStack.Screen name="CalibrateSensor" component={ManualCalibrationModal} />
        <AppStack.Screen name="ChangeFastingWindow" component={FastingWindowModal} />
        <AppStack.Screen name="ProductsSelection" component={ProductsSelectionModal} />
        <AppStack.Screen name="UnlockNutritionistSupport" component={UnlockNutritionistSupport} />
        <AppStack.Screen name="ShareMealComparison" component={ShareMealComparison} />
        <AppStack.Screen name="WebViewModal" component={WebViewModal} />
        <AppStack.Screen name="CoursesSensorActivation" component={SensorActivationModal} />
        <AppStack.Screen name="TutorialsGroup" component={TutorialsGroup} />
        <AppStack.Screen name="ZoomChart" component={ChartModal} />
        <AppStack.Screen name="ChatWelcomeModal" component={ChatWelcomeModal} />
        <AppStack.Screen
          name="ChatDelimiterConfirmationModal"
          component={ChatDelimiterConfirmationModal}
        />
        <AppStack.Screen
          name="ChangeBillingCycleSuccessModal"
          component={ChangeBillingCycleSuccessModal}
        />
        <AppStack.Screen
          name="ManageUpcomingSubscriptionModal"
          component={ManageUpcomingSubscriptionModal}
        />
        <AppStack.Screen name="SetReminderModal" component={SetReminderModal} />
        <AppStack.Screen name="VideoCallsUpsellModal" component={VideoCallsUpsellModal} />
        <AppStack.Screen
          name="VideoCallsUpsellDetailsModal"
          component={VideoCallsUpsellDetailsModal}
        />
        <AppStack.Screen name="RequiredPolicyConsent" component={RequiredPolicyConsentScreen} />
        <AppStack.Screen name="PolicyConsent" component={PolicyConsentScreen} />
        <AppStack.Screen
          name="UpsertRecurrenceSuccessfulModal"
          component={UpsertRecurrenceSuccessfulModal}
        />
      </AppStack.Group>
      {!hasLargeScreen &&
        drawerRoutes.map(({ routeName, component }) => (
          <AppStack.Screen key={routeName} name={routeName} component={component} />
        ))}

      <AppStack.Screen name="ActivityDetail" component={ActivityDetailModal} />
      <AppStack.Screen name="EditJournalEntry" component={JournalEntryModal} />
      <AppStack.Screen name="EditMeasurement" component={MeasurementModal} />
      <AppStack.Screen name="MealDetail" component={MealDetailModal} />
      <AppStack.Screen name="EditMeal" component={EditMealModal} />
      <AppStack.Screen name="EditDailyMeasurementModal" component={EditDailyMeasurementModal} />
      <AppStack.Screen name="CompareMeals" component={CompareMeals} />
      <AppStack.Screen name="CompareMealsDetails" component={CompareMealsDetails} />
      <AppStack.Screen name="ScanMigrationGuide" component={ScanMigrationGuide} />
      <AppStack.Screen
        name="ShowTutorial"
        component={TutorialModal}
        options={tutorialModalOptions}
      />

      <AppStack.Screen name="AppSettings" component={SettingsScreen} />
      <AppStack.Screen name="FriendReferral" component={ReferralScreen} />
      <AppStack.Screen name="Subscriptions" component={SubscriptionsNavigator} />
      <AppStack.Screen
        name="FreeTrialSubscriptionOffer"
        component={FreeTrialSubscriptionOfferScreen}
      />
      <AppStack.Screen name="EligibilityCheck" component={EligibilityCheckScreen} />
      <AppStack.Screen name="IngredientSearch" component={IngredientSearch} />
      <AppStack.Screen name="CustomIngredient" component={CustomIngredient} />
      <AppStack.Screen name="Scans" component={ScansScreen} />
      <AppStack.Screen name="HistoryEdit" component={HistoryEditScreen} />
      <AppStack.Screen name="AccountSubscriptions" component={SubscriptionScreen} />
      <AppStack.Screen name="ChangePassword" component={ChangePasswordScreen} />
      <AppStack.Screen name="ResearchSurveys" component={ResearchSurveysScreen} />
      <AppStack.Screen name="AddLabResults" component={AddLabResultsScreen} />
      <AppStack.Screen name="EditAccountDetails" component={EditAccountDetailsScreen} />
      <AppStack.Screen name="ProgramDetails" component={ProgramDetailsScreen} />
      <AppStack.Screen name="SubscriptionCheckout" component={SubscriptionCheckoutScreen} />
      <AppStack.Screen
        name="CancelSubscriptionOtherOptions"
        component={CancelSubscriptionOtherOptionsScreen}
      />
      <AppStack.Screen
        name="CancelSubscriptionOnCommitment"
        component={CancelSubscriptionOnCommitmentScreen}
      />
      <AppStack.Screen
        name="CancelSubscriptionSwitchSensor"
        component={CancelSubscriptionSwitchSensorScreen}
      />
      <AppStack.Screen name="SensorSwitchConfirmed" component={SensorSwitchConfirmedScreen} />
      <AppStack.Screen
        name="CancelSubscriptionConfirmation"
        component={CancelSubscriptionConfirmationScreen}
      />
      <AppStack.Screen name="PauseSubscription" component={PauseSubscriptionScreen} />
      <AppStack.Screen name="PauseSubscriptionReview" component={PauseSubscriptionReviewScreen} />
      <AppStack.Screen name="PauseSubscriptionSuccess" component={PauseSubscriptionSuccessScreen} />
      <AppStack.Screen name="ChurnRecoveryOptions" component={ChurnRecoveryOptionsScreen} />
      <AppStack.Screen name="ChurnRecoveryUpsellOffer" component={ChurnRecoveryUpsellOfferScreen} />
      <AppStack.Screen
        name="ChurnRecoveryUpsellOfferConfirmation"
        component={ChurnRecoveryUpsellOfferConfirmationScreen}
      />
      <AppStack.Screen name="InsuranceCallsUpsellScreen" component={InsuranceCallsUpsellScreen} />
      <AppStack.Screen name="UserSettings" component={UserSettingsScreen} />
      <AppStack.Screen name="NotificationsSettings" component={NotificationsSettingsScreen} />
      <AppStack.Screen name="SensorSettings" component={SensorSettingsScreen} />
      <AppStack.Screen name="SourcePriorityList" component={SourcePriorityListScreen} />
      <AppStack.Screen name="Integrations" component={IntegrationsScreen} />
      <AppStack.Screen name="SkipTutorial" component={SkipTutorialScreen} />
      <AppStack.Screen name="TerraProvider" component={TerraProviderScreen} />
      <AppStack.Screen name="LibreAuth" component={LibreAuthScreen} />
      <AppStack.Screen name="LibreLinkupIntegration" component={LibreLinkupIntegrationScreen} />
      <AppStack.Screen name="HealthKitIntegration" component={HealthKitIntegrationScreen} />
      <AppStack.Screen name="KetoMojoIntegration" component={KetoMojoIntegrationScreen} />
      <AppStack.Screen name="CGMEducation" component={CGMEducationScreen} />
      <AppStack.Screen name="FAQs" component={GlucoseEducationScreen} />
      <AppStack.Screen name="FAQsArticles" component={ArticlesScreen} />
      <AppStack.Screen name="SensorInfoModal" component={SensorInfoModal} />
      {Debug.useAdminMenu && (
        <AppStack.Screen name="AdminSettings" component={AdminSettingsScreen} />
      )}

      <AppStack.Screen name="CoursesLearn" component={LearnScreen} />
      <AppStack.Screen name="CoursesProgram" component={ProgramScreen} />
      <AppStack.Screen name="CoursesModule" component={ModuleScreen} />
      <AppStack.Screen name="CoursesLesson" component={LessonScreen} />
      <AppStack.Screen
        name="CoursesLessonItem"
        component={LessonScreen}
        options={{ animationEnabled: false }}
      />
      <AppStack.Screen
        name="CoursesModuleCompletion"
        component={ModuleCompletionScreen}
        options={{ animationEnabled: false }}
      />
      <AppStack.Screen name="CoursesExperiment" component={LessonExperimentModal} />
      <AppStack.Screen name="CMSTutorial" component={CMSTutorial} />
      <AppStack.Screen name="CheckConnection" component={CheckConnectionScreen} />
      <AppStack.Screen name="ConnectionSuccessful" component={ConnectionSuccessfulScreen} />
      <AppStack.Screen name="IntegrationSuccessful" component={IntegrationSuccessfulScreen} />
      <AppStack.Screen name="VideoTutorial" component={VideoTutorial} />
      <AppStack.Screen name="Chat" component={ChatScreen} />
      <AppStack.Screen name="CallScheduling" component={CallSchedulingScreen} />
      <AppStack.Screen
        name="RescheduleVideoCallToEarlierSlot"
        component={RescheduleVideoCallToEarlierSlotScreen}
      />
      <AppStack.Screen name="ActivateSensorTutorialsGroup" component={TutorialsGroup} />
      <AppStack.Screen
        name="VideoCallsGettingStartedScreen"
        component={VideoCallsGettingStartedScreen}
      />
      <AppStack.Screen name="PaidCallsIntroScreen" component={PaidCallsIntroScreen} />
      <AppStack.Screen name="BookingConfirmationScreen" component={BookingConfirmationScreen} />
      <AppStack.Screen name="VideoCallCheckout" component={VideoCallCheckoutScreen} />
      <AppStack.Screen name="FreeVideoCall" component={FreeVideoCallScreen} />
      <AppStack.Screen name="MemberNoteDetail" component={MemberNoteDetail} />
      <AppStack.Screen name="MemberNotes" component={MemberNotes} />
      <AppStack.Screen name="AllProducts" component={AllProductsScreen} />
      <AppStack.Screen name="InsightsSettings" component={InsightsSettings} />
      <AppStack.Screen name="InsightsDetailedPage" component={InsightsDetailsScreen} />
      <AppStack.Screen
        name="MonthlyNutritionCoachingCheckout"
        component={MonthlyNutritionCoachingCheckoutScreen}
      />
      <AppStack.Screen name="AddonCheckout" component={AddonCheckoutScreen} />
      <AppStack.Screen name="DietitianMenuCheckout" component={DietitianMenuCheckoutScreen} />
      <AppStack.Screen
        name="CheckoutWithInsuranceOption"
        component={CheckoutWithInsuranceOptionScreen}
      />
      <AppStack.Screen
        name="DietitianMenuGroupProductSelector"
        component={DietitianMenuGroupProductSelectorScreen}
      />
      <AppStack.Screen
        name="DietitianMenuFeedback"
        component={DietitianMenuFeedbackModal}
        options={fadeFromBottomModalOptions}
      />
      <AppStack.Screen name="LabResultsSubmittedScreen" component={LabResultsSubmittedScreen} />
      <AppStack.Screen name="LabResultsScreen" component={LabResultsScreen} />
      <AppStack.Screen name="BiomarkerForm" component={BiomarkerForm} />
      <AppStack.Screen name="AllVideoCalls" component={AllVideoCalls} />
      <AppStack.Screen name="UpsertRecurrence" component={UpsertRecurrence} />
      <AppStack.Screen name="SetWeightLossGoal" component={SetWeightLossGoalScreen} />
      <AppStack.Screen
        name="ConfirmWeightLossRecurrence"
        component={ConfirmWeightLossRecurrenceScreen}
      />
      <AppStack.Screen name="InsuranceStatus" component={InsuranceStatusScreen} />
      <AppStack.Screen name="ConfirmSensorSelection" component={ConfirmSensorSelectionScreen} />
      <AppStack.Screen name="UpgradeToDexcomSensor" component={UpgradeToDexcomSensorScreen} />
      <AppStack.Screen name="SubmitInsuranceCardScreen" component={SubmitInsuranceCardScreen} />
      <AppStack.Screen name="InsurancePolicyForm" component={InsurancePolicyForm} />
    </>
  )
}
