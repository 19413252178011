import React from 'react'
import { ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useNutritionistMenuProducts } from '../../hooks/useNutritionistMenuProducts'
import { ProductsGrid } from './ProductsGrid'

export const AllProductsScreen = () => {
  const nutritionistMenuProducts = useNutritionistMenuProducts()
  const insets = useSafeAreaInsets()

  return (
    <NavigationContainer title="Upgrades and Add-ons">
      <ScrollView
        contentContainerStyle={{ paddingBottom: insets.bottom + 16, marginHorizontal: 16 }}
      >
        <ProductsGrid products={nutritionistMenuProducts} productsPerRow={2} />
      </ScrollView>
    </NavigationContainer>
  )
}
