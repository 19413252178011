import React, { useRef } from 'react'
import { Keyboard, TouchableWithoutFeedback, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Checkbox, Input, Text } from '@components/base'
import { SecureInput } from '@components/inputs/SecureInput'
import { PasswordValidationInfo } from '@components/inputs/PasswordValidationInfo/PasswordValidationInfo'
import {
  termsOfUseSelector,
  privacyPolicySelector,
  telehealthSelector,
  consumerHealthDataPrivacyPolicySelector,
} from '@selectors/app'
import { openUrl, Validators } from '@utils'
import { Asset } from '@utils/image'
import { useSnack } from '@utils/navigatorContext'
import { LegalPolicyConsentKind } from '@src/types'

interface SignUpFormProps {
  photo?: Partial<Asset>
}

export const SignUpForm = ({ photo }: SignUpFormProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const termsOfUseUrl = useSelector(termsOfUseSelector)
  const privacyPolicyUrl = useSelector(privacyPolicySelector)
  const telehealthPolicyUrl = useSelector(telehealthSelector)
  const consumerHealthDataPrivacyPolicyUrl = useSelector(consumerHealthDataPrivacyPolicySelector)

  const {
    control,
    formState: { errors, isValid },
    setFocus,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      termsAccepted: false,
    },
  })

  const isLoading = useRef(false)

  const onSignUpPress = handleSubmit(({ firstName, lastName, email, password }) => {
    Keyboard.dismiss()

    if (isLoading.current) {
      return
    }

    isLoading.current = true

    dispatch({
      type: 'users/create',
      payload: {
        firstName,
        lastName,
        email,
        password,
        photo,
        policyConsentKinds: [
          LegalPolicyConsentKind.TermsOfUse,
          LegalPolicyConsentKind.PrivacyPolicy,
          LegalPolicyConsentKind.Telehealth,
          LegalPolicyConsentKind.ConsumerHealthDataPrivacyPolicy,
        ],
      },
      navigation,
      success: () => {
        dispatch({ type: 'app/config' })
      },
      failure: ({ status }: { status: string }) => {
        if (status === 'exists') {
          showSnack('Member with this email already exists!', null, 'error')
        } else {
          showSnack('Server error, please try again', null, 'error')
        }
      },
      complete: () => {
        isLoading.current = false
      },
    })
  })

  const termsAccepted = watch('termsAccepted')

  const checkboxContent = (
    <Text type="regular" style={styles.checkBoxContent}>
      I agree to the{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Terms & Conditions"
        onPress={() => openUrl(termsOfUseUrl)}
      >
        <Text type="regular" bold>
          Terms & Conditions
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Telehealth Consent"
        onPress={() => openUrl(telehealthPolicyUrl)}
      >
        <Text type="regular" bold>
          Telehealth Consent
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Privacy Policy"
        onPress={() => openUrl(privacyPolicyUrl)}
      >
        <Text type="regular" bold>
          Privacy Policy
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Consumer Health Data Privacy Policy"
        onPress={() => openUrl(consumerHealthDataPrivacyPolicyUrl)}
      >
        <Text type="regular" bold>
          Consumer Health Data Privacy Policy
        </Text>
      </TouchableWithoutFeedback>
    </Text>
  )

  return (
    <View accessible={false} testID="SignupScreen/SignupForm">
      <Controller
        control={control}
        name="firstName"
        rules={{ required: true, validate: Validators.NameValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="words"
            hasError={!!errors.firstName}
            label="First Name"
            onSubmitEditing={() => setFocus('lastName')}
            placeholder="Enter your first name"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/FirstName"
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        rules={{ required: true, validate: Validators.NameValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="words"
            hasError={!!errors.lastName}
            label="Last Name"
            onSubmitEditing={() => setFocus('email')}
            placeholder="Enter your last name"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/LastName"
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        rules={{ required: true, validate: Validators.EmailValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="none"
            autoCorrect={false}
            hasError={!!errors.email}
            keyboardType="email-address"
            label="Email"
            onChangeText={(text) => field.onChange(text.trim())}
            onSubmitEditing={() => setFocus('password')}
            placeholder="Enter your email address"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/Email"
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{ required: true, validate: Validators.PasswordValidator }}
        render={({ field }) => (
          <SecureInput
            {...field}
            hasError={!!errors.password}
            label="Password"
            onSubmitEditing={() => (termsAccepted ? onSignUpPress() : undefined)}
            placeholder="Set your password"
            returnKeyType="done"
            style={styles.textInput}
            testID="SignupScreen/Password"
          />
        )}
      />
      <PasswordValidationInfo password={watch('password')} />
      <Controller
        name="termsAccepted"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <View style={styles.checkBoxContainer}>
            <Checkbox
              {...field}
              checked={field.value}
              hasError={!!errors.termsAccepted}
              text={checkboxContent}
              testID="SignupScreen/CheckBox"
            />
          </View>
        )}
      />
      <Button
        allowPressWhenDisabled
        accessibilityLabel="Create account"
        disabled={!isValid}
        onPress={onSignUpPress}
        size="block"
        testID="SignupScreen/SignupButton"
        type="primary"
      >
        Create Account
      </Button>
    </View>
  )
}

const themedStyle = StyleService.create({
  textInput: {
    marginBottom: 16,
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  },
  checkBoxContent: {
    marginLeft: 8,
  },
  termsText: {
    marginLeft: 8,
  },
})
