import React, { ReactNode } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { UiPillFill, UiPillSize } from '@src/types'
import { ChoiceAccessory } from './Choice.types'

export interface ChoiceProps {
  content?: string | ReactNode
  tag?: string
  accessory: ChoiceAccessory
  onPress?: () => void
}

export const Choice = ({ content, accessory, onPress, tag }: ChoiceProps) => {
  const styles = useStyleSheet(themedStyles)

  const getContainerStyle = () => {
    switch (accessory) {
      case ChoiceAccessory.Default:
        return null
      case ChoiceAccessory.Selected:
        return styles.containerSelected
      case ChoiceAccessory.Correct:
        return styles.containerCorrect
      case ChoiceAccessory.Incorrect:
        return styles.containerIncorrect
    }
  }

  const getAccessoryStyle = () => {
    switch (accessory) {
      case ChoiceAccessory.Default:
        return styles.indicatorDefault
      case ChoiceAccessory.Selected:
        return styles.indicatorSelected
      case ChoiceAccessory.Correct:
        return null
      case ChoiceAccessory.Incorrect:
        return null
    }
  }

  const getAccessoryView = () => {
    switch (accessory) {
      case ChoiceAccessory.Default:
        return null
      case ChoiceAccessory.Selected:
        return <View style={styles.iconSelected} />
      case ChoiceAccessory.Correct:
        return <Icon name="check-circle" style={styles.iconCorrect} weight="fill" />
      case ChoiceAccessory.Incorrect:
        return <Icon name="x-circle" style={styles.iconIncorrect} weight="fill" />
    }
  }

  const hasStringContent = typeof content === 'string'

  return (
    <TouchableOpacity
      style={[styles.container, getContainerStyle()]}
      accessibilityLabel={(hasStringContent && content) || ''}
      disabled={!onPress}
      onPress={onPress}
    >
      {hasStringContent ? (
        <Text type="regular" style={styles.bodyText}>
          {content}
        </Text>
      ) : (
        content
      )}
      <View style={[styles.selectionIndicator, getAccessoryStyle()]}>{getAccessoryView()}</View>
      {tag && (
        <Pill text={tag} fill={UiPillFill.Moonstone} size={UiPillSize.S} style={styles.tag} />
      )}
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  tag: {
    position: 'absolute',
    top: -12,
    right: -4,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    marginBottom: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
  },
  containerSelected: {
    borderColor: 'theme.primary.base',
  },
  containerCorrect: {
    borderColor: 'theme.success.base',
  },
  containerIncorrect: {
    borderColor: 'theme.error.base',
  },
  bodyText: {
    flexShrink: 1,
    marginRight: 16,
  },
  selectionIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  indicatorDefault: {
    borderWidth: 2,
    borderColor: 'theme.surface.base0',
  },
  indicatorSelected: {
    backgroundColor: 'theme.primary.base',
  },
  iconSelected: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'theme.solid.white',
  },
  iconCorrect: {
    color: 'theme.success.base',
  },
  iconIncorrect: {
    color: 'theme.error.base',
  },
})
