import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@src/components/base'
import { hasValue } from '@src/utils'
import { CustomEventTypes } from '@src/config'
import { UiPillSize, UiPillType } from '@src/types'
import { VideoCallDetails } from '../utils'
import {
  DEFAULT_VIDEO_CALL_INFO,
  INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN,
  FULL_DAY_MONTH_AND_DATE_FORMAT,
  VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT,
} from '../constants'
import { useNutritionistHubTracker } from '../hooks'
import { Card } from './Card'

interface VideoCallInfoProps {
  videoCallDetails: VideoCallDetails
}

export const VideoCallScheduledInfoCard = (props: VideoCallInfoProps) => {
  const styles = useStyleSheet(themedStyles)
  const { videoCallDetails } = props
  const navigation = useNavigation()
  const track = useNutritionistHubTracker()

  const primaryDescription = videoCallDetails.date
    ? moment(videoCallDetails.date).format(FULL_DAY_MONTH_AND_DATE_FORMAT)
    : ''
  const secondaryDescription = videoCallDetails.date
    ? moment(videoCallDetails.date).format(VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT)
    : INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN

  const handleButtonPress = () => {
    track(CustomEventTypes.NutritionistHubViewCallDetailsTapped)
    if (!hasValue(videoCallDetails, 'date')) {
      return
    }
    if (videoCallDetails.isCallConfirmed) {
      return navigation.navigate('VideoCallConfirmedModal', videoCallDetails)
    } else if (videoCallDetails.isCallInReview) {
      return navigation.navigate('VideoCallInReviewModal', videoCallDetails)
    }
  }

  return (
    <Card buttonText="View Details" onButtonPress={handleButtonPress}>
      <View style={styles.header}>
        <Icon name="video-camera" size="32" />
        {videoCallDetails.isCallInReview && (
          <Pill status={UiPillType.Warning} text="PENDING" size={UiPillSize.S} />
        )}
      </View>
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {videoCallDetails.title || DEFAULT_VIDEO_CALL_INFO.title}
      </Text>
      {primaryDescription && (
        <Text type="small" bold lineSpacing="none" style={styles.description}>
          {primaryDescription}
        </Text>
      )}
      <Text type="small" lineSpacing="none" style={styles.description}>
        {secondaryDescription}
      </Text>
    </Card>
  )
}

const themedStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    color: 'theme.text.secondary',
  },
})
