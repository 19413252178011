import { gql } from '@apollo/client'

export const UPDATE_GOAL_METRIC = gql`
  mutation updateGoalMetric(
    $kind: UserGoalMetricKind!
    $lowThresholdValue: Float
    $highThresholdValue: Float
  ) {
    updateGoalMetric(
      kind: $kind
      lowThresholdValue: $lowThresholdValue
      highThresholdValue: $highThresholdValue
    ) {
      primary
      kind
      lowThresholdValue
      highThresholdValue
      section
      statKey
    }
  }
`
