import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { useIsDarkMode } from '@src/config/theme'
import { UiPillFill, UiPillType } from '@src/types'

type PillColorProps =
  | {
      status: UiPillType
      fill?: never
    }
  | {
      status?: never
      fill: UiPillFill
    }

export type PillProps = PillColorProps & {
  text: string
  size?: 's' | 'm'
  style?: StyleProp<ViewStyle>
}

export const Pill = ({ text, status, size = 'm', style, fill }: PillProps) => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const sizeStyles = styles[`${size}Size`]
  const fillColor = fill ? theme[`theme.accent.${fill}`] : ''
  const borderColor = fill ? { borderColor: fillColor } : styles[`${status}Border`]
  const backgroundColor = fill ? { backgroundColor: fillColor } : styles[`${status}Background`]
  const backgroundOpacity = status && isDarkMode ? { opacity: 0.1 } : null
  const textColor = status ? styles[`${status}Text${isDarkMode ? 'Dark' : 'Light'}`] : null

  return (
    <View style={[styles.pillContainer, sizeStyles, borderColor, style]}>
      <View style={[styles.background, backgroundColor, backgroundOpacity]} />
      <Text bold type={status ? 'regular' : 'small'} style={[styles.text, textColor]}>
        {text}
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  pillContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    borderWidth: 1,
    overflow: 'hidden',
  },
  sSize: {
    height: 24,
    paddingHorizontal: 8,
  },
  mSize: {
    height: 32,
    paddingHorizontal: 16,
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  text: {
    zIndex: 1,
    color: 'theme.primary.dark',
  },
  // Status background colors
  infoBackground: {
    backgroundColor: 'theme.info.lightest',
  },
  successBackground: {
    backgroundColor: 'theme.success.lightest',
  },
  warningBackground: {
    backgroundColor: 'theme.warning.lightest',
  },
  alertBackground: {
    backgroundColor: 'theme.error.lightest',
  },
  neutralBackground: {
    backgroundColor: 'theme.surface.base2',
  },
  // Status border colors
  infoBorder: {
    borderColor: 'theme.info.lighter',
  },
  successBorder: {
    borderColor: 'theme.success.lighter',
  },
  warningBorder: {
    borderColor: 'theme.warning.lighter',
  },
  alertBorder: {
    borderColor: 'theme.error.lighter',
  },
  neutralBorder: {
    borderColor: 'theme.surface.base0',
  },
  // Status text colors
  infoTextLight: {
    color: 'theme.info.darkest',
  },
  infoTextDark: {
    color: 'theme.info.light_',
  },
  successTextLight: {
    color: 'theme.success.darkest',
  },
  successTextDark: {
    color: 'theme.success.light_',
  },
  warningTextLight: {
    color: 'theme.warning.darkest',
  },
  warningTextDark: {
    color: 'theme.warning.light_',
  },
  alertTextLight: {
    color: 'theme.error.darkest',
  },
  alertTextDark: {
    color: 'theme.error.light_',
  },
  neutralTextLight: {
    color: 'theme.text.secondary',
  },
  neutralTextDark: {
    color: 'theme.text.secondary',
  },
})
