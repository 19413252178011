import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet, OutlinedIcon } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'
import { InsuranceBerryStreetAppointmentType } from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { latestUserAppointmentSelector } from '../models/nutritionistHub.selectors'

export const EarlierSlotAvailableModal = () => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const latestAppointment = useSelector(latestUserAppointmentSelector)

  const goBack = useGoBack()

  const onPress = () => {
    if (!latestAppointment) {
      return
    }

    navigation.replace('RescheduleVideoCallToEarlierSlot', {
      appointmentType: latestAppointment.appointmentType as InsuranceBerryStreetAppointmentType,
      appointmentId: latestAppointment.id,
      nutritionistName: latestAppointment.nutritionistName,
      title: latestAppointment.title,
      date: latestAppointment.date as string,
    })
  }

  const latestAppointmentExists = !!latestAppointment

  useEffect(() => {
    if (!latestAppointmentExists) {
      goBack()
    }
  }, [goBack, latestAppointmentExists])

  return (
    <BottomSheet primaryButton={{ text: 'Update Appointment', onPress }}>
      <OutlinedIcon
        iconName="calendar-blank"
        iconStyle={styles.icon}
        style={styles.iconContainer}
      />
      <Text style={styles.content} type="title-2">
        Get Started Sooner!
      </Text>
      <Text style={styles.content} type="large">
        Good news! Your nutritionist has an earlier availability. Grab the slot and start focusing
        on your health goals sooner!
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  icon: {
    color: 'theme.primary.base',
  },
  iconContainer: {
    alignSelf: 'center',
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
})
