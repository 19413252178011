import React, { useRef } from 'react'

import { useTheme } from '@ui-kitten/components'
import Chart from 'react-native-f2chart'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CommonChart } from '@src/components'
import {
  expectedWeightLossChart,
  ExpectedWeightLossChartData,
} from '@src/components/charts/expectedWeightLossChart'

export const ExpectedWeightLossChart = (props: ExpectedWeightLossChartData) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyles)
  const chartRef = useRef<Chart | null>(null)
  const { initialWeight, expectedWeightLoss, units } = props

  const chartScript = expectedWeightLossChart({
    data: {
      initialWeight,
      expectedWeightLoss,
      units,
    },
    lineColors: [theme['theme.primary.base']],
    style: {
      axisLabel: theme['theme.text.secondary'],
      axisLine: theme['theme.surface.base'],
      weightIconBackground: theme['theme.primary.base'],
      initialWeightBackground: theme['theme.text.inColor'],
      lossRateMainColor: theme['theme.text.primary'],
      lossRateSubColor: theme['theme.text.tertiary'],
      initialWeightColor: theme['theme.text.primary'],
      dashColor: theme['theme.surface.base3'],
    },
  })

  return (
    <CommonChart chartRef={chartRef} chartScript={chartScript} height={300} style={styles.chart} />
  )
}

const themedStyles = StyleService.create({
  chart: {
    backgroundColor: 'theme.surface.base3',
    borderRadius: 24,
    marginBottom: 24,
  },
})
