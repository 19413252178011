import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { View, TouchableWithoutFeedback } from 'react-native'
import Config from 'react-native-config'
import { LongPressGestureHandler } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text, Button } from '@components/base'
import { NutrisenseLogo, ScrollableAvoidKeyboard } from '@components'
import { useSignIn } from '@src/hooks/useSignIn'
import { Debug } from '@config'
import { SignInButton, SignInButtonVariant } from '@screens/SignIn'
import {
  consumerHealthDataPrivacyPolicySelector,
  privacyPolicySelector,
  telehealthSelector,
  termsOfUseSelector,
} from '@selectors/app'
import { openUrl } from '@utils'
import { useSnack } from '@utils/navigatorContext'
import { useAllowSignInWithApple, useAllowSignInWithGoogle } from '@utils/hooks'

export const SignInOptions = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const showSnack = useSnack()
  const termsOfUseUrl = useSelector(termsOfUseSelector)
  const privacyPolicyUrl = useSelector(privacyPolicySelector)
  const telehealthPolicyUrl = useSelector(telehealthSelector)
  const consumerHealthDataPrivacyPolicyUrl = useSelector(consumerHealthDataPrivacyPolicySelector)
  const allowSignInWithGoogle = useAllowSignInWithGoogle()
  const allowSignInWithApple = useAllowSignInWithApple()
  const { signUpWithApple, signUpWithGoogle } = useSignIn()

  const onSignInPress = () => {
    navigation.navigate('SignIn')
  }

  const onSignUpWithEmailPress = () => {
    navigation.navigate('SignUp')
  }

  const onSignUpWithGooglePress = async () => {
    try {
      await signUpWithGoogle()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onSignUpWithApplePress = async () => {
    try {
      await signUpWithApple()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onAdminPress = () => {
    if (Debug.useAdminMenu) {
      navigation.navigate('ShowAdmin')
    }
  }

  return (
    <ScrollableAvoidKeyboard
      style={styles.container}
      contentContainerStyle={styles.content}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.headerContainer} testID="SigninScreen/Logo">
        <LongPressGestureHandler
          enabled={Config.ENV !== 'production'}
          minDurationMs={50}
          onHandlerStateChange={onAdminPress}
        >
          <View>
            <NutrisenseLogo />
          </View>
        </LongPressGestureHandler>
      </View>
      <SafeAreaView edges={['bottom']} style={styles.signInButtonContainer}>
        <Text type="large" lineSpacing="none" style={styles.signInPrompt}>
          Already have an account?
        </Text>
        <Button
          type="primary"
          size="block"
          style={[styles.signInButton, styles.ctaButton]}
          accessibilityLabel="Log In"
          onPress={onSignInPress}
        >
          Log In
        </Button>
        <Text type="large" lineSpacing="none" style={styles.signInPrompt}>
          New to Nutrisense? Sign Up
        </Text>
        <SignInButton
          variant={SignInButtonVariant.Email}
          style={styles.signInButton}
          onPress={onSignUpWithEmailPress}
        />
        {allowSignInWithGoogle && (
          <SignInButton
            variant={SignInButtonVariant.Google}
            style={styles.signInButton}
            onPress={onSignUpWithGooglePress}
          />
        )}
        {allowSignInWithApple && (
          <SignInButton
            variant={SignInButtonVariant.Apple}
            style={styles.signInButton}
            onPress={onSignUpWithApplePress}
          />
        )}
        <View style={styles.bottom}>
          <Text type="regular" style={styles.termsOfService}>
            By creating an account you agree to our{' '}
            <TouchableWithoutFeedback
              accessibilityLabel="Terms of Use"
              onPress={() => openUrl(termsOfUseUrl)}
              testID="SignInOptionsScreen/TermsOfServiceButton"
            >
              <Text type="regular" bold>
                Terms & Conditions
              </Text>
            </TouchableWithoutFeedback>
            ,{' '}
            <TouchableWithoutFeedback
              accessibilityLabel="Telehealth Consent"
              onPress={() => openUrl(telehealthPolicyUrl)}
            >
              <Text type="regular" bold>
                Telehealth Consent
              </Text>
            </TouchableWithoutFeedback>
            ,{' '}
            <TouchableWithoutFeedback
              accessibilityLabel="Privacy Policy"
              onPress={() => openUrl(privacyPolicyUrl)}
            >
              <Text type="regular" bold>
                Privacy Policy
              </Text>
            </TouchableWithoutFeedback>
            ,{' '}
            <TouchableWithoutFeedback
              accessibilityLabel="Consumer Health Data Privacy Policy"
              onPress={() => openUrl(consumerHealthDataPrivacyPolicyUrl)}
            >
              <Text type="regular" bold>
                Consumer Health Data Privacy Policy
              </Text>
            </TouchableWithoutFeedback>
          </Text>
        </View>
      </SafeAreaView>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
  },
  headerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInButtonContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  signInPrompt: {
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
  },
  signInButton: {
    marginBottom: 16,
  },
  ctaButton: {
    marginBottom: 48,
  },
  bottom: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  termsOfService: {
    marginTop: 16,
    textAlign: 'center',
  },
})
