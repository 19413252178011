import React, { useMemo, useRef } from 'react'
import { ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@screens/Common/containers'
import { Button, Input, Text } from '@src/components/base'
import { useUnitSystemMeasurements } from '@src/screens/Measurements/hooks'
import {
  HealthDataMeasurementSource,
  HealthDataMeasurementType,
  UserGoalKind,
  UserGoalMetricKind,
} from '@src/types'
import { SliderInputBlock } from '@components/modalBlocks/SliderInputBlock'
import { useDispatchAsync } from '@src/utils'
import { userSelector } from '@src/selectors/app'
import { AppStackParamList } from '@src/navigation/types'

interface FormData {
  currentWeight: string
  weightGoal: string
  weightLossRate: number
}

const STEP_LABELS = {
  pounds: ['0.5', '1', '1.5', '2'],
  kilos: ['0.25', '0.5', '0.7', '1'],
}

export const RECOMMENDED_WEIGHT_LOSS_RATE = {
  pounds: 1,
  kilos: 0.5,
}

const fetchStepLabels = (units: 'pounds' | 'kilos') => STEP_LABELS[units] as string[]

export const SetWeightLossGoalScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const measurementUnitConfig = useUnitSystemMeasurements()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { units, minThreshold: min, maxThreshold: max } = measurementUnitConfig[
    HealthDataMeasurementType.Weight
  ]
  const hasWeightThreshold = typeof min === 'number' && typeof max === 'number'

  const user = useSelector(userSelector)
  const goalMetrics = user?.goalMetrics || []
  const metricKinds = goalMetrics?.map((metric) => metric.kind) || []

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues: { currentWeight: '', weightGoal: '', weightLossRate: 1 },
  })

  const validationRules = {
    required: 'This field is required',
    validate: (value = '') => {
      if (!hasWeightThreshold || !value) {
        return true
      }
      return (
        (Number(value) >= min && Number(value) <= max) ||
        `Please enter a value from ${min} to ${max}`
      )
    },
  }

  const sliderValue = watch('weightLossRate')
  const isSubmitting = useRef(false)

  const sliderSteps = useMemo(() => {
    const stepLabels = fetchStepLabels(units as 'pounds' | 'kilos')
    return stepLabels.map((stepLabel, index) => ({ index, stepLabel, prefix: '', suffix: '' }))
  }, [units])

  const saveCurrentWeight = async (value: string) => {
    const parsedValue = Number.parseFloat(value)
    const payload = {
      type: HealthDataMeasurementType.Weight,
      externalSource: HealthDataMeasurementSource.Nutrisense,
      values: { value: parsedValue },
      createdAt: moment().format(),
      occurredAt: moment().format(),

      // fake data for optimistic reducer
      __typename: 'Measurement',
      fake: true,
      isDailyMeasurement: false,
      title: 'Weight',
      units,
      value: parsedValue,
    }

    await dispatchAsync({
      payload,
      type: 'measurements/submit',
    })
  }

  const updateGoalWithMetric = async (
    currentWeight: string,
    weightGoal: string,
    weightLossRate: number,
  ) => {
    const parsedWeightGoal = Number.parseFloat(weightGoal)
    const parsedCurrentWeight = Number.parseFloat(currentWeight)

    const weightMetricsExists =
      metricKinds?.findIndex(
        (metricKind) => metricKind === UserGoalMetricKind.MeasurementsWeight,
      ) >= 0

    if (!weightMetricsExists) {
      await dispatchAsync({
        type: 'goals/setPrimaryGoalMetrics',
        payload: { kinds: [...metricKinds, UserGoalMetricKind.MeasurementsWeight] },
      })
    }

    await dispatchAsync({
      type: 'goals/updateGoalMetric',
      payload: {
        kind: UserGoalMetricKind.MeasurementsWeight,
        lowThresholdValue: parsedWeightGoal,
        highThresholdValue: parsedCurrentWeight,
      },
    })

    await dispatchAsync({
      type: 'goals/upsertPrimaryGoal',
      payload: {
        kind: UserGoalKind.LoseWeight,
        description: `Desired weight loss rate ${sliderSteps[weightLossRate].stepLabel} ${units}/week`,
      },
    })

    return
  }

  const submitForm = async (data: FormData) => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    await saveCurrentWeight(data.currentWeight)
    await updateGoalWithMetric(data.currentWeight, data.weightGoal, data.weightLossRate)
    isSubmitting.current = false

    return navigation.replace('ConfirmWeightLossRecurrence', {
      currentWeight: parseFloat(data.currentWeight),
      weightLossRate: parseFloat(sliderSteps[data.weightLossRate].stepLabel),
    })
  }

  return (
    <NavigationContainer title="Hit you weight loss goal" style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[styles.container, { paddingBottom: 16 + insets?.bottom ?? 0 }]}
      >
        <Text type="regular" bold style={styles.text}>
          Congratulations!
        </Text>
        <Text type="regular" style={styles.text}>
          You are eligible for unlimited video calls at $0 out-of-pocket. Reach your weight loss
          goal with expert guidance.
        </Text>

        <>
          <Controller
            control={control}
            name="currentWeight"
            rules={validationRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                accessoryRight={<Text type="regular">{units}</Text>}
                errorText={fieldState.error?.message}
                keyboardType="decimal-pad"
                hasError={!!fieldState.error?.message}
                label="Current weight"
                placeholder="Enter your current weight"
                style={styles.input}
                onChangeText={(value) => {
                  if (value !== null) {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="weightGoal"
            rules={validationRules}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                accessoryRight={<Text type="regular">{units}</Text>}
                errorText={fieldState.error?.message}
                keyboardType="decimal-pad"
                hasError={!!fieldState.error?.message}
                label="Weight Goal"
                placeholder="Enter your goal weight"
                style={styles.input}
                onChangeText={(value) => {
                  if (value !== null) {
                    field.onChange(value)
                  }
                }}
              />
            )}
          />

          <SliderInputBlock
            label="Rate of weight loss"
            value={sliderValue}
            showValue={false}
            rightAccessoryText={`${units}/by week`}
            min={0}
            max={sliderSteps.length - 1}
            step={1}
            stepsAs={sliderSteps}
            style={styles.sliderInput}
            showStepLabels
            showStepMarkers
            stepLabelStyle={styles.stepLabelStyle}
            smoothSnapped
            showSteps
            onValueChanged={(value) => {
              setValue('weightLossRate', value)
            }}
          />
          <Button
            type="primary"
            size="large"
            style={styles.button}
            accessibilityLabel="Set Goal"
            onPress={handleSubmit(submitForm)}
          >
            Set Goal
          </Button>
        </>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  button: {
    marginTop: 'auto',
    width: '100%',
  },
  input: {
    marginBottom: 24,
  },
  sliderInput: {
    marginTop: 16,
    marginHorizontal: 8,
  },
  text: {
    textAlign: 'center',
    marginBottom: 32,
    marginHorizontal: 8,
  },
  stepLabelStyle: {
    marginLeft: -2,
  },
})
