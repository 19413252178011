import React, { useCallback, useEffect } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { authenticatedUserSelector } from '@src/selectors/app'
import { CheckoutContainer } from '@src/screens/Marketplace/components/checkout/CheckoutContainer'
import { ProductDescription } from '@src/screens/Marketplace/components/checkout/ProductDescription'
import { ProductPayment } from '@src/screens/Marketplace/components/checkout/ProductPayment'
import { AddressForm, AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import { OnboardingStackParamsList } from '@src/navigation/types'
import { MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@config/momentFormat'
import { InlineAlert } from '@components'
import {
  usePromotionCode,
  usePurchaseSubscription,
  useTaxEstimation,
  useUpgradeSubscriptionTrial,
  useUpgradeSubscriptionProduct,
} from '@screens/Marketplace/utils/hooks'
import { BillingProduct, UiInlineAlert } from '@src/types'
import { CheckoutKind } from '../../types/types'
import { getPriceDescription } from '../../utils/utils'

export const SubscriptionCheckout = () => {
  const styles = useStyleSheet(themedStyle)

  const navigation = useNavigation()
  const route = useRoute<RouteProp<OnboardingStackParamsList, 'SubscriptionCheckout'>>()
  const {
    product,
    subscriptionId,
    checkoutKind,
    checkoutOptionKinds,
    savings,
    trialEndsAt,
  } = route.params

  const purchaseSubscription = usePurchaseSubscription()
  const upgradeSubscriptionTrial = useUpgradeSubscriptionTrial()
  const upgradeSubscriptionProduct = useUpgradeSubscriptionProduct()

  const productKey = product.key as BillingProduct

  const promotionCode = usePromotionCode(productKey)

  const estimatedTax = useTaxEstimation(productKey, promotionCode)
  const user = useSelector(authenticatedUserSelector)
  const { address, paymentMethod } = user

  const { requiresShipping, trialDays } = product

  const addressType = requiresShipping ? AddressType.Shipping : AddressType.Billing

  const navigateToAddressScreen = useCallback(() => {
    navigation.navigate('ChangeAddress', { addressType })
  }, [addressType, navigation])

  const shouldNavigateToAddressScreen = !!address && !user.phoneNumber
  useEffect(() => {
    if (shouldNavigateToAddressScreen) {
      navigateToAddressScreen()
    }
  }, [navigateToAddressScreen, shouldNavigateToAddressScreen])

  const onSignUpPress = async () => {
    if (!subscriptionId) {
      await purchaseSubscription({ productKey: product.key, checkoutOptionKinds, promotionCode })
      return
    }

    if (checkoutKind === CheckoutKind.UpgradeTrial) {
      await upgradeSubscriptionTrial(subscriptionId, promotionCode)
    } else if (checkoutKind === CheckoutKind.UpgradeProduct) {
      await upgradeSubscriptionProduct(subscriptionId, product, promotionCode)
    }
  }

  return (
    <CheckoutContainer
      checkoutButtonTitle="Sign Up"
      showDiscountDisclosure={!!savings && savings > 0}
      onCheckout={onSignUpPress}
    >
      <ProductDescription
        title={product.title}
        description={product.interval.description}
        price={getPriceDescription(product.price, product.interval)}
        showDivider={false}
      />
      <Divider appearance="large" />
      <ProductPayment
        product={productKey}
        trialDays={trialDays ?? undefined}
        estimatedTax={estimatedTax}
        fullPrice={product.price}
        promotionCode={promotionCode}
      />
      <Divider appearance="large" />
      <AddressForm
        addressType={addressType}
        address={address}
        onEditAddressPress={navigateToAddressScreen}
      />
      {trialDays && paymentMethod && (
        <InlineAlert
          style={styles.info}
          category={UiInlineAlert.Info}
          message={`Enjoy ${trialDays}-day free trial with no charges`}
          // eslint-disable-next-line max-len
          secondaryMessage="We've got your credit card information, but don't worry, we won't charge you until the trial ends."
        />
      )}
      {trialEndsAt && checkoutKind === CheckoutKind.UpgradeTrial && (
        <InlineAlert
          style={styles.info}
          category={UiInlineAlert.Danger}
          message={`You’ll be billed on ${moment(trialEndsAt).format(
            MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT,
          )}, when your complimentary period ends.`}
        />
      )}
    </CheckoutContainer>
  )
}

const themedStyle = StyleService.create({
  info: {
    margin: 16,
  },
})
