import React, { useCallback } from 'react'
import { Keyboard, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Controller, useForm } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { NavigationContainer } from '@screens/Common/containers'
import { Button, Input, Text } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { EhrChartNoteKind, EhrGetHealthieInstanceKind } from '@src/types.ts'
import { useSnack } from '@utils'
import { CustomEventTypes, ErrorMessages } from '@config'
import Storage from '@utils/storage.ts'
import {
  appetiteDescriptionsLookup,
  energyDescriptionsLookup,
  sleepDescriptionsLookup,
  stressDescriptionsLookup,
} from '@screens/NutritionistHub/components/VideoCallCheckIn/constants.ts'
import { navigateToCheckInCompletedModal } from '@screens/NutritionistHub/components/VideoCallCheckIn/utils.ts'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'
import { ScrollableAvoidKeyboard } from '@components'
import { DescriptiveSlider } from './DescriptiveSlider.tsx'

export const VideoCallCheckIn = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()
  const route = useRoute<RouteProp<AppStackParamList, 'VideoCallCheckIn'>>()
  const { appointmentId } = route.params

  useFocusEffect(
    useCallback(() => {
      if (track) {
        track(CustomEventTypes.NutritionistHubCheckInStarted)
      }
    }, [track]),
  )

  useFocusEffect(
    useCallback(() => {
      const checkInCompleted = Storage.get<boolean>(
        `${Storage.VIDEO_CALL_CHECK_IN_COMPLETED_KEY}_${appointmentId}`,
        false,
      )
      if (checkInCompleted && navigation) {
        navigation.replace('VideoCallCheckInAddendum', { appointmentId })
      }
    }, [appointmentId, navigation]),
  )

  const defaultValues = {
    energy: 1,
    sleep: 1,
    stress: 1,
    appetite: 1,
    progress: '',
    updates: '',
  }

  const {
    control,
    formState: { isValid, errors },
    setFocus,
    handleSubmit,
  } = useForm({
    defaultValues,
  })

  const onSubmit = handleSubmit(({ energy, sleep, stress, appetite, progress, updates }) => {
    const data = {
      energy: energyDescriptionsLookup[energy as keyof typeof energyDescriptionsLookup],
      sleep: sleepDescriptionsLookup[sleep as keyof typeof sleepDescriptionsLookup],
      stress: stressDescriptionsLookup[stress as keyof typeof stressDescriptionsLookup],
      appetite: appetiteDescriptionsLookup[appetite as keyof typeof appetiteDescriptionsLookup],
      progress,
      updates,
    }

    track(CustomEventTypes.NutritionistHubCheckInSubmitted)
    dispatch({
      type: 'nutritionistHub/createChartNote',
      payload: {
        data,
        kind: EhrChartNoteKind.CheckIn,
        getHealthieInstanceKind: EhrGetHealthieInstanceKind.BerryStreet,
        legacyAppointmentId: appointmentId,
      },
      success: () => {
        Storage.set(`${Storage.VIDEO_CALL_CHECK_IN_COMPLETED_KEY}_${appointmentId}`, true)
        navigateToCheckInCompletedModal(navigation)
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  })

  return (
    <NavigationContainer title="Call Check In" style={{ flexGrow: 1 }}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={{ paddingBottom: 16 + insets?.bottom ?? 0 }}
      >
        <Text type="title-3">
          Drag the sliders to reflect your experience since your last appointment:
        </Text>
        <View style={styles.form}>
          <Controller
            control={control}
            name="energy"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Energy
                </Text>
                <DescriptiveSlider
                  descriptionsLookup={energyDescriptionsLookup}
                  min={1}
                  max={5}
                  showStepLabels={false}
                  style={styles.slider}
                  value={value}
                  customMarker={() => <View style={styles.marker} />}
                  onValueChanged={onChange}
                />
              </View>
            )}
          />
          <Controller
            control={control}
            name="sleep"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Sleep quality
                </Text>
                <DescriptiveSlider
                  descriptionsLookup={sleepDescriptionsLookup}
                  min={1}
                  max={5}
                  showStepLabels={false}
                  style={styles.slider}
                  value={value}
                  customMarker={() => <View style={styles.marker} />}
                  onValueChanged={onChange}
                />
              </View>
            )}
          />
          <Controller
            control={control}
            name="stress"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Stress
                </Text>
                <DescriptiveSlider
                  descriptionsLookup={stressDescriptionsLookup}
                  min={1}
                  max={5}
                  showStepLabels={false}
                  style={styles.slider}
                  value={value}
                  customMarker={() => <View style={styles.marker} />}
                  onValueChanged={onChange}
                />
              </View>
            )}
          />
          <Controller
            control={control}
            name="appetite"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Appetite
                </Text>
                <DescriptiveSlider
                  descriptionsLookup={appetiteDescriptionsLookup}
                  min={1}
                  max={5}
                  showStepLabels={false}
                  style={styles.slider}
                  value={value}
                  customMarker={() => <View style={styles.marker} />}
                  onValueChanged={onChange}
                />
              </View>
            )}
          />
          <Controller
            control={control}
            name="progress"
            rules={{ required: true }}
            render={({ field }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Do you feel you are progressing?
                </Text>
                <Input
                  {...field}
                  hasError={!!errors.progress}
                  onSubmitEditing={() => setFocus('updates')}
                  placeholder="Type here..."
                  returnKeyType="next"
                />
              </View>
            )}
          />
          <Controller
            control={control}
            name="updates"
            render={({ field }) => (
              <View>
                <Text type="title-3" style={styles.label}>
                  Do you have any updates?
                </Text>
                <Input
                  {...field}
                  hasError={!!errors.progress}
                  onSubmitEditing={() => Keyboard.dismiss()}
                  placeholder="Type here..."
                  returnKeyType="next"
                />
              </View>
            )}
          />
        </View>

        <Button
          type="primary"
          size="block"
          disabled={!isValid}
          onPress={onSubmit}
          accessibilityLabel="Send to Nutritionist"
        >
          Send to Nutritionist
        </Button>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  form: {
    gap: 16,
    marginVertical: 24,
  },
  slider: {
    padding: 16,
    marginBottom: 16,
    backgroundColor: 'theme.surface.base3',
    borderRadius: 8,
  },
  marker: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'theme.primary.base',
  },
  label: {
    marginBottom: 8,
  },
})
