import { useSelector } from 'react-redux'
import { InsuranceBerryStreetAppointmentType } from '@src/types.ts'
import { clientConfigStoreStateSelector } from '@selectors/app.ts'

export const useVideoCallDurations = () => {
  const clientConfig = useSelector(clientConfigStoreStateSelector)

  return {
    getVideoCallDuration: (type: InsuranceBerryStreetAppointmentType | undefined | null) => {
      if (!type) {
        return 0
      }

      return (
        clientConfig.appointmentTypes.find((appointmentType) => appointmentType.key === type)
          ?.duration || 0
      )
    },
  }
}
