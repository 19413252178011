import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Product } from '@src/types'
import { Button, Icon, Text } from '@src/components/base'
import { useVariantName, useVariantPayload } from '@src/components/FeatureFlag/FeatureFlagUtils'
import { Feature } from '@src/components'
import { Analytics, CustomEventTypes } from '@src/config'
import { userSelector } from '@src/selectors/app'
import { ProductsGrid } from './ProductsGrid'

interface NutritionistMenuSectionProps {
  products: Product[]
  style?: ViewStyle
}

const MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY = 4

export const NutritionistMenuSection = (props: NutritionistMenuSectionProps) => {
  const { style, products } = props
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const user = useSelector(userSelector)

  const productsSequenceVariantName = useVariantName(Feature.NutritionistMenuProductsSequence)
  const productsSequenceVariantPayload = useVariantPayload(Feature.NutritionistMenuProductsSequence)

  if (productsSequenceVariantName && productsSequenceVariantPayload) {
    Analytics.track(
      CustomEventTypes.NutritionistMenuVisited,
      {
        productsSequenceVariantName,
        productsSequenceVariantPayload,
        userId: user?.id,
      },
      user?.id,
    )
  }

  const showSeeAllOption = products.length > MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY

  return (
    <View style={[style]}>
      <View style={styles.header}>
        <View style={styles.iconAndTextContainer}>
          <Icon name="shopping-cart" />
          <View style={styles.textContainer}>
            <Text type="large" bold>
              Upgrades and Add-ons
            </Text>
            <Text type="small" style={styles.titleDescription}>
              Premium optimal wellness support.
            </Text>
          </View>
        </View>
        {showSeeAllOption && (
          <Button
            type="outline"
            size="small"
            accessibilityLabel="See All"
            onPress={() => {
              navigation.navigate('AllProducts')
            }}
          >
            See All
          </Button>
        )}
      </View>
      <ProductsGrid
        products={products.slice(0, MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY)}
        productsPerRow={2}
      />
    </View>
  )
}

const themedStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  iconAndTextContainer: {
    flexDirection: 'row',
    marginBottom: 8,
    alignItems: 'center',
    gap: 20,
  },
  textContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  titleDescription: {
    color: 'theme.text.secondary',
  },
})
