/* eslint-disable max-len, @cspell/spellchecker */
export const DEFAULT_CLIENT_CONFIG = {
  clientConfig: {
    measurements: [
      {
        name: 'glucose',
        metric: {
          units: 'mmol/L',
          precision: 1,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'mg/dL',
          precision: 0,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'blood_glucose',
        metric: {
          units: 'mmol/L',
          precision: 1,
          minThreshold: 1.0,
          maxThreshold: 50.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'mg/dL',
          precision: 0,
          minThreshold: 18.0,
          maxThreshold: 900.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'blood_ketones',
        metric: {
          units: 'mmol/L',
          precision: 1,
          minThreshold: 0.0,
          maxThreshold: 30.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'mmol/L',
          precision: 1,
          minThreshold: 0.0,
          maxThreshold: 30.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'breath_ketones',
        metric: {
          units: 'aces',
          precision: 0,
          minThreshold: 0.0,
          maxThreshold: 50.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'aces',
          precision: 0,
          minThreshold: 0.0,
          maxThreshold: 50.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'blood_pressure',
        metric: {
          units: 'mmHg',
          precision: 0,
          minThreshold: 20.0,
          maxThreshold: 300.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'mmHg',
          precision: 0,
          minThreshold: 20.0,
          maxThreshold: 300.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'body_fat_percentage',
        metric: {
          units: 'percent',
          precision: 1,
          minThreshold: 1.0,
          maxThreshold: 70.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'percent',
          precision: 1,
          minThreshold: 1.0,
          maxThreshold: 70.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'body_temperature',
        metric: {
          units: '°C',
          precision: 1,
          minThreshold: 30.0,
          maxThreshold: 50.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: '°F',
          precision: 1,
          minThreshold: 86.0,
          maxThreshold: 122.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'step_count',
        metric: {
          units: 'steps',
          precision: 0,
          minThreshold: 1.0,
          maxThreshold: 200000.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'steps',
          precision: 0,
          minThreshold: 1.0,
          maxThreshold: 200000.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'flights_climbed',
        metric: {
          units: 'flights',
          precision: 0,
          minThreshold: 1.0,
          maxThreshold: 200000.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'flights',
          precision: 0,
          minThreshold: 1.0,
          maxThreshold: 200000.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'exercise_time',
        metric: {
          units: 'minutes',
          precision: 0,
          minThreshold: 0.1,
          maxThreshold: 1400.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'minutes',
          precision: 0,
          minThreshold: 0.1,
          maxThreshold: 1400.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'cycling_distance',
        metric: {
          units: 'kilometers',
          precision: 1,
          minThreshold: 0.01,
          maxThreshold: 1000.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'miles',
          precision: 1,
          minThreshold: 0.006,
          maxThreshold: 621.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'running_distance',
        metric: {
          units: 'kilometers',
          precision: 1,
          minThreshold: 0.01,
          maxThreshold: 1000.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'miles',
          precision: 1,
          minThreshold: 0.006,
          maxThreshold: 621.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'swimming_distance',
        metric: {
          units: 'kilometers',
          precision: 1,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'miles',
          precision: 1,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'weight',
        metric: {
          units: 'kilos',
          precision: 1,
          minThreshold: 10.0,
          maxThreshold: 370.0,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'pounds',
          precision: 1,
          minThreshold: 22.0,
          maxThreshold: 800.0,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
      {
        name: 'stress',
        metric: {
          units: 'stress',
          precision: 1,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        imperial: {
          units: 'stress',
          precision: 1,
          minThreshold: null,
          maxThreshold: null,
          __typename: 'MeasurementConfigUnit',
        },
        __typename: 'MeasurementConfig',
      },
    ],
    activityTypes: ['Exercise', 'Fasting', 'Meditation', 'Sleep'],
    mealTypes: ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Dessert', 'Drink', 'Supplement'],
    dayTabs: [
      { key: '0', value: 'Cal', __typename: 'KeyValuePairString' },
      { key: '7', value: '7 days', __typename: 'KeyValuePairString' },
      { key: '14', value: '14 days', __typename: 'KeyValuePairString' },
      { key: '30', value: '30 days', __typename: 'KeyValuePairString' },
      { key: '60', value: '60 days', __typename: 'KeyValuePairString' },
      { key: '120', value: '120 days', __typename: 'KeyValuePairString' },
    ],
    timeZones: [
      'Pacific/Honolulu',
      'America/Juneau',
      'America/Los_Angeles',
      'America/Denver',
      'America/Chicago',
      'America/New_York',
      'America/Halifax',
      'America/Argentina/Buenos_Aires',
      'Europe/London',
      'Europe/Paris',
      'Africa/Cairo',
      'Europe/Moscow',
      'Asia/Karachi',
      'Asia/Kolkata',
      'Asia/Bangkok',
      'Asia/Hong_Kong',
      'Asia/Tokyo',
      'Australia/Sydney',
      'Pacific/Auckland',
      'Pacific/Apia',
    ],
    history: {
      filters: {
        types: [
          {
            key: 'meals',
            value: [
              'favorite',
              'breakfast',
              'lunch',
              'dinner',
              'dessert',
              'drink',
              'snack',
              'supplement',
            ],
            __typename: 'KeyValuePairStringArray',
          },
          {
            key: 'activities',
            value: ['favorite', 'sleep', 'fasting', 'exercise', 'meditation'],
            __typename: 'KeyValuePairStringArray',
          },
          {
            key: 'measurements',
            value: [
              'weight',
              'step_count',
              'blood_glucose',
              'blood_ketones',
              'breath_ketones',
              'blood_pressure',
              'exercise_time',
              'flights_climbed',
              'body_temperature',
              'cycling_distance',
              'running_distance',
              'swimming_distance',
            ],
            __typename: 'KeyValuePairStringArray',
          },
          {
            key: 'journal',
            value: ['my_entries', 'coach_entries'],
            __typename: 'KeyValuePairStringArray',
          },
        ],
        orderBy: ['occurred_at', 'score', 'peak', 'exposure', 'stability', 'recovery'],
        order: ['descending', 'ascending'],
        __typename: 'HistoryFilters',
      },
      __typename: 'History',
    },
    meals: {
      filters: {
        types: [
          'favorite',
          'breakfast',
          'lunch',
          'dinner',
          'dessert',
          'drink',
          'snack',
          'supplement',
        ],
        orderBy: ['occurred_at', 'score', 'peak', 'exposure', 'stability', 'recovery'],
        order: ['descending', 'ascending'],
        __typename: 'ListFilters',
      },
      __typename: 'Meals',
    },
    insights: {
      statistics: [
        {
          name: 'daily_average',
          metric: {
            ranges: [
              { from: 80.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 50.0, to: 79.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 49.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 80.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 50.0, to: 79.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 49.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'meals_average',
          metric: {
            ranges: [
              { from: 80.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 50.0, to: 79.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 49.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 80.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 50.0, to: 79.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 49.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'time_within_range',
          metric: {
            ranges: [
              { from: 95.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 85.0, to: 94.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 84.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'percentage',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 95.0, to: 100.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 85.0, to: 94.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.0, to: 84.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'percentage',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'standard_deviation',
          metric: {
            ranges: [
              { from: null, to: 0.8, type: 'unbounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 0.9, to: 1.1, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 1.2, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: null, to: 14.0, type: 'unbounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 15.0, to: 20.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 21.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'decimal',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'mean',
          metric: {
            ranges: [
              { from: 3.9, to: 5.8, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 5.9, to: 6.4, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 3.1, to: 3.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 6.5, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 3.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 70.0, to: 105.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 106.0, to: 115.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 55.0, to: 69.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 116.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 54.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'mg/dL',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'min',
          metric: {
            ranges: [
              { from: 3.9, to: 7.8, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 3.1, to: 3.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 7.8, to: 8.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: null, to: 3.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: 8.9, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 70.0, to: 140.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 55.0, to: 69.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 141.0, to: 159.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: null, to: 54.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: 160.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'mg/dL',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'max',
          metric: {
            ranges: [
              { from: 3.9, to: 7.8, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 3.1, to: 3.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 7.8, to: 8.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: null, to: 3.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: 8.9, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 70.0, to: 140.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 55.0, to: 69.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 141.0, to: 159.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: null, to: 54.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: 160.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'mg/dL',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'sleep_average',
          metric: {
            ranges: [
              { from: 3.9, to: 5.8, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 5.9, to: 6.4, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 3.1, to: 3.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 6.5, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 3.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 70.0, to: 105.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 106.0, to: 115.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 55.0, to: 69.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 116.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 54.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'mg/dL',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'morning_average',
          metric: {
            ranges: [
              { from: 3.9, to: 5.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 5.1, to: 5.6, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 3.1, to: 3.8, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 5.7, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 3.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 70.0, to: 90.0, type: 'unbounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 91.0, to: 100.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 55.0, to: 69.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 101.0, to: null, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
              { from: null, to: 54.0, type: 'unbounded', rating: 'bad', __typename: 'RangeUnit' },
            ],
            precision: 0,
            units: 'mg/dL',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
        {
          name: 'ketones_max',
          metric: {
            ranges: [
              { from: 0.5, to: 3.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 3.1, to: 8.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 8.1, to: 10.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
              {
                from: 10.1,
                to: null,
                type: 'unbounded',
                rating: 'critical',
                __typename: 'RangeUnit',
              },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          imperial: {
            ranges: [
              { from: 0.5, to: 3.0, type: 'bounded', rating: 'good', __typename: 'RangeUnit' },
              { from: 3.1, to: 8.0, type: 'bounded', rating: 'ok', __typename: 'RangeUnit' },
              { from: 8.1, to: 10.0, type: 'bounded', rating: 'bad', __typename: 'RangeUnit' },
              {
                from: 10.1,
                to: null,
                type: 'unbounded',
                rating: 'critical',
                __typename: 'RangeUnit',
              },
            ],
            precision: 1,
            units: 'mmol/L',
            __typename: 'ConfigUnit',
          },
          __typename: 'Statistics',
        },
      ],
      __typename: 'Config',
    },
    biteAi: {
      units: [
        { key: 'calories', value: 'kcal', __typename: 'BiteAiKeyValuePairString' },
        { key: 'totalFat', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'saturatedFat', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'monounsaturatedFat', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'polyunsaturatedFat', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'transFat', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'totalCarb', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'sugars', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fiber', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'protein', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'cholesterol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'sodium', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'potassium', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'calcium', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'iron', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'magnesium', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'phosphorus', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'zinc', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'copper', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'manganese', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'selenium', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminARae', value: 'mcg rae', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminAIu', value: 'IU', __typename: 'BiteAiKeyValuePairString' },
        { key: 'retinol', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'alphaCarotene', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaCarotene', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaCryptoxanthin', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'lycopene', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'thiamin', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'riboflavin', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'niacin', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'pantothenicAcid', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminB6', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'folate', value: 'mcg dfe', __typename: 'BiteAiKeyValuePairString' },
        { key: 'folicAcid', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminB12', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'choline', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminC', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminD', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminE', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminK', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'water', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'ash', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'caffeine', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'alcohol', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'addedSugars', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'omega3', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'omega6', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'theobromine', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'luteinZeaxanthin', value: 'mcg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fruit', value: 'servings', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vegetable', value: 'servings', __typename: 'BiteAiKeyValuePairString' },
        { key: 'wholeGrain', value: 'servings', __typename: 'BiteAiKeyValuePairString' },
        { key: 'starch', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'sucrose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'glucose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fructose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'lactose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'maltose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'adjustedProtein', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'energyKj', value: 'kJ', __typename: 'BiteAiKeyValuePairString' },
        { key: 'galactose', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fluoride', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminDIu', value: 'IU', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminD2', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'vitaminD3', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaTocopherol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'gammaTocopherol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'deltaTocopherol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'alphaTocotrienol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaTocotrienol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'gammaTocotrienol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'deltaTocotrienol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'totalFolate', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'menaquinone4', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'dihydrophylloquinone', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'folateFood', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaine', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'tryptophan', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'threonine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'isoleucine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'leucine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'lysine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'methionine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'cystine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'phenylalanine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'tyrosine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'valine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'arginine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'histidine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'alanine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'asparticAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'glutamicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'glycine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'proline', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'serine', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'hydroxyproline', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'addedVitaminE', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'addedVitaminB12', value: 'µg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'butyricAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'caproicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'caprylicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'capricAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'lauricAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'myristic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'palmitic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'stearic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'arachidic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'oleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'linoleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'linolenic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid204', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'docosahexaenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'behenic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'myristoleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'palmitoleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'parinaricAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'gadoleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'eicosapentaenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'docosenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'docosapentaenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'phytosterols', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'stigmasterol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'campesterol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'betaSitosterol', value: 'mg', __typename: 'BiteAiKeyValuePairString' },
        { key: 'pentadecanoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'margaric', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'lignoceric', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid161T', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid181T', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid221T', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid182TNfd', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid182I', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid182Tt', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid182Clas', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'nervonic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'eicosadienoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid161C', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid181C', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid182N6Cc', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'erucicCitoleic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'gammaLinolenicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'heptadecenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'eicosatrienoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'transFatMonoenoic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'transFatPolyenoic', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'tridecanoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'pentadecenoicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'alphaLinolenicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid203N3', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid203N6', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'arachidonicAcid', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid183i', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid215', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid224', value: 'g', __typename: 'BiteAiKeyValuePairString' },
        { key: 'fattyAcid18111T', value: 'g', __typename: 'BiteAiKeyValuePairString' },
      ],
      dailyValues: [
        { key: 'calories', value: 2000.0, __typename: 'KeyValuePairFloat' },
        { key: 'total_fat', value: 78.0, __typename: 'KeyValuePairFloat' },
        { key: 'saturated_fat', value: 20.0, __typename: 'KeyValuePairFloat' },
        { key: 'monounsaturated_fat', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'polyunsaturated_fat', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'trans_fat', value: 2.0, __typename: 'KeyValuePairFloat' },
        { key: 'total_carb', value: 275.0, __typename: 'KeyValuePairFloat' },
        { key: 'sugars', value: 50.0, __typename: 'KeyValuePairFloat' },
        { key: 'fiber', value: 28.0, __typename: 'KeyValuePairFloat' },
        { key: 'protein', value: 50.0, __typename: 'KeyValuePairFloat' },
        { key: 'cholesterol', value: 300.0, __typename: 'KeyValuePairFloat' },
        { key: 'sodium', value: 2400.0, __typename: 'KeyValuePairFloat' },
        { key: 'potassium', value: 4700.0, __typename: 'KeyValuePairFloat' },
        { key: 'calcium', value: 1300.0, __typename: 'KeyValuePairFloat' },
        { key: 'iron', value: 18.0, __typename: 'KeyValuePairFloat' },
        { key: 'magnesium', value: 420.0, __typename: 'KeyValuePairFloat' },
        { key: 'phosphorus', value: 1250.0, __typename: 'KeyValuePairFloat' },
        { key: 'zinc', value: 11.0, __typename: 'KeyValuePairFloat' },
        { key: 'copper', value: 0.9, __typename: 'KeyValuePairFloat' },
        { key: 'manganese', value: 2.3, __typename: 'KeyValuePairFloat' },
        { key: 'selenium', value: 55.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_a_rae', value: 900.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_a_iu', value: 3000.0, __typename: 'KeyValuePairFloat' },
        { key: 'retinol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'alpha_carotene', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'beta_carotene', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'beta_cryptoxanthin', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'lycopene', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'thiamin', value: 1.2, __typename: 'KeyValuePairFloat' },
        { key: 'riboflavin', value: 1.3, __typename: 'KeyValuePairFloat' },
        { key: 'niacin', value: 16.0, __typename: 'KeyValuePairFloat' },
        { key: 'pantothenic_acid', value: 5.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_b6', value: 1.7, __typename: 'KeyValuePairFloat' },
        { key: 'folate', value: 400.0, __typename: 'KeyValuePairFloat' },
        { key: 'folic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_b12', value: 2.4, __typename: 'KeyValuePairFloat' },
        { key: 'choline', value: 550.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_c', value: 90.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_d', value: 20.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_e', value: 15.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_k', value: 120.0, __typename: 'KeyValuePairFloat' },
        { key: 'water', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'ash', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'caffeine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'alcohol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'added_sugars', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'omega_3', value: 1.6, __typename: 'KeyValuePairFloat' },
        { key: 'omega_6', value: 14.0, __typename: 'KeyValuePairFloat' },
        { key: 'theobromine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'lutein_zeaxanthin', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fruit', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'vegetable', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'whole_grain', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'starch', value: 4.4, __typename: 'KeyValuePairFloat' },
        { key: 'sucrose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'glucose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fructose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'lactose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'maltose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'adjusted_protein', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'energy_kj', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'galactose', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fluoride', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_d_iu', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_d2', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'vitamin_d3', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'beta_tocopherol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'gamma_tocopherol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'delta_tocopherol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'alpha_tocotrienol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'beta_tocotrienol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'gamma_tocotrienol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'delta_tocotrienol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'total_folate', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'menaquinone_4', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'dihydrophylloquinone', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'folate_food', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'betaine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'tryptophan', value: 0.28, __typename: 'KeyValuePairFloat' },
        { key: 'threonine', value: 1.05, __typename: 'KeyValuePairFloat' },
        { key: 'isoleucine', value: 1.4, __typename: 'KeyValuePairFloat' },
        { key: 'leucine', value: 2.73, __typename: 'KeyValuePairFloat' },
        { key: 'lysine', value: 2.1, __typename: 'KeyValuePairFloat' },
        { key: 'methionine', value: 0.728, __typename: 'KeyValuePairFloat' },
        { key: 'cystine', value: 0.287, __typename: 'KeyValuePairFloat' },
        { key: 'phenylalanine', value: 0.875, __typename: 'KeyValuePairFloat' },
        { key: 'tyrosine', value: 0.875, __typename: 'KeyValuePairFloat' },
        { key: 'valine', value: 1.82, __typename: 'KeyValuePairFloat' },
        { key: 'arginine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'histidine', value: 0.7, __typename: 'KeyValuePairFloat' },
        { key: 'alanine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'aspartic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: '0lutamic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: '0lycine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'proline', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'serine', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'hydroxyproline', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'added_vitamin_e', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'added_vitamin_b12', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'butyric_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'caproic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'caprylic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'capric_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'lauric_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'myristic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'palmitic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'stearic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'arachidic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'oleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'linoleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'linolenic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_20_4', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'docosahexaenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'behenic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'myristoleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'palmitoleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'parinaric_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: '0adoleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'eicosapentaenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'docosenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'docosapentaenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'phytosterols', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'sti0masterol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'campesterol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'beta_sitosterol', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'pentadecanoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'mar0aric', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'li0noceric', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_16_1_t', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_1_t', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_22_1_t', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_2_t_nfd', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_2_i', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_2_tt', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_2_clas', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'nervonic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'eicosadienoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_16_1_c', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_1_c', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_2_n_6_cc', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'erucic_citoleic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: '0amma_linolenic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'heptadecenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'eicosatrienoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'trans_fat_monoenoic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'trans_fat_polyenoic', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'tridecanoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'pentadecenoic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'alpha_linolenic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_20_3_n_3', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_20_3_n_6', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'arachidonic_acid', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_3i', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_21_5', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_22_4', value: 0.0, __typename: 'KeyValuePairFloat' },
        { key: 'fatty_acid_18_1_11_t', value: 0.0, __typename: 'KeyValuePairFloat' },
      ],
      __typename: 'BiteAi',
    },
    healthKit: {
      ignore: [],
      sourceIgnore: ['org.nutrisense', 'com.dexcom'],
      __typename: 'HealthKit',
    },
    customIngredients: {
      macros: [
        { name: 'calories', indent: 0, __typename: 'Macro' },
        { name: 'totalFat', indent: 0, __typename: 'Macro' },
        { name: 'saturatedFat', indent: 1, __typename: 'Macro' },
        { name: 'transFat', indent: 1, __typename: 'Macro' },
        { name: 'polyunsaturatedFat', indent: 1, __typename: 'Macro' },
        { name: 'monounsaturatedFat', indent: 1, __typename: 'Macro' },
        { name: 'cholesterol', indent: 0, __typename: 'Macro' },
        { name: 'sodium', indent: 0, __typename: 'Macro' },
        { name: 'totalCarb', indent: 0, __typename: 'Macro' },
        { name: 'fiber', indent: 1, __typename: 'Macro' },
        { name: 'sugars', indent: 1, __typename: 'Macro' },
        { name: 'protein', indent: 0, __typename: 'Macro' },
      ],
      __typename: 'CustomIngredients',
    },
    settings: {
      cancellationPolicy:
        'https://support.nutrisense.io/hc/en-us/articles/4402767446679-What-is-Your-Subscription-Pause-Cancellation-Policy-',
      privacyPolicy: 'https://nutrisense.io/privacy-policy',
      termsOfUse: 'https://nutrisense.io/terms-of-use',
      telehealth: 'https://www.nutrisense.io/legal/telehealth',
      consumerHealthDataPrivacyPolicy:
        'https://www.nutrisense.io/legal/privacy-policy#consumer-health-data',
      __typename: 'Settings',
    },
    remindersSettings: {
      hoursInterval: {
        glucoseCheckOptions: [
          { text: '24 hours', value: 24.0, __typename: 'NumericalSelect' },
          { text: '23 hours', value: 23.0, __typename: 'NumericalSelect' },
          { text: '22 hours', value: 22.0, __typename: 'NumericalSelect' },
          { text: '21 hours', value: 21.0, __typename: 'NumericalSelect' },
          { text: '20 hours', value: 20.0, __typename: 'NumericalSelect' },
          { text: '19 hours', value: 19.0, __typename: 'NumericalSelect' },
          { text: '18 hours', value: 18.0, __typename: 'NumericalSelect' },
          { text: '17 hours', value: 17.0, __typename: 'NumericalSelect' },
          { text: '16 hours', value: 16.0, __typename: 'NumericalSelect' },
          { text: '15 hours', value: 15.0, __typename: 'NumericalSelect' },
          { text: '14 hours', value: 14.0, __typename: 'NumericalSelect' },
          { text: '13 hours', value: 13.0, __typename: 'NumericalSelect' },
          { text: '12 hours', value: 12.0, __typename: 'NumericalSelect' },
          { text: '11 hours', value: 11.0, __typename: 'NumericalSelect' },
          { text: '10 hours', value: 10.0, __typename: 'NumericalSelect' },
          { text: '9 hours', value: 9.0, __typename: 'NumericalSelect' },
          { text: '8 hours', value: 8.0, __typename: 'NumericalSelect' },
          { text: '7 hours', value: 7.0, __typename: 'NumericalSelect' },
          { text: '6 hours', value: 6.0, __typename: 'NumericalSelect' },
          { text: '5 hours', value: 5.0, __typename: 'NumericalSelect' },
          { text: '4 hours', value: 4.0, __typename: 'NumericalSelect' },
          { text: '3 hours', value: 3.0, __typename: 'NumericalSelect' },
          { text: '2 hours', value: 2.0, __typename: 'NumericalSelect' },
          { text: '1 hour', value: 1.0, __typename: 'NumericalSelect' },
        ],
        __typename: 'HoursInterval',
      },
      __typename: 'RemindersSettings',
    },
    healthSettings: {
      macroGoalSettings: {
        caloriesMax: 5000,
        proteinMax: 300,
        carbsMax: 750,
        fatMax: 750,
        __typename: 'MacroGoalSettings',
      },
      scanReminderOptions: [
        { text: '8 hours', value: 8.0, __typename: 'NumericalSelect' },
        { text: '7 hours', value: 7.0, __typename: 'NumericalSelect' },
        { text: '6 hours', value: 6.0, __typename: 'NumericalSelect' },
        { text: '5 hours', value: 5.0, __typename: 'NumericalSelect' },
        { text: '4 hours', value: 4.0, __typename: 'NumericalSelect' },
        { text: '3 hours', value: 3.0, __typename: 'NumericalSelect' },
        { text: '2 hours', value: 2.0, __typename: 'NumericalSelect' },
        { text: '1 hour', value: 1.0, __typename: 'NumericalSelect' },
      ],
      glucoseLowOptions: [
        { text: '40 mg/dL', value: 40.0, __typename: 'NumericalSelect' },
        { text: '50 mg/dL', value: 50.0, __typename: 'NumericalSelect' },
        { text: '60 mg/dL', value: 60.0, __typename: 'NumericalSelect' },
        { text: '70 mg/dL', value: 70.0, __typename: 'NumericalSelect' },
        { text: '80 mg/dL', value: 80.0, __typename: 'NumericalSelect' },
        { text: '90 mg/dL', value: 90.0, __typename: 'NumericalSelect' },
        { text: '100 mg/dL', value: 100.0, __typename: 'NumericalSelect' },
      ],
      glucoseHighOptions: [
        { text: '100 mg/dL', value: 100.0, __typename: 'NumericalSelect' },
        { text: '110 mg/dL', value: 110.0, __typename: 'NumericalSelect' },
        { text: '120 mg/dL', value: 120.0, __typename: 'NumericalSelect' },
        { text: '130 mg/dL', value: 130.0, __typename: 'NumericalSelect' },
        { text: '140 mg/dL', value: 140.0, __typename: 'NumericalSelect' },
        { text: '150 mg/dL', value: 150.0, __typename: 'NumericalSelect' },
        { text: '160 mg/dL', value: 160.0, __typename: 'NumericalSelect' },
        { text: '170 mg/dL', value: 170.0, __typename: 'NumericalSelect' },
        { text: '180 mg/dL', value: 180.0, __typename: 'NumericalSelect' },
        { text: '190 mg/dL', value: 190.0, __typename: 'NumericalSelect' },
        { text: '200 mg/dL', value: 200.0, __typename: 'NumericalSelect' },
      ],
      ketoneLowOptions: [
        { text: '0.0 mmol/L', value: 0.0, __typename: 'NumericalSelect' },
        { text: '0.1 mmol/L', value: 0.1, __typename: 'NumericalSelect' },
        { text: '0.2 mmol/L', value: 0.2, __typename: 'NumericalSelect' },
        { text: '0.3 mmol/L', value: 0.3, __typename: 'NumericalSelect' },
        { text: '0.4 mmol/L', value: 0.4, __typename: 'NumericalSelect' },
        { text: '0.5 mmol/L', value: 0.5, __typename: 'NumericalSelect' },
        { text: '0.6 mmol/L', value: 0.6, __typename: 'NumericalSelect' },
        { text: '0.7 mmol/L', value: 0.7, __typename: 'NumericalSelect' },
        { text: '0.8 mmol/L', value: 0.8, __typename: 'NumericalSelect' },
        { text: '0.9 mmol/L', value: 0.9, __typename: 'NumericalSelect' },
        { text: '1.0 mmol/L', value: 1.0, __typename: 'NumericalSelect' },
        { text: '1.1 mmol/L', value: 1.1, __typename: 'NumericalSelect' },
        { text: '1.2 mmol/L', value: 1.2, __typename: 'NumericalSelect' },
        { text: '1.3 mmol/L', value: 1.3, __typename: 'NumericalSelect' },
        { text: '1.4 mmol/L', value: 1.4, __typename: 'NumericalSelect' },
        { text: '1.5 mmol/L', value: 1.5, __typename: 'NumericalSelect' },
        { text: '1.6 mmol/L', value: 1.6, __typename: 'NumericalSelect' },
        { text: '1.7 mmol/L', value: 1.7, __typename: 'NumericalSelect' },
        { text: '1.8 mmol/L', value: 1.8, __typename: 'NumericalSelect' },
        { text: '1.9 mmol/L', value: 1.9, __typename: 'NumericalSelect' },
        { text: '2.0 mmol/L', value: 2.0, __typename: 'NumericalSelect' },
      ],
      ketoneHighOptions: [
        { text: '2.0 mmol/L', value: 2.0, __typename: 'NumericalSelect' },
        { text: '2.1 mmol/L', value: 2.1, __typename: 'NumericalSelect' },
        { text: '2.2 mmol/L', value: 2.2, __typename: 'NumericalSelect' },
        { text: '2.3 mmol/L', value: 2.3, __typename: 'NumericalSelect' },
        { text: '2.4 mmol/L', value: 2.4, __typename: 'NumericalSelect' },
        { text: '2.5 mmol/L', value: 2.5, __typename: 'NumericalSelect' },
        { text: '2.6 mmol/L', value: 2.6, __typename: 'NumericalSelect' },
        { text: '2.7 mmol/L', value: 2.7, __typename: 'NumericalSelect' },
        { text: '2.8 mmol/L', value: 2.8, __typename: 'NumericalSelect' },
        { text: '2.9 mmol/L', value: 2.9, __typename: 'NumericalSelect' },
        { text: '3.0 mmol/L', value: 3.0, __typename: 'NumericalSelect' },
        { text: '3.1 mmol/L', value: 3.1, __typename: 'NumericalSelect' },
        { text: '3.2 mmol/L', value: 3.2, __typename: 'NumericalSelect' },
        { text: '3.3 mmol/L', value: 3.3, __typename: 'NumericalSelect' },
        { text: '3.4 mmol/L', value: 3.4, __typename: 'NumericalSelect' },
        { text: '3.5 mmol/L', value: 3.5, __typename: 'NumericalSelect' },
        { text: '3.6 mmol/L', value: 3.6, __typename: 'NumericalSelect' },
        { text: '3.7 mmol/L', value: 3.7, __typename: 'NumericalSelect' },
        { text: '3.8 mmol/L', value: 3.8, __typename: 'NumericalSelect' },
        { text: '3.9 mmol/L', value: 3.9, __typename: 'NumericalSelect' },
        { text: '4.0 mmol/L', value: 4.0, __typename: 'NumericalSelect' },
        { text: '4.1 mmol/L', value: 4.1, __typename: 'NumericalSelect' },
        { text: '4.2 mmol/L', value: 4.2, __typename: 'NumericalSelect' },
        { text: '4.3 mmol/L', value: 4.3, __typename: 'NumericalSelect' },
        { text: '4.4 mmol/L', value: 4.4, __typename: 'NumericalSelect' },
        { text: '4.5 mmol/L', value: 4.5, __typename: 'NumericalSelect' },
        { text: '4.6 mmol/L', value: 4.6, __typename: 'NumericalSelect' },
        { text: '4.7 mmol/L', value: 4.7, __typename: 'NumericalSelect' },
        { text: '4.8 mmol/L', value: 4.8, __typename: 'NumericalSelect' },
        { text: '4.9 mmol/L', value: 4.9, __typename: 'NumericalSelect' },
        { text: '5.0 mmol/L', value: 5.0, __typename: 'NumericalSelect' },
        { text: '5.1 mmol/L', value: 5.1, __typename: 'NumericalSelect' },
        { text: '5.2 mmol/L', value: 5.2, __typename: 'NumericalSelect' },
        { text: '5.3 mmol/L', value: 5.3, __typename: 'NumericalSelect' },
        { text: '5.4 mmol/L', value: 5.4, __typename: 'NumericalSelect' },
        { text: '5.5 mmol/L', value: 5.5, __typename: 'NumericalSelect' },
        { text: '5.6 mmol/L', value: 5.6, __typename: 'NumericalSelect' },
        { text: '5.7 mmol/L', value: 5.7, __typename: 'NumericalSelect' },
        { text: '5.8 mmol/L', value: 5.8, __typename: 'NumericalSelect' },
        { text: '5.9 mmol/L', value: 5.9, __typename: 'NumericalSelect' },
        { text: '6.0 mmol/L', value: 6.0, __typename: 'NumericalSelect' },
        { text: '6.1 mmol/L', value: 6.1, __typename: 'NumericalSelect' },
        { text: '6.2 mmol/L', value: 6.2, __typename: 'NumericalSelect' },
        { text: '6.3 mmol/L', value: 6.3, __typename: 'NumericalSelect' },
        { text: '6.4 mmol/L', value: 6.4, __typename: 'NumericalSelect' },
        { text: '6.5 mmol/L', value: 6.5, __typename: 'NumericalSelect' },
        { text: '6.6 mmol/L', value: 6.6, __typename: 'NumericalSelect' },
        { text: '6.7 mmol/L', value: 6.7, __typename: 'NumericalSelect' },
        { text: '6.8 mmol/L', value: 6.8, __typename: 'NumericalSelect' },
        { text: '6.9 mmol/L', value: 6.9, __typename: 'NumericalSelect' },
        { text: '7.0 mmol/L', value: 7.0, __typename: 'NumericalSelect' },
        { text: '7.1 mmol/L', value: 7.1, __typename: 'NumericalSelect' },
        { text: '7.2 mmol/L', value: 7.2, __typename: 'NumericalSelect' },
        { text: '7.3 mmol/L', value: 7.3, __typename: 'NumericalSelect' },
        { text: '7.4 mmol/L', value: 7.4, __typename: 'NumericalSelect' },
        { text: '7.5 mmol/L', value: 7.5, __typename: 'NumericalSelect' },
        { text: '7.6 mmol/L', value: 7.6, __typename: 'NumericalSelect' },
        { text: '7.7 mmol/L', value: 7.7, __typename: 'NumericalSelect' },
        { text: '7.8 mmol/L', value: 7.8, __typename: 'NumericalSelect' },
        { text: '7.9 mmol/L', value: 7.9, __typename: 'NumericalSelect' },
        { text: '8.0 mmol/L', value: 8.0, __typename: 'NumericalSelect' },
      ],
      dashboardGraphOptions: [
        { text: 'Glucose', value: 'glucose', __typename: 'StringSelect' },
        { text: 'Glucose + Ketones', value: 'combined', __typename: 'StringSelect' },
      ],
      unitSystemOptions: [
        { text: 'Imperial', value: 'imperial', __typename: 'StringSelect' },
        { text: 'Metric', value: 'metric', __typename: 'StringSelect' },
      ],
      glucoseThresholdPresets: [
        {
          name: 'Restricted',
          description:
            'For those following a very low-carbohydrate/no-carbohydrate dietary approach.',
          low: 70,
          high: 120,
          __typename: 'GlucoseThresholdPreset',
        },
        {
          name: 'Balanced',
          description: 'Best for most individuals.',
          low: 70,
          high: 140,
          __typename: 'GlucoseThresholdPreset',
        },
        {
          name: 'Expanded',
          description: 'For those that are working on improving their glucose control.',
          low: 70,
          high: 180,
          __typename: 'GlucoseThresholdPreset',
        },
      ],
      __typename: 'HealthSettings',
    },
    sensorSettings: {
      baselineAdjustmentOptions: [
        { text: '-70 mg/dL', value: -70.0, __typename: 'NumericalSelect' },
        { text: '-65 mg/dL', value: -65.0, __typename: 'NumericalSelect' },
        { text: '-60 mg/dL', value: -60.0, __typename: 'NumericalSelect' },
        { text: '-55 mg/dL', value: -55.0, __typename: 'NumericalSelect' },
        { text: '-50 mg/dL', value: -50.0, __typename: 'NumericalSelect' },
        { text: '-45 mg/dL', value: -45.0, __typename: 'NumericalSelect' },
        { text: '-40 mg/dL', value: -40.0, __typename: 'NumericalSelect' },
        { text: '-35 mg/dL', value: -35.0, __typename: 'NumericalSelect' },
        { text: '-30 mg/dL', value: -30.0, __typename: 'NumericalSelect' },
        { text: '-25 mg/dL', value: -25.0, __typename: 'NumericalSelect' },
        { text: '-20 mg/dL', value: -20.0, __typename: 'NumericalSelect' },
        { text: '-15 mg/dL', value: -15.0, __typename: 'NumericalSelect' },
        { text: '-10 mg/dL', value: -10.0, __typename: 'NumericalSelect' },
        { text: '-5 mg/dL', value: -5.0, __typename: 'NumericalSelect' },
        { text: '0 mg/dL', value: 0.0, __typename: 'NumericalSelect' },
        { text: '+5 mg/dL', value: 5.0, __typename: 'NumericalSelect' },
        { text: '+10 mg/dL', value: 10.0, __typename: 'NumericalSelect' },
        { text: '+15 mg/dL', value: 15.0, __typename: 'NumericalSelect' },
        { text: '+20 mg/dL', value: 20.0, __typename: 'NumericalSelect' },
        { text: '+25 mg/dL', value: 25.0, __typename: 'NumericalSelect' },
        { text: '+30 mg/dL', value: 30.0, __typename: 'NumericalSelect' },
        { text: '+35 mg/dL', value: 35.0, __typename: 'NumericalSelect' },
        { text: '+40 mg/dL', value: 40.0, __typename: 'NumericalSelect' },
        { text: '+45 mg/dL', value: 45.0, __typename: 'NumericalSelect' },
        { text: '+50 mg/dL', value: 50.0, __typename: 'NumericalSelect' },
        { text: '+55 mg/dL', value: 55.0, __typename: 'NumericalSelect' },
        { text: '+60 mg/dL', value: 60.0, __typename: 'NumericalSelect' },
        { text: '+65 mg/dL', value: 65.0, __typename: 'NumericalSelect' },
        { text: '+70 mg/dL', value: 70.0, __typename: 'NumericalSelect' },
      ],
      __typename: 'SensorSettings',
    },
    referral: {
      title: 'Get $25 Off, Gift $25 Off',
      discount: 25,
      credits: 25,
      description:
        'Invite a friend to Nutrisense and you will both receive discount for your next full month of service.',
      message:
        'Sign up for Nutrisense with my discount code to get $25 off your next month of service.',
      __typename: 'Referral',
    },
    onboarding: {
      tutorials: [
        {
          key: 'welcomeTour',
          value: [
            {
              title: 'Understand your Baseline',
              description:
                'Uncover the connection between your lifestyle and your glucose levels, daily energy, mood, and more. ',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/welcome_tour_1.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/welcome_tour_1.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Experiment & Improve',
              description:
                'Easily plan and test changes to find the unique behaviors and foods that work best for you.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/welcome_tour_2.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/welcome_tour_2.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Create Lasting Changes',
              description:
                'Find support and accountability to turn your discoveries into consistent habits, so you can sustain your goals for the long run.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/welcome_tour_3.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/welcome_tour_3.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'gettingStarted',
          value: [
            {
              title: 'Get Started',
              description:
                'What do glucose levels tell you about your health? How does everything from sleep, stress, exercise, and of course, what you eat, affect your life? All of this information is unique to you. We’ll help you discover and master all of the above through real time data, and your very own expert dietitian.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/onboarding.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/onboarding.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Glucose Chart',
              description:
                'Each time you scan your CGM, real-time glucose data will populate the glucose chart. \\pAdd important events, such as meals, exercise, or stress levels to the chart by placing a point on the chart and then tapping the green plus button at the bottom of the dashboard. This will help you connect the dots and understand how your day to day decisions are affecting your health.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/glucose_graph.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/glucose_graph.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Daily Glucose Score',
              description:
                'Peak is how high your glucose spiked during the day. \\pAverage is your average glucose level for the day. \\pAdaptability measures how much time you spent above your glucose threshold (usually 140 mg/dL). \\pVariability is a measure of how much your glucose fluctuated during the day.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/summary_glucose_score.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/summary_glucose_score.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Installing your CGM',
              description: 'Watch the video above for instructions.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: 'https://d327a4c5tk30k5.cloudfront.net/How_to_put_on_your_CGM_1080p.mp4',
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'mealDetails',
          value: [
            {
              title: 'Meal Score',
              description:
                'Peak is how high your glucose spiked after the meal. \\pExposure is the area under the glucose curve for 2 hours following the meal. \\pStability measures how much of a glucose jump you had. \\pRecovery is how close your 2 hour glucose value is to your pre-meal glucose value.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/meal_details_score.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/meal_details_score.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Meal Chart',
              description:
                'The meal chart shows you the raw data we use to calculate your meal score. \\pMax is how high your glucose spiked after a meal. \\pArea is the area under the curve.  \\pDelta measures how much of a glucose jump you had. \\p2 hour delta is how close your 2 hour glucose value is to your pre-meal glucose value.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/meal_details_chart.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/meal_details_chart.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Meal Macro Summary',
              description:
                'The meal macro summary shows you the macronutrient composition of each meal. \\pYou can set your macro goals in the Member Settings.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/summary_macros.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/summary_macros.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'mealChart',
          value: [
            {
              title: 'Meal Chart',
              description:
                'The meal chart shows you the raw data we use to calculate your meal score. \\pMax is how high your glucose spiked after a meal. \\pArea is the area under the curve.  \\pDelta measures how much of a glucose jump you had. \\p2 hour delta is how close your 2 hour glucose value is to your pre-meal glucose value.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/meal_details_chart.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/meal_details_chart.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'mealMacroSummary',
          value: [
            {
              title: 'Meal Macro Summary',
              description:
                'The meal macro summary shows you the macronutrient composition of each meal. \\pYou can set your macro goals in the Member Settings.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/summary_macros.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/summary_macros.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'experiments',
          value: [
            {
              title: 'Experiments',
              description:
                'There are many variables to tweak when experimenting with your nutrition. \\pAn experiment allows you to compare meal response based on meal type, ingredient composition, time of day and more. \\pAsk your dietitian for some great experiment ideas.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/new_experiment.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/new_experiment.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Creating An Experiment',
              description:
                'If you decide to create a Custom Meal List, then you can manually add meals to this experiment by tapping the three dot menu on any meal. \\pIf you choose to Search All Meals, then all you have to do is tap into the experiment and adjust the search filters to match what you are looking for. The meals will be populated automatically as you continue to track.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/new_experiment.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/new_experiment.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'calendar',
          value: [
            {
              title: 'Calendar',
              description:
                'The calendar allows you to quickly jump to a different day or to select up to a 120-day date range. \\pTo select a multi-day range, simply tap and hold on any calendar day, then tap the end of the range to finish the selection.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/calendar.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/calendar.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'ingredients',
          value: [
            {
              title: 'Custom Ingredients',
              description:
                'This is your personal collection of custom ingredients. Our database is still a work in progress, so this will help you fill in the gaps on those hard-to-find items in your pantry. \\pWhen creating a custom ingredient, you can pre-fill the macro information by either scanning a barcode or searching our database for a similar item.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/summary_macros.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/summary_macros.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'calibration',
          value: [
            {
              title: 'Calibration',
              description:
                'The CGM accurately captures changes in glucose levels; however, all CGM devices can sometimes be calibrated slightly higher or lower from the factory. \\pThe manual calibration feature allows you to adjust the CGM data by using a recent fasting glucose value from a lab or from a blood glucose meter at home. Making a baseline adjustment of "-20" will shift the CGM data and all statistics 20 points lower. \\pWe recommend only using fasting glucose values to calibrate the CGM. This will guarantee that your glucose is stable at the time of calibration. We also recommend avoiding manual calibration until you have worn the device for at least 24 hours.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'timelineGlucoseChart',
          value: [
            {
              title: 'How To Read The Glucose Chart?',
              description:
                'The line on the chart represents the glucose data from your CGM. A green line indicates your values are within target range. An orange or red line indicates your values are outside of the target range.',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/glucose_line_chart.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/glucose_line_chart.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
            {
              title: 'Adding events',
              description:
                'To connect specific events to your glucose values, use the green plus button on the Home screen to log events such as meals, activities, and measurements. The dots on the graph will represent these logged events.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'timelineGlucoseRanges',
          value: [
            {
              title: 'Target Ranges',
              description:
                'The default target range is 70-140 mg/dl. We want to keep glucose spikes within this range as much as possible, but we also want to avoid major shifts in glucose even if it is staying in the green the whole time.\\pPlease note that target ranges may vary depending on your health status and can be adjusted in Chart Settings.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'timelineKetoneChart',
          value: [
            {
              title: 'How To Read The Ketone Chart?',
              description:
                'The line on the chart represents your blood ketone measurements.\\pSync KetoMojo with your Nutrisense app to track your blood ketones or log manually using the green plus button on the Home screen (add as a measurement).',
              photoUrlLight:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/light/ketone_line_chart.png',
              photoUrlDark:
                'https://d327a4c5tk30k5.cloudfront.net/tutorials-v2.7.0/dark/ketone_line_chart.png',
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'timelineKetoneRanges',
          value: [
            {
              title: 'Ketone Ranges',
              description:
                'The default target range is 0.5-3 mmol/L, which is the range for optimal ketosis. Note there is no health benefit for achieving ketone values \u003e3 mmol.\\pIf your ketones are \u003e8 mmol/L, check your glucose values. If glucose values are \u003e240 mg/dL, please refer to your healthcare provider.\\pTarget range can be adjusted in Chart Settings.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'dailyGlucoseScore',
          value: [
            {
              title: 'Daily Glucose Score',
              description:
                'This score is composed of 4 glucose metrics for the selected time period: peak, average, adaptability, and variability. A higher score indicates better glycemic control.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'dailyMacroSummary',
          value: [
            {
              title: 'Daily Macro Summary',
              description:
                'Daily macro goals are targets you set for the intake of macronutrients, such as calories, carbs, protein, and fat, in your diet. By tapping on the update macro goals button, you can adjust these targets based on your  needs and preferences.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'scorePeak',
          value: [
            {
              title: 'Peak',
              description: 'Your highest glucose value.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'scoreMean',
          value: [
            {
              title: 'Average',
              description: 'Your average glucose value.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'scoreTimeOutsideRange',
          value: [
            {
              title: 'Adaptability',
              description: 'The amount of time spent above your glucose threshold.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'scoreStdDev',
          value: [
            {
              title: 'Variability',
              description: 'Your standard deviation or the “swings” in your glucose data.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
        {
          key: 'glucoseThresholdRecommendation',
          value: [
            {
              title: 'Recommendation',
              description:
                'If you are really looking to take your health to the next level and optimize, you can change your upper threshold to 120. If you are spiking often and want to start with a smaller goal to first master, you can adjust the upper threshold to 160.\\pDo you often go below 70? Many non-diabetics may actually have glucose readings below 70, especially when fasting. As long as you are not having symptoms of hypoglycemia, such as dizziness, nausea, or sweating, then this is perfectly okay. You can adjust your low threshold if this happens to you often.',
              photoUrlLight: null,
              photoUrlDark: null,
              videoUrl: null,
              __typename: 'TutorialStep',
            },
          ],
          __typename: 'Tutorials',
        },
      ],
      __typename: 'Onboarding',
    },
    education: {
      collections: [
        {
          title: 'About Nutrisense',
          subtitle:
            "Looking to sign up? Already signed up? We've got your questions answered here! ",
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/4436580906391-About-Nutrisense?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'What is a Continuous Glucose Monitor?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402757859735-What-is-a-Continuous-Glucose-Monitor-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'The Nutrisense Protective Patch FAQ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/15687561003799-The-Nutrisense-Protective-Patch-FAQ?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Do I Need a Medical Prescription to Join Nutrisense?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767511319-Do-I-Need-a-Medical-Prescription-to-Join-Nutrisense-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Are You a New Nutrisense Member?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/12925211568919-Are-You-a-New-Nutrisense-Member-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Are You Returning to the Nutrisense Program?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/13174196668183-Are-You-Returning-to-the-Nutrisense-Program-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How does sign up work?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4408019927191-How-does-sign-up-work-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Why do I Need to Upload My ID?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4406902120727-Why-do-I-Need-to-Upload-My-ID-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What is included in my monthly subscription?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/13174597502615-What-is-included-in-my-monthly-subscription-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Once You Have Your CGM',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/13174727501591-Once-You-Have-Your-CGM?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Interested in Partnering with Nutrisense?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4828254765079-Interested-in-Partnering-with-Nutrisense-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Is There a Cheaper Option?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767502487-Is-There-a-Cheaper-Option-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Who Can Join?',
          subtitle:
            "Looking to sign up? Already signed up? We've got your questions answered here! ",
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9224692532887-Who-Can-Join-?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Who Can Join the Nutrisense CGM Program?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4996573265687-Who-Can-Join-the-Nutrisense-CGM-Program-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Do I Need to Have a Smartphone to Use the CGM?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402770031127-Do-I-Need-to-Have-a-Smartphone-to-Use-the-CGM-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Privacy & Security',
          subtitle: 'Answers regarding your data and privacy ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9223749600151-Privacy-Security?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Your Data & Privacy',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4424998945943-Your-Data-Privacy?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Account & Billing',
          subtitle: 'Payments, shipments, subscriptions, and account management ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/4440619326231-Account-Billing?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'How your Nutrisense Subscription Works',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/15141263022615-How-your-Nutrisense-Subscription-Works?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Snooze Your Program',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4423951045015-How-to-Snooze-Your-Program?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Nutrisense Refund Policy ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767446679-Nutrisense-Refund-Policy-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Nutrisense Return Policy ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/9249299694871-Nutrisense-Return-Policy-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Time Between Sensors',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/6223022175895-Time-Between-Sensors?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Do You Accept Health Insurance?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767481239-Do-You-Accept-Health-Insurance-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Start and Stop Coaching Support',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4879304298647-How-to-Start-and-Stop-Coaching-Support?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Do I Update My Payment Method and Shipping Address? ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4408095408663-How-Do-I-Update-My-Payment-Method-and-Shipping-Address-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Change Your Program',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4946518983191-How-to-Change-Your-Program?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Cancel Your Program',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/9244398472727-How-to-Cancel-Your-Program?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Cancel Your Program Early',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/9251013552023-How-to-Cancel-Your-Program-Early?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How do I Purchase and Use a Gift Card?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4865808697879-How-do-I-Purchase-and-Use-a-Gift-Card-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Orders & Shipping',
          subtitle: 'Payments, shipments, subscriptions, and account management ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/1500002950101-Orders-Shipping?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'How Can I Track My Package? ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4814678242583-How-Can-I-Track-My-Package-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Do You Ship Internationally?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767517079-Do-You-Ship-Internationally-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Wrong address on order ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/13431740750359-Wrong-address-on-order-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Getting Started',
          subtitle: 'Everything you need to know about your CGM and App',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/1500002954241-Getting-Started?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Week One Expectations',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815393815-Week-One-Expectations?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Week Two Expectations',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815422359-Week-Two-Expectations?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Week Three Expectations',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815473815-Week-Three-Expectations?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Week Four Expectations',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815527703-Week-Four-Expectations?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: "Update Your iPhone's iOS",
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815162775-Update-Your-iPhone-s-iOS?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Do I Install & Log in to the Nutrisense App (iOS)?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815200535-How-Do-I-Install-Log-in-to-the-Nutrisense-App-iOS-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Do I Install & Log in to the Nutrisense App (Android)?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828844823-How-Do-I-Install-Log-in-to-the-Nutrisense-App-Android-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title:
                'Unlock Your Health Potential: What Your Nutrisense Nutritionist Can Do For You!',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4414388468375-Unlock-Your-Health-Potential-What-Your-Nutrisense-Nutritionist-Can-Do-For-You-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Reschedule a video call with your Nutritionist',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/14364616842903-How-to-Reschedule-a-video-call-with-your-Nutritionist?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Communicate With Your Nutritionist',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816117527-How-to-Communicate-With-Your-Nutritionist?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'About your FreeStyle Libre CGM',
          subtitle: 'Knowledge is power! Everything you need to know about using your CGM',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/4436636287767-About-your-FreeStyle-Libre-CGM?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'How Do I Activate My CGM',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815269911-How-Do-I-Activate-My-CGM?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Do I Put on My CGM?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815217687-How-Do-I-Put-on-My-CGM-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Tips for Placing Your Sensor',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402767370775-Tips-for-Placing-Your-Sensor?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Long Does the CGM Last?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402764964887-How-Long-Does-the-CGM-Last-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Do I Remove the CGM When it Expires?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828924055-How-Do-I-Remove-the-CGM-When-it-Expires-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Need More CGMs?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/13430307498519-Need-More-CGMs-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Is Wearing a Freestyle Libre CGM Painful?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402764954007-Is-Wearing-a-Freestyle-Libre-CGM-Painful-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Tips for People With Sensitive Skin',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4408020104215-Tips-for-People-With-Sensitive-Skin?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Are CGMs Waterproof?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402764925079-Are-CGMs-Waterproof-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Where Can I Wear My CGM? ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402758152471-Where-Can-I-Wear-My-CGM-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Do I Need a Replacement Sensor?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4410177019799-Do-I-Need-a-Replacement-Sensor-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Calibration',
          subtitle: '',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/17299090617495-Calibration?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Do I Need to Calibrate My CGM?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4435539254935-Do-I-Need-to-Calibrate-My-CGM-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Calibrating Your CGM',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816254231-Calibrating-Your-CGM?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Making Sense of Your Data',
          subtitle: 'Everything you need to know about your CGM and App',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9225690479383-Making-Sense-of-Your-Data?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title:
                'Elevating Your Health Journey: Discover the Enhanced UX in Nutrisense App v2.6.0',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/14074478693399-Elevating-Your-Health-Journey-Discover-the-Enhanced-UX-in-Nutrisense-App-v2-6-0?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What is the Learn tab, and how do I use it?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11957458372119-What-is-the-Learn-tab-and-how-do-I-use-it-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What is the Insights tab and how can I make the most of it?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11955507130135-What-is-the-Insights-tab-and-how-can-I-make-the-most-of-it-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Measurements under Insights',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11278770630423-Measurements-under-Insights?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Nutrition Under Insights',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11273810516631-Nutrition-Under-Insights?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Glucose Values under Insights',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11270560625303-Glucose-Values-under-Insights?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Glucose Analytics & Glucose Insights',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816292119-Glucose-Analytics-Glucose-Insights?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Changing Dates and Date Ranges',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816126359-Changing-Dates-and-Date-Ranges?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Increase the Readability of Your Data',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402829385751-Increase-the-Readability-of-Your-Data?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Adjusting Your User Settings',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402815967767-Adjusting-Your-User-Settings?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Breath vs. Blood Ketones',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4997096928791-Breath-vs-Blood-Ketones?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Logging Meals, Activities, Notes, and Measurements',
          subtitle:
            'Knowledge is Power! Learn about using the Nutrisense App to optimize your health ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9225633209879-Logging-Meals-Activities-Notes-and-Measurements?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Can I Log a Meal, Activity, or Note if I Forgot?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/5428529965975-Can-I-Log-a-Meal-Activity-or-Note-if-I-Forgot-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Logging Meals & Adding Nutrition Information',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402829250199-Logging-Meals-Adding-Nutrition-Information?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Log Favorite and Recent Meals',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4533822844567-How-to-Log-Favorite-and-Recent-Meals?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Edit a Logged Meal',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4533644791447-How-to-Edit-a-Logged-Meal?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Logging a Note',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816242839-Logging-a-Note?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Logging an Activity',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816181783-Logging-an-Activity?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'CGM Troubleshooting ',
          subtitle: '',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/13431091292439-CGM-Troubleshooting-?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Unable to Activate Tag',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402771021847-Unable-to-Activate-Tag?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Connection Error',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402778127895-Connection-Error?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Difficulty Scanning with iPhone',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402778073751-Difficulty-Scanning-with-iPhone?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Difficulty Scanning with Android',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402770996759-Difficulty-Scanning-with-Android?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'App Integrations',
          subtitle: 'Everything you need to know about your CGM and App',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9225592716439-App-Integrations?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'App Integrations: Google Fit',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10984276187543-App-Integrations-Google-Fit?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'App Integrations: Garmin',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11020852334103-App-Integrations-Garmin?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'App Integrations: Fitbit',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/11020847670167-App-Integrations-Fitbit?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'App Integrations: Oura',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10984499424407-App-Integrations-Oura?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How to Sync your HealthKit',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4826896538903-How-to-Sync-your-HealthKit?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'KetoMojo Integration',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402816000279-KetoMojo-Integration?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Running Experiments',
          subtitle: 'Everything you need to know about your CGM and App',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/1500002954321-Running-Experiments?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Experiments Part 1: Getting Started ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402778158231-Experiments-Part-1-Getting-Started-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Experiments Part 2:  Experiment Ideas',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4429075302423-Experiments-Part-2-Experiment-Ideas?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Experiments Part 3: How to Log Experiments in the App',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4429080521879-Experiments-Part-3-How-to-Log-Experiments-in-the-App?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Experiments Part 4: What if I Experience an Abnormal Glucose Response?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4429074476439-Experiments-Part-4-What-if-I-Experience-an-Abnormal-Glucose-Response-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Meal Searching for Experiments',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402829400215-Meal-Searching-for-Experiments?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Nutrisense Product Updates',
          subtitle: 'Everything you need to know about your CGM and App',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/4403173837463-Nutrisense-Product-Updates?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: '7/5/2023 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/15790626655127-7-5-2023-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '4/20/2023 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/14041846880151-4-20-2023-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '5/3/2022 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/5847628814359-5-3-2022-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '2/27/2022 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4523749218583-2-27-2022-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '11/9/2021 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4412576590231-11-9-2021-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '9/24/2021 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4409307509655-9-24-2021-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '6/30/2021 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4404574348311-6-30-2021-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '5/10/2021 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403173845783-5-10-2021-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '1/3/2021 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403168610327-1-3-2021-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '11/10/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403168681623-11-10-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '10/06/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403174119447-10-06-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '08/25/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403168809367-08-25-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '07/16/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403168872727-07-16-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '06/16/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403174358807-06-16-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '05/25/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403169443735-05-25-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '05/06/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403177000599-05-06-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '04/15/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403177207703-04-15-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '03/26/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403177240727-03-26-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '03/18/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403177335831-03-18-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '02/26/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403177417239-02-26-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '02/17/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403181635095-02-17-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '02/11/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403179753111-02-11-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '01/21/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403179775767-01-21-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '01/10/2020 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403179861783-01-10-2020-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '12/26/2019 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403179891479-12-26-2019-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '11/12/2019 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403183003159-11-12-2019-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '10/19/2019 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403179977239-10-19-2019-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: '09/06/2019 - Release Notes',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4403180001175-09-06-2019-Release-Notes?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Diabetes Learning Center ',
          subtitle: 'Self study guide to all things diabetes ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/10098883286807-Diabetes-Learning-Center-?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Welcome!',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10099407001111-Welcome-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 1 - Myths and Facts',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125377430935-Chapter-1-Section-1-Myths-and-Facts?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 2 - What is Diabetes?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125410650647-Chapter-1-Section-2-What-is-Diabetes-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 3 - Systems Involved',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125418121111-Chapter-1-Section-3-Systems-Involved?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 4 - Accepting Your Diabetes Diagnosis',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125577278487-Chapter-1-Section-4-Accepting-Your-Diabetes-Diagnosis?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 5 - Goal Review',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125622154263-Chapter-1-Section-5-Goal-Review?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 1: Section 6 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125688510615-Chapter-1-Section-6-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 2: Section 1 - Glucose Monitoring',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125723438359-Chapter-2-Section-1-Glucose-Monitoring?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 2: Section 2 - Hypoglycemia',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125737401239-Chapter-2-Section-2-Hypoglycemia?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 2: Section 3 - Diabetic Ketoacidosis',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125794638487-Chapter-2-Section-3-Diabetic-Ketoacidosis?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 2: Section 4 - Lifestyle Interventions',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125771078167-Chapter-2-Section-4-Lifestyle-Interventions?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 2: Section 5 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125835503255-Chapter-2-Section-5-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 1 - What You Eat ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10125892106903-Chapter-3-Section-1-What-You-Eat-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 2 - How You Eat',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126026358807-Chapter-3-Section-2-How-You-Eat?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 3 - Why You Eat',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126125257495-Chapter-3-Section-3-Why-You-Eat?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 4 - When You Eat',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126165799703-Chapter-3-Section-4-When-You-Eat?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 5 - Working Through Challenges',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126233734551-Chapter-3-Section-5-Working-Through-Challenges?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 6 - Goal Setting',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126238161687-Chapter-3-Section-6-Goal-Setting?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 3: Section 7 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126230935831-Chapter-3-Section-7-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 1 - How Does Physical Activity Impact Blood Sugar?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126267756951-Chapter-4-Section-1-How-Does-Physical-Activity-Impact-Blood-Sugar-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 2 - Structured Exercise',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126298613527-Chapter-4-Section-2-Structured-Exercise?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 3 - Daily Movement',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126352619287-Chapter-4-Section-3-Daily-Movement?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 4 - Physical Activity Timing for Biggest Impact',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126356404119-Chapter-4-Section-4-Physical-Activity-Timing-for-Biggest-Impact?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 5 - Finding Motivation and Building Habits',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126384253463-Chapter-4-Section-5-Finding-Motivation-and-Building-Habits?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 6 - Goal Setting',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126414535063-Chapter-4-Section-6-Goal-Setting?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 4: Section 7 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126416788759-Chapter-4-Section-7-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 5: Section 1 - Relationship Between Sleep and Glucose',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126453637271-Chapter-5-Section-1-Relationship-Between-Sleep-and-Glucose?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 5: Section 2 - Sleep Hygiene',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126447158807-Chapter-5-Section-2-Sleep-Hygiene?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 5: Section 3 - Goal Setting',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126509546007-Chapter-5-Section-3-Goal-Setting?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 5: Section 4 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126485065623-Chapter-5-Section-4-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 6: Section 1 - Stress and Glucose',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126488332567-Chapter-6-Section-1-Stress-and-Glucose?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 6: Section 2 - Stress Reduction Skills',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126552474007-Chapter-6-Section-2-Stress-Reduction-Skills?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 6: Section 3 - Goal Setting',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126544561815-Chapter-6-Section-3-Goal-Setting?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 6: Section 4 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126557799703-Chapter-6-Section-4-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 7: Section 1 - Putting It All Together',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126549873687-Chapter-7-Section-1-Putting-It-All-Together?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 7: Section 2 - Long Term Progress',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126612946455-Chapter-7-Section-2-Long-Term-Progress?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 7: Section 3 - Additional Resources',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126588487575-Chapter-7-Section-3-Additional-Resources?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Chapter 7: Section 4 - Check Your Learning',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/10126616991383-Chapter-7-Section-4-Check-Your-Learning?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Nutrisense Social Media ',
          subtitle:
            'Knowledge is power! Nutrisense blog posts to learn more about using a CGM to optimize your health ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/9217890489751-Nutrisense-Social-Media-?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Where Can I find Nutrisense on Social Media? ',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4821162566295-Where-Can-I-find-Nutrisense-on-Social-Media-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Learning the Basics',
          subtitle:
            'Knowledge is power! Read more about ways to help you along your journey using your CGM ',
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/1500002934782-Learning-the-Basics?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Managing Stress: Biohacking Your Cortisol Response in Three Steps',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402813840919-Managing-Stress-Biohacking-Your-Cortisol-Response-in-Three-Steps?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Understanding Traditional Glucose Values',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814427927-Understanding-Traditional-Glucose-Values?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Understanding Glucose Trends',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814508439-Understanding-Glucose-Trends?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Effects of Poor Glucose Control',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814488343-Effects-of-Poor-Glucose-Control?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'The Role of Insulin',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402813822359-The-Role-of-Insulin?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'The Standard American Diet (SAD)',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828233495-The-Standard-American-Diet-SAD-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'The Complexities of Obesity',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828245271-The-Complexities-of-Obesity?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Importance of Preventative Medicine',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828550295-Importance-of-Preventative-Medicine?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'The Rise of Nutritionism',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402771104663-The-Rise-of-Nutritionism?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Health Personalization',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814526743-Health-Personalization?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Reducing to Nutrients',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828222103-Reducing-to-Nutrients?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What are Hormones, Anyway?',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402813779735-What-are-Hormones-Anyway-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Leptin, the “Satiety” Hormone',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402813798039-Leptin-the-Satiety-Hormone?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
        {
          title: 'Common Questions about Glucose',
          subtitle:
            "Looking to sign up? Already signed up? We've got your questions answered here!",
          imageUrl: '',
          url:
            'https://support.nutrisense.io/hc/en-us/sections/4402828600599-Common-Questions-about-Glucose?utm_source=in-app-education&utm_medium=ns-mobile-app',
          articles: [
            {
              title: 'Do I Have Diabetes?',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814910999-Do-I-Have-Diabetes-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What Range Should My Glucose be in?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828615447-What-Range-Should-My-Glucose-be-in-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What are the Major Factors Influencing My Glucose?',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828586263-What-are-the-Major-Factors-Influencing-My-Glucose-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What Factors Cause My Elevated Fasting Glucose Values? ',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814855703-What-Factors-Cause-My-Elevated-Fasting-Glucose-Values-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What Else Can Influence My Glucose?',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828592023-What-Else-Can-Influence-My-Glucose-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'Why Did I Have a Glucose Spike During Exercise? ',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828658583-Why-Did-I-Have-a-Glucose-Spike-During-Exercise-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'What Happens to Glucose Levels During a Cold',
              author: 'Kara Collier',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402814875287-What-Happens-to-Glucose-Levels-During-a-Cold?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
            {
              title: 'How Does Alcohol Affect Glucose?',
              author: '',
              subtitle: '',
              imageUrl: '',
              url:
                'https://support.nutrisense.io/hc/en-us/articles/4402828679191-How-Does-Alcohol-Affect-Glucose-?utm_source=in-app-education&utm_medium=ns-mobile-app',
              __typename: 'EducationArticle',
            },
          ],
          __typename: 'EducationCollection',
        },
      ],
      __typename: 'Education',
    },
    score: { warning: 79, danger: 49, max: 100, __typename: 'Score' },
    questionnaireUrl: 'https://nutrisense.typeform.com/to/mALF9G91#email=${EMAIL}',
    privacyCenterUrl: 'https://nutrisense.privacy.saymine.io/Nutrisense',
    usStates: [
      { name: 'Alaska', abbreviation: 'AK', __typename: 'State' },
      { name: 'Alabama', abbreviation: 'AL', __typename: 'State' },
      { name: 'American Samoa', abbreviation: 'AS', __typename: 'State' },
      { name: 'Arizona', abbreviation: 'AZ', __typename: 'State' },
      { name: 'Arkansas', abbreviation: 'AR', __typename: 'State' },
      { name: 'California', abbreviation: 'CA', __typename: 'State' },
      { name: 'Colorado', abbreviation: 'CO', __typename: 'State' },
      { name: 'Connecticut', abbreviation: 'CT', __typename: 'State' },
      { name: 'Delaware', abbreviation: 'DE', __typename: 'State' },
      { name: 'District of Columbia', abbreviation: 'DC', __typename: 'State' },
      { name: 'Federated States of Micronesia', abbreviation: 'FM', __typename: 'State' },
      { name: 'Florida', abbreviation: 'FL', __typename: 'State' },
      { name: 'Georgia', abbreviation: 'GA', __typename: 'State' },
      { name: 'Guam', abbreviation: 'GU', __typename: 'State' },
      { name: 'Hawaii', abbreviation: 'HI', __typename: 'State' },
      { name: 'Idaho', abbreviation: 'ID', __typename: 'State' },
      { name: 'Illinois', abbreviation: 'IL', __typename: 'State' },
      { name: 'Indiana', abbreviation: 'IN', __typename: 'State' },
      { name: 'Iowa', abbreviation: 'IA', __typename: 'State' },
      { name: 'Kansas', abbreviation: 'KS', __typename: 'State' },
      { name: 'Kentucky', abbreviation: 'KY', __typename: 'State' },
      { name: 'Louisiana', abbreviation: 'LA', __typename: 'State' },
      { name: 'Maine', abbreviation: 'ME', __typename: 'State' },
      { name: 'Marshall Islands', abbreviation: 'MH', __typename: 'State' },
      { name: 'Maryland', abbreviation: 'MD', __typename: 'State' },
      { name: 'Massachusetts', abbreviation: 'MA', __typename: 'State' },
      { name: 'Michigan', abbreviation: 'MI', __typename: 'State' },
      { name: 'Minnesota', abbreviation: 'MN', __typename: 'State' },
      { name: 'Mississippi', abbreviation: 'MS', __typename: 'State' },
      { name: 'Missouri', abbreviation: 'MO', __typename: 'State' },
      { name: 'Montana', abbreviation: 'MT', __typename: 'State' },
      { name: 'Nebraska', abbreviation: 'NE', __typename: 'State' },
      { name: 'Nevada', abbreviation: 'NV', __typename: 'State' },
      { name: 'New Hampshire', abbreviation: 'NH', __typename: 'State' },
      { name: 'New Jersey', abbreviation: 'NJ', __typename: 'State' },
      { name: 'New Mexico', abbreviation: 'NM', __typename: 'State' },
      { name: 'New York', abbreviation: 'NY', __typename: 'State' },
      { name: 'North Carolina', abbreviation: 'NC', __typename: 'State' },
      { name: 'North Dakota', abbreviation: 'ND', __typename: 'State' },
      { name: 'Northern Mariana Islands', abbreviation: 'MP', __typename: 'State' },
      { name: 'Ohio', abbreviation: 'OH', __typename: 'State' },
      { name: 'Oklahoma', abbreviation: 'OK', __typename: 'State' },
      { name: 'Oregon', abbreviation: 'OR', __typename: 'State' },
      { name: 'Palau', abbreviation: 'PW', __typename: 'State' },
      { name: 'Pennsylvania', abbreviation: 'PA', __typename: 'State' },
      { name: 'Puerto Rico', abbreviation: 'PR', __typename: 'State' },
      { name: 'Rhode Island', abbreviation: 'RI', __typename: 'State' },
      { name: 'South Carolina', abbreviation: 'SC', __typename: 'State' },
      { name: 'South Dakota', abbreviation: 'SD', __typename: 'State' },
      { name: 'Tennessee', abbreviation: 'TN', __typename: 'State' },
      { name: 'Texas', abbreviation: 'TX', __typename: 'State' },
      { name: 'Utah', abbreviation: 'UT', __typename: 'State' },
      { name: 'Vermont', abbreviation: 'VT', __typename: 'State' },
      { name: 'Virgin Islands', abbreviation: 'VI', __typename: 'State' },
      { name: 'Virginia', abbreviation: 'VA', __typename: 'State' },
      { name: 'Washington', abbreviation: 'WA', __typename: 'State' },
      { name: 'West Virginia', abbreviation: 'WV', __typename: 'State' },
      { name: 'Wisconsin', abbreviation: 'WI', __typename: 'State' },
      { name: 'Wyoming', abbreviation: 'WY', __typename: 'State' },
      { name: 'Armed Forces Middle East', abbreviation: 'AE', __typename: 'State' },
      { name: 'Armed Forces Americas (except Canada)', abbreviation: 'AA', __typename: 'State' },
      { name: 'Armed Forces Pacific', abbreviation: 'AP', __typename: 'State' },
      { name: 'Other', abbreviation: 'Other', __typename: 'State' },
    ],
    caProvinces: [
      { name: 'Alaska', abbreviation: 'AB', __typename: 'State' },
      { name: 'British Columbia', abbreviation: 'BC', __typename: 'State' },
      { name: 'Manitoba', abbreviation: 'MB', __typename: 'State' },
      { name: 'New Brunswick', abbreviation: 'NB', __typename: 'State' },
      { name: 'Newfoundland and Labrador', abbreviation: 'NL', __typename: 'State' },
      { name: 'Northwest Territories', abbreviation: 'NT', __typename: 'State' },
      { name: 'Nova Scotia', abbreviation: 'NS', __typename: 'State' },
      { name: 'Nunavut', abbreviation: 'NU', __typename: 'State' },
      { name: 'Prince Edward Island', abbreviation: 'PE', __typename: 'State' },
      { name: 'Ontario', abbreviation: 'ON', __typename: 'State' },
      { name: 'Quebec', abbreviation: 'QC', __typename: 'State' },
      { name: 'Saskatchewan', abbreviation: 'SK', __typename: 'State' },
      { name: 'Yukon', abbreviation: 'YT', __typename: 'State' },
    ],
    goalOptions: [
      {
        text: 'Experiment with food & glucose',
        value: 'learn_about_glucose_levels',
        __typename: 'GoalOption',
      },
      { text: 'Lose weight or control cravings', value: 'lose_weight', __typename: 'GoalOption' },
      {
        text: 'Lower or improve glucose values',
        value: 'improve_glucose_values',
        __typename: 'GoalOption',
      },
      {
        text: 'Optimize fitness performance',
        value: 'optimize_fitness_performance',
        __typename: 'GoalOption',
      },
      { text: 'Improve long-term health', value: 'prevent_disease', __typename: 'GoalOption' },
      { text: 'Promote longevity', value: 'promote_longevity', __typename: 'GoalOption' },
      {
        text: 'Improve daily wellness',
        value: 'manage_chronic_condition',
        __typename: 'GoalOption',
      },
      { text: 'Other', value: 'other', __typename: 'GoalOption' },
    ],
    goalMetricOptions: [
      { text: 'Time in Range', value: 'glucose_time_within_range', __typename: 'GoalMetricOption' },
      { text: 'Variability', value: 'glucose_standard_deviation', __typename: 'GoalMetricOption' },
      { text: 'Average', value: 'glucose_mean', __typename: 'GoalMetricOption' },
      { text: 'Min', value: 'glucose_min', __typename: 'GoalMetricOption' },
      { text: 'Max', value: 'glucose_max', __typename: 'GoalMetricOption' },
      { text: 'Sleep Average', value: 'glucose_sleep_average', __typename: 'GoalMetricOption' },
      { text: 'Morning Average', value: 'glucose_morning_average', __typename: 'GoalMetricOption' },
      { text: 'Weight', value: 'measurements_weight', __typename: 'GoalMetricOption' },
      { text: 'Mood', value: 'journal_mood', __typename: 'GoalMetricOption' },
      { text: 'Energy', value: 'journal_energy', __typename: 'GoalMetricOption' },
      { text: 'Focus', value: 'journal_focus', __typename: 'GoalMetricOption' },
      { text: 'Stress', value: 'journal_stress', __typename: 'GoalMetricOption' },
      { text: 'Appetite', value: 'journal_appetite', __typename: 'GoalMetricOption' },
    ],
    healthData: {
      measurementTypeDefs: [
        {
          key: 'glucose',
          label: 'Glucose',
          isDailyMeasurement: false,
          icon: null,
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'blood_glucose',
          label: 'Blood Glucose',
          isDailyMeasurement: false,
          icon: 'drop',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'blood_ketones',
          label: 'Blood Ketones',
          isDailyMeasurement: false,
          icon: 'drop',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'breath_ketones',
          label: 'Breath Ketones',
          isDailyMeasurement: false,
          icon: 'chart_line',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'body_fat_percentage',
          label: 'Body Fat Percentage',
          isDailyMeasurement: false,
          icon: 'percent',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'body_temperature',
          label: 'Body Temperature',
          isDailyMeasurement: false,
          icon: 'thermometer',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'weight',
          label: 'Weight',
          isDailyMeasurement: false,
          icon: 'scales',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'blood_pressure',
          label: 'Blood Pressure',
          isDailyMeasurement: false,
          icon: 'drop',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'step_count',
          label: 'Step Count',
          isDailyMeasurement: true,
          icon: 'footprints',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'exercise_time',
          label: 'Exercise Time',
          isDailyMeasurement: true,
          icon: 'timer',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'flights_climbed',
          label: 'Flights Climbed',
          isDailyMeasurement: true,
          icon: 'steps',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'running_distance',
          label: 'Running Distance',
          isDailyMeasurement: true,
          icon: 'person_simple_run',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'cycling_distance',
          label: 'Cycling Distance',
          isDailyMeasurement: true,
          icon: 'person_simple_bike',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'swimming_distance',
          label: 'Swimming Distance',
          isDailyMeasurement: true,
          icon: 'person_simple_swim',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'stress',
          label: 'Stress',
          isDailyMeasurement: false,
          icon: 'hand_heart',
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'mood',
          label: 'Mood',
          isDailyMeasurement: false,
          icon: null,
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'energy',
          label: 'Energy',
          isDailyMeasurement: false,
          icon: null,
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'focus',
          label: 'Focus',
          isDailyMeasurement: false,
          icon: null,
          __typename: 'HealthDataMeasurementTypeDef',
        },
        {
          key: 'appetite',
          label: 'Appetite',
          isDailyMeasurement: false,
          icon: null,
          __typename: 'HealthDataMeasurementTypeDef',
        },
      ],
      measurementSourceDefs: [
        {
          key: 'nutrisense',
          label: 'Nutrisense',
          providesDailyMeasurements: true,
          sourceTags: ['nutrisense'],
          iconDef: { pack: 'customImage', name: 'nutrisense', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'healthkit',
          label: 'Apple Health',
          providesDailyMeasurements: true,
          sourceTags: ['healthkit', 'apple'],
          iconDef: { pack: 'customImage', name: 'appleHealth', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'googlefit',
          label: 'Google Fit',
          providesDailyMeasurements: true,
          sourceTags: ['googlefit', 'google'],
          iconDef: { pack: 'customImage', name: 'googleFitIcon', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'dexcom',
          label: 'Dexcom',
          providesDailyMeasurements: false,
          sourceTags: ['dexcom'],
          iconDef: null,
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'fitbit',
          label: 'Fitbit',
          providesDailyMeasurements: true,
          sourceTags: ['fitbit'],
          iconDef: { pack: 'customImage', name: 'fitbitIcon', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'freestylelibre',
          label: 'FreeStyle Libre',
          providesDailyMeasurements: false,
          sourceTags: ['freestylelibre'],
          iconDef: { pack: 'customImage', name: 'freeStyleLibreIcon', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'garmin',
          label: 'Garmin',
          providesDailyMeasurements: true,
          sourceTags: ['garmin'],
          iconDef: { pack: 'customImage', name: 'garminIcon', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'ketomojo',
          label: 'KetoMojo',
          providesDailyMeasurements: false,
          sourceTags: ['ketomojo'],
          iconDef: null,
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'libre_linkup',
          label: 'Libre Linkup',
          providesDailyMeasurements: false,
          sourceTags: ['libre_linkup'],
          iconDef: null,
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'oura',
          label: 'Oura',
          providesDailyMeasurements: true,
          sourceTags: ['oura'],
          iconDef: { pack: 'customImage', name: 'ouraIcon', __typename: 'IconDef' },
          __typename: 'HealthDataMeasurementSourceDef',
        },
        {
          key: 'whoop',
          label: 'Whoop',
          providesDailyMeasurements: true,
          sourceTags: ['whoop'],
          iconDef: null,
          __typename: 'HealthDataMeasurementSourceDef',
        },
      ],
      __typename: 'HealthDataConfig',
    },
    journal: {
      range: { min: 1, max: 5, __typename: 'JournalRange' },
      measurementTypes: [
        { type: 'Mood', reverse: null, __typename: 'MeasurementType' },
        { type: 'Energy', reverse: null, __typename: 'MeasurementType' },
        { type: 'Focus', reverse: null, __typename: 'MeasurementType' },
        { type: 'Stress', reverse: true, __typename: 'MeasurementType' },
        { type: 'Appetite', reverse: null, __typename: 'MeasurementType' },
      ],
      __typename: 'JournalConfig',
    },
    chatbot: {
      lastShownCutoffAt: '2023-08-23T00:00:00-07:00',
      responseTimeoutInSeconds: 120,
      __typename: 'Chatbot',
    },
    reminders: [
      {
        allowedFrequencies: [{ key: 'daily', __typename: 'ReminderFrequency' }],
        icon: 'fork-knife',
        key: 'breakfast',
        title: 'Log Breakfast',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [{ key: 'daily', __typename: 'ReminderFrequency' }],
        icon: 'fork-knife',
        key: 'lunch',
        title: 'Log Lunch',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [{ key: 'daily', __typename: 'ReminderFrequency' }],
        icon: 'fork-knife',
        key: 'dinner',
        title: 'Log Dinner',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [{ key: 'daily', __typename: 'ReminderFrequency' }],
        icon: 'fork-knife',
        key: 'other_meal',
        title: 'Log Meal',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'daily', __typename: 'ReminderFrequency' },
          { key: 'weekly', __typename: 'ReminderFrequency' },
        ],
        icon: 'person-simple-run',
        key: 'exercise',
        title: 'Log Exercise',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'daily', __typename: 'ReminderFrequency' },
          { key: 'weekly', __typename: 'ReminderFrequency' },
        ],
        icon: 'prohibit',
        key: 'fasting',
        title: 'Log Fasting',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'daily', __typename: 'ReminderFrequency' },
          { key: 'weekly', __typename: 'ReminderFrequency' },
        ],
        icon: 'flower-lotus',
        key: 'meditation',
        title: 'Log Meditation',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [{ key: 'daily', __typename: 'ReminderFrequency' }],
        icon: 'moon',
        key: 'sleep',
        title: 'Log Sleep',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'daily', __typename: 'ReminderFrequency' },
          { key: 'weekly', __typename: 'ReminderFrequency' },
        ],
        icon: 'pencil-simple-line',
        key: 'journal',
        title: 'Log Journal',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [{ key: 'hours_interval', __typename: 'ReminderFrequency' }],
        icon: 'bell-ringing',
        key: 'glucose_check',
        title: 'Check Glucose',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'daily', __typename: 'ReminderFrequency' },
          { key: 'weekly', __typename: 'ReminderFrequency' },
        ],
        icon: 'ruler',
        key: 'weight',
        title: 'Log Weight',
        __typename: 'ReminderKind',
      },
      {
        allowedFrequencies: [
          { key: 'monthly', __typename: 'ReminderFrequency' },
          { key: 'bimonthly', __typename: 'ReminderFrequency' },
          { key: 'quarterly', __typename: 'ReminderFrequency' },
        ],
        icon: 'calendar-plus',
        key: 'submit_lab_results',
        title: 'Submit Lab Results',
        __typename: 'ReminderKind',
      },
    ],
    appointmentTypes: [
      {
        key: 'initial',
        duration: '90',
        title: 'Initial 90 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'initial_60',
        duration: '60',
        title: 'Initial 60 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'followup',
        duration: '60',
        title: '60 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'none_covered',
        duration: '30',
        title: '30 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'followup_90',
        duration: '90',
        title: '90 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'followup_30',
        duration: '30',
        title: '30 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'followup_45',
        duration: '45',
        title: '45 Minute Video Call',
        __typename: 'AppointmentType',
      },
      {
        key: 'dpp_session',
        duration: '60',
        title: 'Diabetes Prevention Program Session',
        __typename: 'AppointmentType',
      },
    ],
    healthMetrics: [
      {
        key: 'weight',
        label: 'Weight',
        unit: null,
        kind: 'measurement',
        __typename: 'HealthMetricType',
      },
      {
        key: 'height',
        label: 'Height',
        unit: 'in',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      { key: 'bmi', label: 'BMI', unit: null, kind: 'biomarker', __typename: 'HealthMetricType' },
      {
        key: 'blood_pressure',
        label: 'Blood Pressure',
        unit: null,
        kind: 'measurement',
        __typename: 'HealthMetricType',
      },
      {
        key: 'fasted_glucose',
        label: 'Glucose (fasted)',
        unit: 'mg/dL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'fasted_insulin',
        label: 'Insulin (fasted)',
        unit: 'mIU/L',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'hb_a1c',
        label: 'HbA1c',
        unit: '%',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'ldl_cholesterol',
        label: 'LDL Cholesterol',
        unit: 'mg/dL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'hdl_cholesterol',
        label: 'HDL Cholesterol',
        unit: 'mg/dL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'total_cholesterol',
        label: 'Total Cholesterol',
        unit: 'mg/dL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'triglycerides',
        label: 'Triglycerides',
        unit: 'mg/dL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'vitamin_d',
        label: 'Vitamin D',
        unit: 'ng/mL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'tsh',
        label: 'TSH',
        unit: 'mIU/mL',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
      {
        key: 'hs_crp',
        label: 'hsCRP',
        unit: 'mg/L',
        kind: 'biomarker',
        __typename: 'HealthMetricType',
      },
    ],
    researchFaqs: {
      rootUrl: 'https://support.nutrisense.io/hc/en-us/sections/17612641018519-Nutrisense-Research',
      gettingStartedUrl:
        'https://support.nutrisense.io/hc/en-us/sections/17612279563287-Getting-Started-with-your-Dexcom-G7-CGM',
      eligibilityUrl:
        'https://support.nutrisense.io/hc/en-us/articles/17612660716695-Who-can-participate-in-the-Nutrisense-Longitudinal-Outcomes-of-CGM-Use-Study',
      dataProtectionUrl:
        'https://support.nutrisense.io/hc/en-us/articles/17613125028375-How-will-I-be-protected-if-I-take-part-in-this-research',
      sensorComparisonUrl:
        'https://support.nutrisense.io/hc/en-us/articles/17612475231255-What-is-the-difference-between-Abbott-and-Dexcom-CGMs-provided-by-Nutrisense',
      disableLibreScanUrl: 'https://support.nutrisense.io/hc/en-us/articles/27437823488023',
      __typename: 'ResearchFaqs',
    },
    dailyActions: [
      {
        key: 'fiber_25_grams',
        title: 'Aim for 25 grams of fiber per day.',
        description: 'Fiber is essential for gut, heart and metabolic health',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'fiber_38_grams',
        title: 'Aim for 38 grams of fiber per day.',
        description: 'Fiber is essential for gut, heart and metabolic health',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'last_meal_3_hours_before_bed',
        title: 'Aim for your last meal 3 hours before bed',
        description: 'Eating in alignment with our circadian rhythm improves glucose levels',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'water_8_glasses',
        title: 'Drink 8 glasses of water',
        description: 'Staying hydrated helps keep our cells healthy and energy levels stable',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'non_starch_vegetables_3_to_5_cups',
        title: 'Aim for 3-5 cups of non-starchy vegetables per day.',
        description: 'Non-starchy vegetables provide fiber and antioxidants.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'protein_30_grams_per_meal',
        title: 'Aim for 30 grams of protein per meal',
        description: 'Protein helps with satiety, fat loss and glucose regulation.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'high_fiber_and_protein_breakfast',
        title: 'Eat a breakfast high in protein and fiber',
        description: 'Protein and fiber help with satiety and glucose regulation.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'low_carb_dinner',
        title: 'Eat a low carb dinner',
        description:
          'We are less insulin sensitive in the evening. Eating a low carb dinner helps reduce high overnight glucose levels.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'vegetable_or_protein_before_starchy_carbs',
        title: 'Eat a vegetable or protein before starchy carbs',
        description: 'Fiber helps reduce glucose spikes',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'half_of_plate_non_starchy_vegetables',
        title: 'Make 1/2 of your plate non-starchy vegetables',
        description:
          'Vegetables are rich in fiber and phytonutrients that reduce inflammation and support metabolic health.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'quarter_of_plate_protein',
        title: 'Make 1/4 of your plate protein',
        description: 'Protein helps with satiety, fat loss and glucose regulation.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'no_naked_carbs',
        title: 'No naked carbs',
        description: 'Pairing your carbs with protein and fat helps reduce glucose spikes.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'protein_after_exercise',
        title: 'Refuel with protein after exercise',
        description: 'Protein in the hours after workouts helps build lean muscle mass.',
        category: 'nutrition',
        __typename: 'DailyActionKind',
      },
      {
        key: 'stress_levels',
        title: 'Keep track of your stress levels in Journal',
        description: 'Increased stress may cause a rise in glucose levels.',
        category: 'journal',
        __typename: 'DailyActionKind',
      },
      {
        key: 'hunger_levels',
        title: 'Log your your hunger level before every meal',
        description: 'Hunger and cravings may be connected to glucose levels.',
        category: 'journal',
        __typename: 'DailyActionKind',
      },
      {
        key: 'sleep_quality',
        title: 'Record your sleep quality when you wake up',
        description: 'Good sleep is essential for glucose regulation and reducing cravings.',
        category: 'journal',
        __typename: 'DailyActionKind',
      },
      {
        key: 'digestion_quality',
        title: 'Monitor your digestion throughout the day',
        description: 'GI symptoms may be connected to glucose levels.',
        category: 'journal',
        __typename: 'DailyActionKind',
      },
      {
        key: 'steps_7500',
        title: 'Aim for 7500 steps',
        description: 'Daily movement improves insulin sensitivity and reduces glucose levels.',
        category: 'activity',
        __typename: 'DailyActionKind',
      },
      {
        key: 'sleep_7_to_9_hours',
        title: 'Focus on getting 7-9 hours of sleep',
        description: 'Good sleep is essential for glucose regulation and reducing cravings.',
        category: 'activity',
        __typename: 'DailyActionKind',
      },
      {
        key: 'no_screen_time_1_hour_before_bed',
        title: 'No screen time at least 1 hour before bed',
        description: 'Good sleep is essential for glucose regulation and reducing cravings.',
        category: 'activity',
        __typename: 'DailyActionKind',
      },
      {
        key: 'walk_15_to_30_minutes_after_meals',
        title: 'Walk for 15-30 minutes after meals',
        description: 'Walking is a powerful way to reduce glucose levels.',
        category: 'activity',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_time_in_range',
        title: 'Glucose Time in range',
        description:
          'Monitoring time in range helps you understand how well your blood sugar is managed throughout the day.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_average',
        title: 'Glucose Average',
        description:
          'Monitoring your average glucose helps you maintain steady energy levels and make informed dietary choices.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_max',
        title: 'Glucose Max',
        description:
          'Monitoring glucose spikes is key to avoiding energy crashes, reducing inflammation, and supporting overall metabolic health.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_morning_average',
        title: 'Glucose Morning average',
        description:
          'Monitoring fasting glucose provides insight into sleep, stress and eating patterns.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_sleep_average',
        title: 'Glucose Sleep average',
        description:
          'Monitoring overnight glucose levels helps ensure stable blood sugar while you sleep, reducing the risk of nocturnal hypoglycemia and supporting better overall health.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'weight',
        title: 'Weight',
        description: 'Logging your weight helps with accountability and consistency.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'glucose_variability',
        title: 'Glucose variability',
        description:
          'Monitoring glycemic variability helps you identify fluctuations in blood sugar that could lead to cravings,increased appetite, and weight gain.',
        category: 'trends',
        __typename: 'DailyActionKind',
      },
      {
        key: 'compare_meals',
        title: 'Compare responses from two meals',
        description:
          'Understand how factors like timing, stress, sleep and activity can impact your response to the same meal.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_15_grams_30_minutes_before_exercise',
        title: 'Try this: Eat 15 grams of carbs 30 minutes before exercise',
        description: 'Fueling before a workout provides ready energy to our muscles.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_30_grams_from_starchy_vegetable',
        title: 'Try this: Eat 30 grams of carbs from a starchy vegetable',
        description: 'See how you react to isolated foods, then experiment with new combinations.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_30_grams_from_bread',
        title: 'Try this: Eat 30 grams of carbs from bread',
        description: 'See how you react to isolated foods, then experiment with new combinations.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_30_grams_from_dessert',
        title: 'Try this: Eat 30 grams of carbs from dessert',
        description: 'See how you react to isolated foods, then experiment with new combinations.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_30_grams_from_fruit',
        title: 'Try this: Eat 30 grams of carbs from fruit',
        description: 'See how you react to isolated foods, then experiment with new combinations.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'carbs_30_grams_from_rice',
        title: 'Try this: Eat 30 grams of carbs from rice',
        description: 'See how you react to isolated foods, then experiment with new combinations.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
      {
        key: 'fasting_window',
        title: 'Try this: Experiment with your fasting window',
        description: 'If you have an established fasting plan, reflect and record how it went.',
        category: 'experiment',
        __typename: 'DailyActionKind',
      },
    ],
    insuranceKinds: {
      kindDefs: [
        { key: 'aetna', title: 'Aetna', __typename: 'UserInsurancePolicyKindDef' },
        { key: 'anthem', title: 'Anthem', __typename: 'UserInsurancePolicyKindDef' },
        {
          key: 'blue_cross_blue_shield',
          title: 'Blue Cross Blue Shield',
          __typename: 'UserInsurancePolicyKindDef',
        },
        { key: 'cigna', title: 'Cigna', __typename: 'UserInsurancePolicyKindDef' },
        { key: 'empire', title: 'Empire', __typename: 'UserInsurancePolicyKindDef' },
        { key: 'highmark', title: 'Highmark', __typename: 'UserInsurancePolicyKindDef' },
        { key: 'horizon', title: 'Horizon', __typename: 'UserInsurancePolicyKindDef' },
        { key: 'sana_benefits', title: 'Sana Benefits', __typename: 'UserInsurancePolicyKindDef' },
        {
          key: 'united_healthcare',
          title: 'United Healthcare',
          __typename: 'UserInsurancePolicyKindDef',
        },
      ],
      __typename: 'InsuranceKinds',
    },
    __typename: 'ClientConfig',
  },
}
