import { gql } from '@apollo/client'

export const CORE_INSURANCE_POLICY_FIELDS = gql`
  fragment CoreInsurancePolicyFields on InsurancePolicy {
    id
    initialAppointmentType {
      key
      title
      duration
    }
    memberId
    unitsLeft
    unitsUsed
    unitsAuthorized
    lastState {
      id
      kind
      reason
    }
    kind {
      title
    }
    last
    eligible
    inReview
    declined
    hasUnusedCalls
    hasUnlimitedCalls
  }
`
