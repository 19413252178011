import React from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import {
  InsuranceBerryStreetAppointmentMeetingStatus,
  InsuranceBerryStreetAppointmentType,
} from '@src/types'
import {
  VIDEO_CALL_CARD_DATE_WITH_TIME_FORMAT,
  VIDEO_CALL_CARD_TIME_FORMAT,
} from '@src/screens/NutritionistHub/constants'
import { useVideoCallDurations } from '@screens/NutritionistHub/hooks/useVideoCallDurations.ts'

export interface CallCardProps {
  title?: string | null
  meetingAt: string
  nutritionistName?: string | null
  appointmentType?: InsuranceBerryStreetAppointmentType | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  recurrent: boolean
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  header?: string
}

const meetingStatusTextLookup = {
  [InsuranceBerryStreetAppointmentMeetingStatus.Occurred]: 'Completed',
  [InsuranceBerryStreetAppointmentMeetingStatus.NoShow]: 'No-show',
  [InsuranceBerryStreetAppointmentMeetingStatus.Cancelled]: 'Cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.LateCancellation]: 'Late Cancellation',
  [InsuranceBerryStreetAppointmentMeetingStatus.Rescheduled]: 'Rescheduled',
  [InsuranceBerryStreetAppointmentMeetingStatus.PendingReschedule]: 'Rescheduled',
}

const meetingStatusCssLookup: {
  [key in InsuranceBerryStreetAppointmentMeetingStatus]: 'occurred' | 'cancelled' | 'rescheduled'
} = {
  [InsuranceBerryStreetAppointmentMeetingStatus.Occurred]: 'occurred',
  [InsuranceBerryStreetAppointmentMeetingStatus.Cancelled]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.LateCancellation]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.NoShow]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.Rescheduled]: 'rescheduled',
  [InsuranceBerryStreetAppointmentMeetingStatus.PendingReschedule]: 'rescheduled',
}

export const CallCard = (props: CallCardProps) => {
  const {
    title,
    meetingAt,
    meetingStatus,
    nutritionistName,
    appointmentType,
    recurrent,
    style,
    onPress,
    header,
  } = props
  const styles = useStyleSheet(themedStyles)
  const { getVideoCallDuration } = useVideoCallDurations()

  const borderColor = meetingStatus
    ? styles[`${meetingStatusCssLookup[meetingStatus]}Border`]
    : null

  return (
    <TouchableOpacity
      accessibilityLabel="appointment"
      style={[styles.container, borderColor, style]}
      onPress={onPress}
      activeOpacity={onPress ? 0.2 : 1}
    >
      {!!header && (
        <Text type="small" bold style={styles.header}>
          {header}
        </Text>
      )}
      <View style={styles.title}>
        <Text type="regular" bold>
          {title || ''} with {nutritionistName?.split(' ')[0] || ''}
          {meetingStatus ? ` (${meetingStatusTextLookup[meetingStatus]})` : ''}
          {!meetingStatus && recurrent ? ' (Recurring)' : ''}
        </Text>
        {onPress && <Icon name="dots-three-vertical" weight="bold" />}
      </View>
      <Text type="small" style={styles.time}>
        {moment(meetingAt).format(VIDEO_CALL_CARD_DATE_WITH_TIME_FORMAT)} -{' '}
        {moment(meetingAt)
          .add(getVideoCallDuration(appointmentType), 'minutes')
          .format(VIDEO_CALL_CARD_TIME_FORMAT)}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderLeftWidth: 3,
    borderColor: 'theme.success.base',
    paddingLeft: 12,
  },
  title: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  occurredBorder: {
    borderColor: 'theme.success.darkest',
  },
  cancelledBorder: {
    borderColor: 'theme.error.base',
  },
  rescheduledBorder: {
    borderColor: 'theme.tooltip.textSecondary',
  },
  time: {
    marginTop: 12,
    color: 'theme.text.secondary',
  },
  header: {
    color: 'theme.text.secondary',
  },
})
