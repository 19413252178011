import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { useDispatchAsync } from '@src/utils'
import {
  SurveysConfigKind,
  SurveyLink,
  SurveyLinkCollection,
  SurveysUserLinkState,
  SurveysQuestionKey,
  Question,
} from '@src/types'
import { QuestionnaireScreenName } from '@src/navigation/types'
import { QuestionId, QuestionnaireConfigInteractor } from '../QuestionnaireConfigInteractor'

type UseQuestionnaireStateLoading = { loading: true }
type UseQuestionnaireStateError = { error: true; message: string }

export const useLoadSurveyLink = (key: SurveysConfigKind) => {
  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()
  const questionnaireForm = useForm({ mode: 'all' })
  const [surveyLink, setSurveyLink] = useState<null | SurveyLink>(null)

  const [currentQuestionIdentifier, setCurrentQuestionIdentifier] = useState<QuestionId>({
    questionIndex: 0,
    sectionIndex: 0,
  })

  const [questionnaireConfigInteractor, setQuestionnaireConfigInteractor] = useState<
    UseQuestionnaireStateLoading | QuestionnaireConfigInteractor | UseQuestionnaireStateError
  >({ loading: true })

  const { setValue: questionnaireSetValue } = questionnaireForm

  const handleSurveyLink = useCallback(
    (surveyLink: SurveyLink) => {
      try {
        if (!surveyLink) {
          return
        }
        const { responses: surveyResponses, state, survey } = surveyLink

        const questionnaireConfigInteractor = new QuestionnaireConfigInteractor(survey)

        // add followup questions to questionnaire config
        surveyResponses.forEach((surveyResponse) => {
          const answerValueObject = surveyResponse.answer.value

          const answerValues = (Array.isArray(answerValueObject)
            ? answerValueObject
            : [answerValueObject]
          ).map((value) => value?.toString())

          const followups = questionnaireConfigInteractor.findQuestionStandaloneFollowupsWithAnswer(
            {
              questionKey: (surveyResponse.questionKey as unknown) as SurveysQuestionKey,
              answerValues,
            },
          )

          followups.reverse().forEach((followup) => {
            const questionId = questionnaireConfigInteractor.findQuestion(
              followup.parentQuestionKey,
            )

            if (questionId) {
              questionnaireConfigInteractor.addQuestionAfterCurrent(followup.question, questionId)
            }
          })
        })

        if (state === SurveysUserLinkState.Restarted) {
          // present only questions without answers
          const SurveysQuestionKeyWithResponses = surveyResponses.map(
            (response) => response.questionKey,
          )

          SurveysQuestionKeyWithResponses.forEach((questionKey) => {
            const questionId = questionnaireConfigInteractor.findQuestion(
              (questionKey as unknown) as SurveysQuestionKey,
            )

            if (questionId) {
              // remove question with answer
              questionnaireConfigInteractor.removeQuestionByKey(questionKey, questionId)
            }
          })
        }

        const mappedValues =
          surveyResponses?.flatMap(({ questionKey, answer }) => {
            const questionId = questionnaireConfigInteractor.findQuestion(
              (questionKey as unknown) as SurveysQuestionKey,
            )

            if (!questionId) {
              return []
            }

            const { sectionIndex, questionIndex } = questionId

            return [
              {
                answer:
                  Object.keys(answer).length > 1
                    ? {
                        value: {
                          ...answer,
                          value: answer.value,
                        },
                      }
                    : answer,
                sectionIndex,
                questionIndex,
                questionKey,
              },
            ]
          }) || []

        mappedValues.forEach((value) => {
          questionnaireSetValue(value.questionKey, value.answer.value)
        })

        const initialSectionIndex = Math.max(0, ...mappedValues.map((value) => value.sectionIndex))

        const initialSectionQuestions = questionnaireConfigInteractor.getSectionConfig(
          initialSectionIndex,
        ).questions

        const questionsNotAnswered = initialSectionQuestions
          .map((question) => {
            const questionAnswered = surveyResponses.some(
              (response) => response.questionKey === question.key,
            )

            if (!questionAnswered) {
              return question
            }
          })
          .filter((question) => !!question)

        let initialQuestionIndex = 0
        if (questionsNotAnswered.length > 0) {
          initialQuestionIndex = initialSectionQuestions.indexOf(
            questionsNotAnswered[0] as Question,
          )
        } else {
          initialQuestionIndex = Math.max(
            0,
            ...mappedValues
              .filter((value) => value.sectionIndex === initialSectionIndex)
              .map((value) => value.questionIndex),
          )
        }

        setCurrentQuestionIdentifier({
          questionIndex: initialQuestionIndex,
          sectionIndex: initialSectionIndex,
        })

        setQuestionnaireConfigInteractor(questionnaireConfigInteractor)
        setSurveyLink(surveyLink)
      } catch (e: any) {
        console.error('Error fetching existing survey', e)
        setQuestionnaireConfigInteractor({
          error: true,
          message: e.message,
        })
      }
    },
    [questionnaireSetValue],
  )

  const handleSurveyLinkError = useCallback((message: string) => {
    setQuestionnaireConfigInteractor({
      error: true,
      message,
    })
  }, [])

  const loadSurveyLink = useCallback(async () => {
    try {
      const { surveyLinks } = await dispatchAsync<SurveyLinkCollection>({
        type: 'app/surveyQuestionnaire',
      })

      const stoppedSurveyLink = surveyLinks.find(
        (link) =>
          link.state === SurveysUserLinkState.Stopped &&
          link.survey.kind === SurveysConfigKind.Health &&
          link.outcome,
      )

      if (stoppedSurveyLink) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: QuestionnaireScreenName.HealthQuestionnaireRejectionReason,
              params: { outcome: stoppedSurveyLink.outcome },
            },
          ],
        })
        return
      }

      const surveyLinksOfKind = surveyLinks.filter((link) => link.survey.kind === key)
      const surveyLink = surveyLinksOfKind.find((link) => !link.finished)

      if (
        surveyLink &&
        (!surveyLink.finished || !surveyLink.survey.behavior.allowMultipleSubmissions)
      ) {
        handleSurveyLink(surveyLink)
        return
      }

      const newSurveyLink = await dispatchAsync<SurveyLink>({
        type: 'app/startQuestionnaire',
        payload: {
          kind: key,
        },
      })
      return handleSurveyLink(newSurveyLink)
    } catch (e: any) {
      console.error('Error fetching existing survey', e)
      handleSurveyLinkError(e.message)
    }
  }, [dispatchAsync, key, navigation, handleSurveyLinkError, handleSurveyLink])

  return {
    loadSurveyLink,
    surveyLink,
    questionnaireConfigInteractor,
    currentQuestionIdentifier,
    setCurrentQuestionIdentifier,
    questionnaireForm,
  }
}
