import React, { ReactNode } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Pill, Text } from '@components/base'
import { UiPillSize, UiPillType } from '@src/types'
import { Menu, MenuInfoItem } from './Menu'

export interface BaseListItemProps {
  title: string
  subtitle?: string
  tag?: string
  info: string
  infoDetails?: string
  menuItems?: MenuInfoItem[]
  isMenuEnabled?: boolean
  renderSubtitleContent?: () => ReactNode
  renderInfoContent?: () => ReactNode
  renderImageContent?: () => ReactNode
  onItemPress?: () => void
  showDivider?: boolean
  style?: any
  imageContainerStyle?: any
}

export const BaseListItem = (props: BaseListItemProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    title,
    subtitle,
    tag,
    info,
    infoDetails,
    isMenuEnabled = true,
    menuItems,
    renderSubtitleContent,
    renderInfoContent,
    renderImageContent,
    onItemPress,
    showDivider = true,
    style,
    imageContainerStyle,
  } = props

  const renderSubtitle = () => {
    if (renderSubtitleContent) {
      return renderSubtitleContent()
    }
    if (subtitle) {
      return (
        <Text type="small" bold lineSpacing="tight" style={styles.subtitle}>
          {subtitle}
        </Text>
      )
    }
    return null
  }

  const renderInfoDetails = () => {
    if (renderInfoContent) {
      return renderInfoContent()
    }
    if (infoDetails) {
      return (
        <Text type="small" bold>
          {infoDetails}
        </Text>
      )
    }
    return null
  }

  const showMenu = menuItems && menuItems.length > 0

  return (
    <>
      <TouchableOpacity
        style={[styles.container, style]}
        activeOpacity={0.7}
        disabled={!onItemPress}
        onPress={onItemPress}
        accessibilityLabel={title}
      >
        {renderImageContent && (
          <View style={[styles.imageContainer, imageContainerStyle]}>{renderImageContent()}</View>
        )}
        <View style={styles.contentContainer}>
          <View style={styles.content}>
            <View style={styles.titleContainer}>
              <Text type="large" bold style={styles.title}>
                {title}
              </Text>
              {!!tag && <Pill status={UiPillType.Info} size={UiPillSize.S} text={tag} />}
            </View>
            {renderSubtitle()}
          </View>
          <View style={[styles.infoContainer, !showMenu && styles.infoContainerWithoutMenu]}>
            <Text type="large" bold style={styles.info}>
              {info}
            </Text>
            {renderInfoDetails()}
          </View>
          {showMenu && <Menu menuItems={menuItems} menuEnabled={isMenuEnabled} />}
        </View>
      </TouchableOpacity>
      {showDivider && <Divider />}
    </>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: 'theme.background',
  },
  imageContainer: {
    height: 56,
    width: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'theme.surface.base3',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  infoContainer: {
    alignItems: 'flex-end',
    paddingVertical: 4,
  },
  infoContainerWithoutMenu: {
    marginRight: 12,
  },
  content: {
    flex: 1,
    paddingVertical: 4,
  },
  title: {
    marginRight: 8,
  },
  subtitle: {
    paddingTop: 4,
    color: 'theme.primary.base',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    paddingBottom: 4,
  },
})
