import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Controller, useForm } from 'react-hook-form'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Keyboard, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { CommonSelect, ScrollableAvoidKeyboard, SelectDateTime } from '@src/components'
import { Button, Input, Text } from '@src/components/base'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import {
  InsuranceBerryStreetAppointmentType,
  InsurancePolicy,
  UserInsurancePolicyKindDef,
} from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { ErrorMessages } from '@src/config'
import { useSnack } from '@src/utils'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { SelectOption } from '@src/components/CommonSelect.types'

interface HolderRelationshipData {
  value: 'self' | 'spouse' | 'child' | 'other'
  text: string
}

interface ProviderData {
  value: string
  text: string
}

interface InsurancePolicyFormData {
  insuranceProvider: ProviderData
  memberId: string
  groupId: string
  holderRelationship: HolderRelationshipData
  holderFirstName: string | undefined
  holderLastName: string | undefined
  holderDateOfBirth: Date | undefined
}

const buildInsuranceKindOption = (option: UserInsurancePolicyKindDef) => {
  if (!option) {
    return
  }

  return {
    value: option.key,
    text: option.title,
  }
}
const buildInsuranceKindOptions = (options: UserInsurancePolicyKindDef[]) => {
  return options.map(buildInsuranceKindOption).filter((option) => !!option) as SelectOption[]
}

const holderRelationshipOptions = [
  { value: 'self', text: 'Self' },
  { value: 'spouse', text: 'Spouse' },
  { value: 'child', text: 'Child' },
  { value: 'other', text: 'Other' },
]

export const InsurancePolicyForm = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const insuranceKindsConfig = get(clientConfig, 'insuranceKinds.kindDefs', [])
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const showSnack = useSnack()

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<InsurancePolicyFormData>()

  const policyHolder = watch('holderRelationship')
  const showHolderInformationFields = policyHolder !== undefined && policyHolder['value'] !== 'self'

  const submitPolicy = handleSubmit((formData) => {
    Keyboard.dismiss()

    const payload = {
      memberId: formData.memberId,
      groupId: formData.groupId,
      kind: formData.insuranceProvider['value'],
      holderRelationship: formData.holderRelationship['value'],
      holderFirstName: formData.holderFirstName,
      holderLastName: formData.holderLastName,
      holderDateOfBirth: formData.holderDateOfBirth,
    }
    dispatch({
      payload,
      type: 'insuranceStatus/createInsurancePolicy',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      success: (response: InsurancePolicy) => {
        navigation.replace('CallScheduling', {
          appointmentType:
            response.initialAppointmentType?.key || InsuranceBerryStreetAppointmentType.Initial_60,
          allowBackNavigation: false,
        })
      },
    })
  })

  return (
    <NavigationContainer title="New Insurance" style={{ flexGrow: 1 }}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
      >
        <View style={styles.inputWithLabel}>
          <Text type="regular">Insurance Provider</Text>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CommonSelect
                options={buildInsuranceKindOptions(insuranceKindsConfig)}
                selectedOption={value}
                onSelect={onChange}
                accessibilityLabel="Insurance Provider"
              />
            )}
            name="insuranceProvider"
            rules={{ required: 'Insurance Provider is required' }}
          />
        </View>

        <View style={styles.inputWithLabel}>
          <Text type="regular">Member ID</Text>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                hasError={!!errors.memberId}
              />
            )}
            name="memberId"
            rules={{ required: 'Member ID is required' }}
          />
        </View>

        <View style={styles.inputWithLabel}>
          <Text type="regular">Group Number (if applicable)</Text>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                hasError={!!errors.groupId}
              />
            )}
            name="groupId"
          />
        </View>

        <View style={styles.inputWithLabel}>
          <Text type="regular">Who is the primary insurance policy holder?</Text>
          <Controller
            control={control}
            defaultValue={{ value: 'self', text: 'Self' }}
            render={({ field: { onChange, value } }) => (
              <CommonSelect
                options={holderRelationshipOptions}
                selectedOption={value}
                onSelect={onChange}
                accessibilityLabel="Primary Policy Holder"
              />
            )}
            name="holderRelationship"
            rules={{ required: 'Information about primary policy holder is required' }}
          />
        </View>

        {showHolderInformationFields && (
          <>
            <View style={styles.inputWithLabel}>
              <Text type="regular">Policyholder's First Name</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    hasError={!!errors.holderFirstName}
                  />
                )}
                name="holderFirstName"
                rules={{ required: 'Policyholder First Name is required' }}
              />
            </View>

            <View style={styles.inputWithLabel}>
              <Text type="regular">Policyholder's Last Name</Text>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    hasError={!!errors.holderLastName}
                  />
                )}
                name="holderLastName"
                rules={{ required: 'Policyholder Last Name is required' }}
              />
            </View>

            <View style={styles.inputWithLabel}>
              <Text type="regular">Policyholder's Date of Birth</Text>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDateTime
                    inputProps={{
                      value: value
                        ? moment.utc(value).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)
                        : undefined,
                      hasError: !!errors.holderDateOfBirth,
                    }}
                    pickerProps={{
                      mode: 'date',
                      maximumDate: new Date(),
                      timeZoneName: 'UTC',
                    }}
                    onChange={(value) => onChange(moment(value).format(DATE_FORMAT))}
                  />
                )}
                name="holderDateOfBirth"
                rules={{ required: 'Policyholder Date of Birth is required' }}
              />
            </View>
          </>
        )}
        <View style={styles.buttonContainer}>
          <Button accessibilityLabel="Save" type="primary" size="block" onPress={submitPolicy}>
            Save
          </Button>
        </View>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    padding: 16,
  },
  inputWithLabel: {
    marginBottom: 16,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 16,
  },
})
