import { useDispatch, useSelector } from 'react-redux'
import { activeNotificationsSelector } from '@src/selectors/notifications'
import { userSelector } from '@src/selectors/app'
import { Notification, NotificationEngineNotificationActionKind } from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { NotificationEngineScreen } from '@src/types'
import { IconName, UI_ICONS_MAPPING } from '@src/components/base'
import { handleEngineNotification } from './handler'

export const useNotifications = (screenName: NotificationEngineScreen, calendarDate?: string) => {
  const notifications = useSelector(activeNotificationsSelector)

  return notifications
    .filter((notification) => notification.screen === screenName)
    .filter((notification) => selectNotificationsForCalendarDate(notification, calendarDate))
    .sort((a, b) => b.createdAt - a.createdAt)
}

const selectNotificationsForCalendarDate = (notification: Notification, calendarDate?: string) => {
  // If there is no calendar date or display date
  if (!calendarDate || !notification.payload?.displayDate) {
    return true
  }

  return notification.payload.displayDate === calendarDate
}

export const useNotificationBanner = (
  screenName: NotificationEngineScreen,
  calendarDate?: string,
) => {
  const dispatch = useDispatch()
  const [notification] = useNotifications(screenName, calendarDate)
  const user = useSelector(userSelector)

  if (!notification || !user) {
    return null
  }

  const userId = user.id

  const iconName: IconName = UI_ICONS_MAPPING[notification.icon] || 'bell-ringing'

  const actionHandler = () => {
    handleEngineNotification({
      notificationData: {
        id: notification.id,
        actionKind: notification.actionKind,
        kind: notification.kind,
        ...notification.payload,
      },
      userId,
      dispatch,
    })
  }

  return {
    content: notification.content,
    iconName,
    action:
      notification.actionKind === NotificationEngineNotificationActionKind.None
        ? undefined
        : actionHandler,
    actionButtonLabel: notification.actionButtonLabel,
    dismissButtonLabel: notification.dismissButtonLabel,
    style: notification.style,
    kind: notification.kind,
    dismiss: () => {
      dispatch({
        type: 'notifications/dismiss',
        payload: { id: notification.id },
        success: (notification: Notification) => {
          Analytics.track(CustomEventTypes.NotificationBannerDismissed, {
            id: notification.id,
            actionKind: notification.actionKind,
            kind: notification.kind,
            ...notification.payload,
            userId,
            dateSent: notification.createdAt,
          })
        },
      })
    },
  }
}
