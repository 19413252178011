import { useSelector } from 'react-redux'
import { useFeatureFlag, Feature } from '@src/components'
import { HeroChartType } from '@src/screens/DailyActions/models/dailyActions.types'
import { preferredHeroChartSelector } from '@src/selectors/dailyActions'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'

export const useHomeScreenChart = () => {
  const preferredHeroChart = useSelector(preferredHeroChartSelector)
  const customHeroChartEnabled = useFeatureFlag(Feature.CustomHeroChart)
  const showGlucoseChart = User.hasFeature(MobileAppFeature.ViewGlucoseChart)
  const isDialChartEnabled = useFeatureFlag(Feature.HomeScreenDialChart)

  const shouldShowNutritionSummaryChart = !showGlucoseChart && customHeroChartEnabled
  const shouldShowDialChart =
    (customHeroChartEnabled && preferredHeroChart === HeroChartType.DialChart) ||
    (!customHeroChartEnabled && isDialChartEnabled)

  return {
    shouldShowDialChart,
    shouldShowNutritionSummaryChart,
  }
}
