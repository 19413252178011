import React, { useRef, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { WebView } from 'react-native-webview'
import Chart from 'react-native-f2chart'

interface CommonChartProps {
  chartScript: string
  chartRef?: React.MutableRefObject<Chart | null>
  height?: number
  onLoad?: () => void
  style?: any
}

const renderLoading = () => null

export const CommonChart = ({
  chartScript,
  chartRef,
  height,
  onLoad,
  style = {},
}: CommonChartProps) => {
  const fallbackRef = useRef<any>()
  const activeRef = chartRef || fallbackRef

  const reloadChart = () => {
    activeRef.current?.chart?.current?.reload()
  }

  useEffect(reloadChart, [activeRef, chartScript])

  const containerStyle = height ? { height, flex: undefined } : { flex: 1 }

  const onChange = useCallback(
    (data: any) => {
      if (data.type === 'loaded' && onLoad) {
        onLoad()
      }
    },
    [onLoad],
  )

  return (
    <View style={[containerStyle, style]} onLayout={reloadChart}>
      <Chart
        ref={activeRef}
        initScript={chartScript}
        WebView={WebView}
        renderLoading={renderLoading}
        onChange={onChange}
        androidLayerType="hardware"
        onLoadEnd={onLoad}
      />
    </View>
  )
}
