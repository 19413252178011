import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { InsurancePolicy, UiPillType } from '@src/types'
import { Pill, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { InsurancePolicyState } from './InsurancePolicyState'

interface InsurancePolicyProps {
  insurancePolicy: InsurancePolicy
  style?: StyleProp<ViewStyle>
}

const DEFAULT_PROVIDER_NAME = 'Unknown Provider'

export const InsurancePolicyComponent = (props: InsurancePolicyProps) => {
  const styles = useStyleSheet(themedStyles)
  const { style, insurancePolicy } = props
  const provider = insurancePolicy.kind?.title || DEFAULT_PROVIDER_NAME

  return (
    <View style={[styles.container, style]}>
      <View style={styles.column}>
        <View style={styles.row}>
          <Text type="regular">{provider}</Text>
          {insurancePolicy.last && (
            <Pill
              style={{ marginLeft: 16, height: 28 }}
              text="Active"
              status={UiPillType.Success}
            />
          )}
          <Text type="small" style={styles.benefitsCheckText}>
            Benefits Check Status
          </Text>
        </View>
        <View style={styles.row}>
          <Text type="regular">{insurancePolicy.memberId}</Text>
          <InsurancePolicyState insurancePolicy={insurancePolicy} />
        </View>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderWidth: 2,
    borderRadius: 16,
    padding: 12,
    borderColor: 'theme.surface.base1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 74,
  },
  benefitsCheckText: {
    color: 'theme.text.secondary',
    textAlign: 'right',
    flex: 1,
  },
  column: {
    flex: 1,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pill: {
    marginLeft: 16,
    height: 28,
  },
})
