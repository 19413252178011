import React from 'react'
import { Keyboard, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Input } from '@components/base'
import { PhoneInput, ScrollableAvoidKeyboard } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { authenticatedUserSelector } from '@selectors/app'
import { AddressCountries, MobileAppFeature } from '@src/types'
import { User, Validators, useGoBack, useSnack } from '@utils'
import { useCancelModal } from '@utils/hooks'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'

interface AccountFormData {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export const EditAccountDetails = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const showSnack = useSnack()

  const { email, firstName, lastName, phoneNumber = '', address } = useSelector(
    authenticatedUserSelector,
  )

  const addressCountry = (address?.country as AddressCountries | undefined) || AddressCountries.Us

  const countryCode = getCountryCodeByCountry(addressCountry)

  const defaultValues: AccountFormData = {
    email,
    firstName,
    lastName,
    phoneNumber: new AsYouType(countryCode).input(phoneNumber || ''),
  }

  const {
    control,
    formState: { isValid, isDirty, errors },
    setFocus,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues,
  })

  const updateProfile = handleSubmit(({ email, firstName, lastName, phoneNumber }) => {
    Keyboard.dismiss()

    dispatch({
      type: 'users/update',
      payload: {
        email,
        firstName,
        lastName,
        phoneNumber: parsePhoneNumber(phoneNumber, countryCode).nationalNumber,
      },
      success: () => {
        showSnack('Account details updated successfully')
        goBack()
      },
      failure: (error: any) => {
        showSnack(error?.message || 'An error ocurred, please try later', null, 'error')
      },
    })
  })

  const openCancelModal = useCancelModal({
    goBack,
    isModified: isDirty,
    title: 'You have unsaved changes. Are you sure you want to leave?',
    confirmText: 'Leave',
    cancelText: 'Keep editing',
  })

  const isUserNameChangingDisabled = !User.hasFeature(MobileAppFeature.AccountDetailsEditName)

  return (
    <NavigationContainer title="Account Details" goBack={openCancelModal}>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <ScrollableAvoidKeyboard>
          <View style={styles.form}>
            <Controller
              control={control}
              name="email"
              rules={{ required: true, validate: Validators.EmailValidator }}
              render={({ field }) => (
                <Input
                  {...field}
                  autoCapitalize="none"
                  autoCorrect={false}
                  hasError={!!errors.email}
                  keyboardType="email-address"
                  label="Email"
                  onChangeText={(text) => field.onChange(text.trim())}
                  onSubmitEditing={() => setFocus('firstName')}
                  placeholder="Enter your email address"
                  returnKeyType="next"
                  style={styles.input}
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              rules={{ required: true, validate: Validators.NameValidator }}
              render={({ field }) => (
                <Input
                  {...field}
                  autoCapitalize="words"
                  disabled={isUserNameChangingDisabled}
                  hasError={!!errors.firstName}
                  label="First Name"
                  onSubmitEditing={() => setFocus('lastName')}
                  placeholder="Enter your first name"
                  returnKeyType="next"
                  style={styles.input}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true, validate: Validators.NameValidator }}
              render={({ field }) => (
                <Input
                  {...field}
                  autoCapitalize="words"
                  disabled={isUserNameChangingDisabled}
                  hasError={!!errors.lastName}
                  label="Last Name"
                  onSubmitEditing={() => setFocus('phoneNumber')}
                  placeholder="Enter your last name"
                  returnKeyType="next"
                  style={styles.input}
                />
              )}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: true,
                validate: (value) => Validators.PhoneNumberValidator(value, countryCode),
              }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  autoCapitalize="words"
                  hasError={!!errors.phoneNumber}
                  keyboardType="phone-pad"
                  label="Phone"
                  onSubmitEditing={updateProfile}
                  placeholder="Enter your phone number"
                  returnKeyType="next"
                  style={styles.input}
                  country={addressCountry}
                />
              )}
            />
          </View>
          <Button
            accessibilityLabel="Create account"
            disabled={!isValid || !isDirty}
            onPress={updateProfile}
            size="block"
            type="primary"
          >
            Save Account Details
          </Button>
        </ScrollableAvoidKeyboard>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 24,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  form: {
    flex: 1,
  },
  input: {
    marginBottom: 16,
  },
})
