import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { InsuranceBerryStreetAppointmentChargeType } from '@src/types'
import { appointmentTypesSelector } from '@src/selectors/app'
import { VideoCallDetails, getSchedulingConfig, isFollowupSchedulingAvailable } from '../utils'
import { useInitialAppointmentType } from '../hooks'
import { Card } from './Card'

interface VideoCallInfoProps {
  videoCallDetails: VideoCallDetails
}

export const VideoCallNotScheduledCard = (props: VideoCallInfoProps) => {
  const { videoCallDetails } = props
  const navigation = useNavigation()

  const appointmentTypes = useSelector(appointmentTypesSelector)

  const initialAppointmentType = useInitialAppointmentType()
  const followupCallNotScheduled = isFollowupSchedulingAvailable(videoCallDetails)

  const schedulingConfig = getSchedulingConfig(
    videoCallDetails,
    followupCallNotScheduled,
    initialAppointmentType,
    appointmentTypes,
  )

  const isCallFree = videoCallDetails.chargeType === InsuranceBerryStreetAppointmentChargeType.Free

  const [buttonText, description] = isCallFree
    ? ['Book Call', 'Free Call']
    : ['Schedule', 'Not Scheduled']

  const styles = useStyleSheet(themedStyle)
  const onSchedulePress = () => {
    navigation.navigate('CallScheduling', schedulingConfig.navigationParams)
  }

  return (
    <Card buttonText={buttonText} onButtonPress={onSchedulePress}>
      <Icon name="video-camera" size="32" style={styles.header} />
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {schedulingConfig.title}
      </Text>
      <Text
        type="small"
        bold
        lineSpacing="none"
        style={isCallFree ? styles.text : styles.textWarning}
      >
        {description}
      </Text>
    </Card>
  )
}

const themedStyle = StyleService.create({
  header: {
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  text: {
    color: 'theme.text.secondary',
  },
  textWarning: {
    color: 'theme.error.base',
  },
})
